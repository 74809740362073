import React from "react";
import { Link } from "react-router-dom";
import Img from "./operationalSupportImg/SystemMontaring.png";
import { useEffect } from "react";
const SystemMonitoring = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>System Monitoring Services</h1>
            <h2>
              we offer system monitoring solutions to ensure smooth operations,
              prevent issues, and boost efficiency.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Maximize System Performance with Real-Time Monitoring</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive IT Infrastructure Monitoring</h3>
                </li>
                <li>
                  <h2>
                    Ensure Optimal System Health with Proactive Monitoring
                  </h2>
                </li>
                <li>
                  <p>
                    Our proactive system monitoring services allow you to track
                    the health of your network and servers in real-time,
                    preventing potential downtime before it affects your
                    business operations.
                  </p>
                </li>
                <li>
                  <h1>Discover Our IT Infrastructure Monitoring Solutions</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Application Performance Monitoring</h3>
                </li>
                <li>
                  <h2>Optimize Your Application Performance</h2>
                </li>
                <li>
                  <p>
                    With our monitoring tools, we provide detailed insights into
                    your applications' performance, helping you resolve issues
                    faster and enhance user experiences.
                  </p>
                </li>
                <li>
                  <h1>Learn More About Application Monitoring</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Acetians Technologies: Leader in System Monitoring Innovation
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies is committed to providing state-of-the-art
                monitoring solutions that streamline your IT operations and
                maximize uptime. Our services are designed to give you the
                control and visibility you need to stay ahead.
              </p>
            </li>
            <li>
              <button>Read Our Latest Insights</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.uffizio.com/wp-content/uploads/2024/01/Trailers-1.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global System Monitoring for Business Continuity</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies provides global system monitoring
                solutions that empower businesses to manage their IT
                infrastructure efficiently, regardless of location or scale.
              </h2>
            </li>
            <li>
              <button>Explore Our Global Solutions</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Unlock Efficiency with Seamless Monitoring</h1>
        </li>
        <li>
          <p>
            Acetians Technologies helps you unlock operational efficiencies with
            integrated monitoring tools that span your entire digital
            infrastructure. From servers to applications, our solutions ensure
            peak performance.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize Your Network</h1>
                </li>
                <li>
                  <p>
                    Leverage cutting-edge network monitoring tools to ensure
                    fast and reliable network performance, essential for
                    business continuity.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Monitor Performance 24/7</h1>
                </li>
                <li>
                  <p>
                    Gain 24/7 insights into your system’s performance to
                    identify potential issues and improve uptime across your
                    operations.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Real-Time Monitoring</h1>
                </li>
                <li>
                  <p>
                    Acetians’ real-time monitoring tools provide you with
                    instant feedback and early warnings about your system’s
                    health, helping to keep your business running smoothly.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SystemMonitoring;

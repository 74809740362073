import React from "react";
import { Link } from "react-router-dom";
import Img from "./AcetiansITImage/IndustryExpertSessions.png";
import { useEffect } from "react";

const IndustryExpertSessions = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Industry Expert Sessions</h1>
            <h2>
              {" "}
              Gain invaluable insights from leading experts in various
              industries.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-component-11" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Connect with Leading Industry Experts</h1>
            </li>
            <li>
              <p>
                Book a session with one of our industry experts to gain tailored
                advice, insights, and strategies specific to your needs, whether
                for career development or business growth.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnhPC2iXfJZ_vXO71rWLIKuXpTXAsnpCXVP0zc2bJ7IvT4vO59Oatf-p67KravBWq5nVc&usqp=CAU"
            alt="Industry Experts"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMpjv9HuL_AT5s1Y3fhlDKPNHNV1-PWxIlUzqMA0P8pCI_mkun8tY7UAhrreuNILu2sOc&usqp=CAU"
            alt="Expert Insights"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
        <li>
          <ul>
            <li>
              <h1>Unlock Valuable Insights from Experts</h1>
            </li>
            <li>
              <p>
                Our sessions provide actionable insights into industry trends,
                challenges, and emerging opportunities, helping you stay ahead
                of the competition and make informed decisions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Consultation</button>
                </li>
                <li>
                  <button>Watch a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Stay Ahead with Industry Trends</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Keep up with the latest trends, innovations, and
                industry-specific developments through our expert-led sessions,
                ensuring you remain competitive in your field.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default IndustryExpertSessions;

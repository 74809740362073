import React from "react";
import { Link } from "react-router-dom";
import Img from "./CloudSolutionImages/clouddatastorage.png";
import { useEffect } from "react";

const CloudDataStorage = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cloud Data Storage Solutions.</h1>
            <h2>
              {" "}
              provide scalable and secure cloud data storage solutions to
              enhance your business's data management and accessibility.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-5" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Forrester names Acetians a Leader in Cloud Data Storage</h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in cloud
                data storage solutions, offering cutting-edge tools and services
                to streamline your data management and enhance business
                continuity.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://cdn.mos.cms.futurecdn.net/RXjTpHQERsFQaUFEMYn96f.jpg" />
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://i.pcmag.com/imagery/roundups/03yy022DDsenwhBBYdxvwDi-3..v1593631883.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Cloud Storage Innovations Forum</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends in cloud data storage by
                    joining our Cloud Storage Innovations Forum. Connect with
                    experts, gain insights into the best storage practices, and
                    explore new solutions.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Optimize Cloud Storage Efficiency</h1>
        </li>
        <li>
          <p>
            Accelerate your cloud data management with Acetians’ expertise. Our
            solutions ensure efficient use of cloud storage resources, enhancing
            your business's data retrieval and storage capabilities.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://metaorangedigital.com/wp-content/uploads/2023/11/Types-of-Cloud-Storage-01-1024x971.jpg" />
                </li>
                <li>
                  <h1>Optimize</h1>
                </li>
                <li>
                  <p>
                    Leverage advanced cloud storage technologies for seamless
                    data access and optimal storage management.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQZ3G9GdsymnNnAXFfLLS0H5lYOhoMOcrZTJ2PM1NDf2Z0_ppH5Cd7bWIRorBpt7iYVx0&usqp=CAU" />
                </li>
                <li>
                  <h1>Secure</h1>
                </li>
                <li>
                  <p>
                    Maximize data security and privacy with Acetians’ secure
                    cloud storage solutions designed to keep your business data
                    safe.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSQi4kd5m0svvlzUVL6J4cpMWCMOskl4h_5WGWcH69NjtJ9Up_aXhHecpHm9-cMnbldgo&usqp=CAU" />
                </li>
                <li>
                  <h1>Scale</h1>
                </li>
                <li>
                  <p>
                    Scale your cloud storage capacity as your business grows,
                    ensuring that data storage costs remain optimized.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ AI-Powered Cloud Storage Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians leverages AI to enhance cloud storage
                management, automating processes for better performance and cost
                efficiency.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies Cloud Storage Report 2024</h1>
            </li>
            <li>
              <p>
                Download our latest cloud storage report, covering trends, best
                practices, and expert insights to help you optimize your cloud
                storage strategy.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Optimize Cloud Storage Efficiency</h1>
        </li>
        <li>
          <p>
            Accelerate your cloud data management with Acetians’ expertise. Our
            solutions ensure efficient use of cloud storage resources, enhancing
            your business's data retrieval and storage capabilities.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize</h1>
                </li>
                <li>
                  <p>
                    Leverage advanced cloud storage technologies for seamless
                    data access and optimal storage management.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDp-ZZpBG98NyCxcYBchTKMWhGu3UWgpKHv47TOjCUQ7Tkz6axy7-hImZYsce9k9gzQns&usqp=CAU" />
                </li>
                <li>
                  <h1>Secure</h1>
                </li>
                <li>
                  <p>
                    Maximize data security and privacy with Acetians’ secure
                    cloud storage solutions designed to keep your business data
                    safe.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Scale</h1>
                </li>
                <li>
                  <p>
                    Scale your cloud storage capacity as your business grows,
                    ensuring that data storage costs remain optimized.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CloudDataStorage;

import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/Communication.png";
import { useEffect } from "react";

const CommunicationsSkills = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Mastering Communication Skills with Acetians Technologies</h1>
            <h2>
              {" "}
              Enhancing your personal and professional communication abilities.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-4" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Enhance Your Communication Skills with Acetians</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers a variety of resources and courses
                to help individuals and teams develop essential communication
                skills. Our training programs focus on improving both verbal and
                non-verbal communication to ensure clear and effective
                interactions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Request a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://media.licdn.com/dms/image/D4E12AQFlo893jjwmgA/article-cover_image-shrink_720_1280/0/1690202356962?e=2147483647&v=beta&t=qWGoRRrqredCLKwuvJIpZmg8vmsWoi6__7Q9q7Gn0jw" />
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://blogimage.vantagecircle.com/content/images/2019/06/Communication-skills-in-the-workplace.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Proactive Communication Training for Professional Growth</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we offer tailored communication
                training programs designed to improve both team and individual
                communication. Learn how to communicate more effectively with
                peers, clients, and leadership.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Request a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Building Strong Communication Foundations</h1>
        </li>
        <li>
          <p>
            At Acetians Technologies, we believe in the power of strong
            communication skills to create successful teams and businesses. Our
            training services focus on active listening, empathy, conflict
            resolution, and assertiveness.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Effective Listening Skills</h1>
                </li>
                <li>
                  <p>
                    Our training helps individuals improve their listening
                    skills, ensuring better understanding and stronger
                    relationships in both personal and professional
                    environments.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Conflict Resolution Techniques</h1>
                </li>
                <li>
                  <p>
                    Our communication skills program covers conflict resolution
                    strategies, teaching individuals how to navigate difficult
                    conversations and reach mutually beneficial outcomes.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Clear and Concise Communication</h1>
                </li>
                <li>
                  <p>
                    Our training emphasizes how to communicate clearly and
                    effectively, reducing misunderstandings and improving
                    overall productivity.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Us for Communication Training Services</h1>
            </li>
            <li>
              <p>
                Reach out to our expert team to discover how Acetians
                Technologies can help you develop your communication skills and
                drive success in your business.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://virtualspeech-com.b-cdn.net/wp-content/uploads/effective-communication-skills.jpg" />
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians: Your Partner in Communication Mastery</h1>
            </li>
            <li>
              <h2>
                No matter your communication challenges, Acetians Technologies
                offers the expertise and resources to help you and your team
                become more effective communicators.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CommunicationsSkills;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./DigitalExeImg/CostmerJourneyMapping.png";
import { useEffect } from "react";

const CustomerJourneyMapping = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Customer Journey Mapping</h1>
            <h2>
              We craft solutions to optimize and elevate every customer
              interaction.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-2" id="globalpre">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://www.cisco.com/c/en_in/solutions/industries/manufacturing/jcr:content/Grid/category_atl/layout-category-atl/blade/bladeContents/spotlight_969515212.img.jpg/1641791342633.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Connect with your peers in The Global Gateway</h1>
                </li>
                <li>
                  <p>
                    Join our community in The Global Gateway to explore best
                    practices, industry trends, and learn how other
                    organizations are improving their customer journey through
                    mapping and optimization.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Join the community</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>
                The Future of Customer Journey Mapping: Insights and Trends
              </h1>
            </li>
            <li>
              <p>
                Discover how customer journey mapping is evolving, with insights
                from leading experts and how businesses are leveraging this tool
                to enhance their customer experience and engagement strategies.
              </p>
            </li>
            <li>
              <button>Download the report</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Streamline Your Customer Journey with Journey Mapping</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Customer Journey Mapping Suite</h3>
                </li>
                <li>
                  <h2>
                    Map and Optimize Every Step of Your Customer’s Journey
                  </h2>
                </li>
                <li>
                  <p>
                    Our suite of customer journey mapping tools helps you
                    visualize every interaction, uncover pain points, and
                    optimize customer touchpoints for a seamless and impactful
                    experience.
                  </p>
                </li>
                <li>
                  <h1>View Customer Journey Mapping Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Customer Experience Optimization Platform</h3>
                </li>
                <li>
                  <h2>Maximize Customer Satisfaction and Loyalty</h2>
                </li>
                <li>
                  <p>
                    Our platform provides actionable insights into customer
                    behaviors and preferences, enabling you to improve customer
                    retention, satisfaction, and lifetime value through
                    effective journey mapping.
                  </p>
                </li>
                <li>
                  <h1>View Experience Optimization Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile Customer Journey Mapping Strategies</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we implement agile strategies for
                mapping and optimizing customer journeys, allowing you to adapt
                to changing customer behaviors and market dynamics quickly.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/contentReuse/en_in/dm/small-business/sb-solutions-spotlight-xl/jcr:content/Grid/category_atl_copy/layout-category-atl/spotlight_copy.img.jpg/1632720254172.jpg" />
        </li>
      </ul>
      <ul className="business-component-5">
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight_523742182.img.jpg/1687847291685.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Customer Journey Leadership Consulting</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers expert consulting services to help
                businesses map and improve customer journeys, ensuring they are
                aligned with customer needs and expectations, and driving
                customer success.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CustomerJourneyMapping;

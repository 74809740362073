import { cleanup } from "@testing-library/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ModalCross from '../ICONS/ModalCross'

const LoginModal = ({ closeModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "none";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);


  return (
    <>
      <div className="modal-wrapper" onClick={closeModal}></div>
      <div className="modal-container">
        <div className="login-container">
          <button onClick={closeModal}>
          <ModalCross />
          </button>
          
          <h2 className="Sign-lable">Sign In</h2>
          <form className="login-form">
            <label className="login-label" for="email">
              Email/Username
            </label>
            <input type="text" id="email" className="login-input" />

            <label className="login-label" for="password">
              Password
            </label>
            <input type="password" id="password" className="login-input" />

            <div className="login-options">
              <a href="#" className="forgot-password">
                Forgot?
              </a>
              <label className="keep-signed-in">
                <input type="checkbox" className="keep-signed-in-checkbox" /> 
                <div>Keep
                me signed in</div>
              </label>
            </div>

            <button type="submit" className="login-button">
              Sign In
            </button>
          </form>

          {/* <div className="create-account">
            <p>
              Don't have an account?{" "}
              <Link to="/register"  className="create-account-link">
                Create One.
              </Link>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoginModal;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./AcetiansITImage/ItCources.png";

const CertifiedITAourses = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <ul>
        <li>
          <div
            className="WhyBharat"
            style={{
              backgroundImage: `url(${Img})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <ul>
              <li>
                <h1> Certified IT Courses</h1>
                <h2>
                  {" "}
                  we offer certified IT courses to boost your skills and career.
                </h2>
                <li className="btn-explore">
                  <Link to="/contact-us">TALK TO AN EXPERT</Link>
                </li>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <ul className="business-comopnent" id="globalpre">
            <li>
              <ul>
                <li>
                  <h1>Explore Certified IT Courses with Acetians</h1>
                </li>
                <li>
                  <h2>
                    Gain hands-on experience and industry-recognized
                    certifications with Acetians. Our courses are tailored to
                    help you succeed in today's competitive IT landscape.
                  </h2>
                </li>
                <li>
                  <button>See all courses</button>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="business-component-1">
            <li>
              <img src="https://lh5.googleusercontent.com/proxy/tPcMSTj8nwRGQKsQDr9VPzrLE22FqFsoWGLktx9mMvISSxo1gf3YKVPHBYe9f-kfnQT_fSoVpk1JzLNr6OaL92oWXZD_O1kPigJRNaBa8Z2EPNuG-aJmbYZ4DXhFzjPve8-84vTjMBBzjBfbpkmhWA" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>IT Certifications & Expertise</h1>
                </li>
                <li>
                  <p>
                    At Acetians Technologies, we specialize in providing the
                    most up-to-date IT certifications that boost your career
                    prospects. Our instructors are industry experts with
                    real-world experience.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Cloud Computing Certification</h1>
                        </li>
                        <li>
                          <p>
                            Gain expertise in cloud platforms like AWS, Azure,
                            and Google Cloud. Prepare for certifications that
                            will empower your career in cloud computing.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Cybersecurity Certification</h1>
                        </li>
                        <li>
                          <p>
                            Protect digital assets and systems by mastering the
                            skills necessary for top-tier cybersecurity roles.
                            Acetians offers comprehensive training in
                            cybersecurity essentials.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Data Science & AI Certification</h1>
                        </li>
                        <li>
                          <p>
                            Master the fundamentals of data science and
                            artificial intelligence with industry-recognized
                            certifications that will help you stand out in the
                            tech world.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="business-component-2">
            <li>
              <ul>
                <li>
                  <h1>Agile & Lean IT Strategies</h1>
                </li>
                <li>
                  <p>
                    Acetians Technologies offers training in agile methodologies
                    and lean IT processes, helping you enhance efficiency,
                    reduce costs, and increase value in IT projects.
                  </p>
                </li>
                <li>
                  <button>Learn more</button>
                </li>
              </ul>
            </li>
            <li>
              <img src="https://i0.wp.com/www.campuzine.com/wp-content/uploads/2023/07/Certificate.jpg?fit=640%2C360&ssl=1" />
            </li>
          </ul>

          <ul className="business-component-4">
            <li>
              <ul>
                <li>
                  <h1>Innovation through IT Solutions</h1>
                </li>
                <li>
                  <p>
                    Acetians empowers businesses with cutting-edge IT solutions
                    that drive competitive advantage, scalability, and growth
                    through innovative technology.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Start a Consultation</button>
                    </li>
                    <li>
                      <button>Watch A Demo</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
            </li>
          </ul>

          <ul className="business-comopnent-6">
            <li>
              <ul>
                <li>
                  <h1>Global IT Certifications</h1>
                </li>
                <li>
                  <h2>
                    Acetians Technologies offers globally recognized
                    certifications to help professionals excel in the rapidly
                    evolving IT industry.
                  </h2>
                </li>
                <li>
                  <button>Why Acetians</button>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="business-comopnent-7">
            <li>
              <h1>Deliver Superior IT Experiences</h1>
            </li>
            <li>
              <p>
                Enhance your IT operations with Acetians’ comprehensive
                strategies that optimize digital performance and drive business
                success.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                    </li>
                    <li>
                      <h1>Optimize</h1>
                    </li>
                    <li>
                      <p>
                        Maximize IT system efficiency with Acetians’ full-stack
                        observability solutions.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                    </li>
                    <li>
                      <h1>Optimize</h1>
                    </li>
                    <li>
                      <p>
                        Enhance performance with IT-driven strategies for
                        optimized user experiences.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                    </li>
                    <li>
                      <h1>Optimize</h1>
                    </li>
                    <li>
                      <p>
                        Achieve excellence with tailored IT strategies for
                        business optimization.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CertifiedITAourses;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./EnterpricesSolimg/workforcemanagementool.png";
import { useEffect } from "react";

const ServerInstallations = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Workforce Management System</h1>
            <h2>
              Efficiently manage and optimize your workforce with our innovative
              solutions for businesses of all sizes.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://www.cisco.com/c/en_in/products/software/index/jcr:content/Grid/category_atl/layout-category-atl/spotlight_677629952_.img.jpg/1693300210307.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Workforce Management</h1>
            </li>
            <li>
              <p>
                Our workforce management solutions help you streamline employee
                scheduling, time tracking, and performance evaluation, ensuring
                greater productivity and efficiency.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 1: Assess Workforce Requirements</h1>
                    </li>
                    <li>
                      <p>
                        We work closely with you to understand your workforce
                        needs, including the skills, scheduling, and compliance
                        requirements for optimal performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 2: Choose the Right Tools</h1>
                    </li>
                    <li>
                      <p>
                        Selecting the right workforce management tools is
                        essential to track time, schedule shifts, and monitor
                        performance effectively.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 3: Implement Scheduling and Time Tracking</h1>
                    </li>
                    <li>
                      <p>
                        Our system allows for efficient scheduling and time
                        tracking to ensure that your workforce is aligned with
                        business demands and stays compliant.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 4: Performance and Productivity Monitoring</h1>
                    </li>
                    <li>
                      <p>
                        Track employee performance and identify areas of
                        improvement to ensure maximum efficiency and
                        productivity across the team.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 5: Training and Development Programs</h1>
                    </li>
                    <li>
                      <p>
                        We offer training programs to upskill your workforce,
                        ensuring that employees are well-prepared to meet
                        evolving industry needs.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 6: Continuous Improvement</h1>
                    </li>
                    <li>
                      <p>
                        Our solution provides continuous analytics to assess
                        workforce performance, so you can make data-driven
                        decisions and adjust strategies for continuous
                        improvement.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Our Workforce Experts</h1>
            </li>
            <li>
              <p>
                Speak with one of our experts to discuss how we can help you
                implement a robust workforce management system tailored to your
                business needs.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/spotlight.img.jpg/1693555465493.jpg" />
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Optimize Your Workforce with Our Expertise</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Maximize Workforce Efficiency</h1>
                </li>
                <li>
                  <p>
                    Work with us to streamline your workforce management
                    processes, ensuring that you get the most out of your
                    employees and reduce operational inefficiencies.
                  </p>
                </li>
                <li>
                  <h1>Get Expert Workforce Advice</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Upgrade Your Workforce Management System</h1>
                </li>
                <li>
                  <p>
                    Whether you're implementing a new system or upgrading your
                    current one, our team provides the expertise to guide you
                    through the process.
                  </p>
                </li>
                <li>
                  <h1>Contact Our Team</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Acetians Workforce Insights</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Learn more about the best practices and strategies for
                optimizing your workforce management systems to enhance
                efficiency and employee satisfaction.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ServerInstallations;

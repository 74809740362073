import React from 'react'


function StoryCard1() {
  return (
    <div className='story__card'>
        <ul>
            <li>
            <img src={"https://media.licdn.com/dms/image/D5612AQEqJqAHMdW2_Q/article-cover_image-shrink_720_1280/0/1708394467259?e=2147483647&v=beta&t=ZAtMFiIdRhdxI7lfMp5w3vdg_2KH4ydvld0tWmM_D60"} className='Story_card_img' />
            </li>
            <li>
                <div className='blog_content'>
                <h2>Cloud Computing</h2>
                <h3>Cloud computing is the delivery of computing services like storage, processing, and software over the internet. It allows users to access and use resources on-demand, without needing to own or maintain physical servers. This provides flexibility, scalability, and cost savings. Examples include services like Google Drive, Amazon Web Services (AWS), and Microsoft Azure.</h3>   
                <a href=''>Explore more</a>
                </div>
            </li>
        </ul>
    </div>
  )
}

export default StoryCard1
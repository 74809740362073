import React from "react";
import CoreServices from "../components/CoreServices";
import LatestInnovations from "../components/LatestInnovations";
import StoryCard1 from "../StoryCards/StoryCard1";
import StoryCard2 from "../StoryCards/StoryCard2";
import StoryCard3 from "../StoryCards/StoryCard3";
import StoryCard4 from "../StoryCards/StoryCard4";
import { Link } from "react-router-dom";
import img3 from "../images/cropped-img5.jpg";

function Home() {
  return (
    <div className="main-body">
      <div className="WhyBharat" style={{ backgroundImage: `url(${img3})` }}>
        <ul>
          <li>
            <h1>Empowering Tomorrow with Artificial Intelligence</h1>
            <h2>Smarter Solutions for a Smarter World</h2>

            <Link className="btn-explore" to="/contact-us">
              Explore Acetians
            </Link>
          </li>
        </ul>
      </div>
      <CoreServices />
      <LatestInnovations />
      <StoryCard3 />
      <StoryCard2 />
      <StoryCard1 />
      <StoryCard4 />
    </div>
  );
}

export default Home;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./operationalSupportImg/RemoteSupportService.png";
import { useEffect } from "react";

const RemoteSupportServices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Remote Support Services</h1>
            <h2>
              we provide remote support to keep your systems running smoothly
              and securely.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-2" id="globalpre">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://ossisto.com/wp-content/uploads/2024/01/Types-of-Remote-IT-Support-1.webp" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Connect with your peers in The Global Gateway</h1>
                </li>
                <li>
                  <p>
                    Visit our Let's Talk Manufacturing channel in The Global
                    Gateway where you’ll connect with peers, read about industry
                    trends, and see how other customers are leveraging remote
                    support solutions for their digital transformation.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Join the community</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>2024 Remote Support Solutions Survey Report</h1>
            </li>
            <li>
              <p>
                Gain insights from professionals around the globe on the
                evolving role of remote support in IT management, its impact on
                business continuity, and how companies are adopting innovative
                solutions to stay ahead.
              </p>
            </li>
            <li>
              <button>Download the report</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>Optimize Your Business with Remote Support</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive Support for Your IT Infrastructure</h3>
                </li>
                <li>
                  <h2>24/7 Remote Monitoring and Support</h2>
                </li>
                <li>
                  <p>
                    Get proactive remote monitoring, troubleshooting, and issue
                    resolution across your entire IT infrastructure, ensuring
                    business continuity and minimizing downtime.
                  </p>
                </li>
                <li>
                  <h1>View Remote Support Solutions</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Remote Support for Critical Systems</h3>
                </li>
                <li>
                  <h2>Specialized Support for Mission-Critical Operations</h2>
                </li>
                <li>
                  <p>
                    With our remote support services, we ensure that your
                    critical business systems are always online and optimized
                    for maximum performance.
                  </p>
                </li>
                <li>
                  <h1>View Critical Support Solutions</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile & Lean IT Support Strategies</h1>
            </li>
            <li>
              <p>
                Acetians Technologies focuses on lean IT support processes that
                minimize waste while maximizing the efficiency and effectiveness
                of your remote support team.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://techsee.com/wp-content/uploads/2020/11/Remote-support-best-practices.jpg" />
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://www.screenmeet.com/hubfs/Blog%20Images/guy-giving-remote-support-blog.jpeg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Strategic IT Support Leadership</h1>
            </li>
            <li>
              <p>
                Acetians Technologies provides leadership consulting to optimize
                remote support teams and IT operations, ensuring that your
                business is supported by industry-leading experts.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default RemoteSupportServices;

import { Support } from "@mui/icons-material";
import React, { useState } from "react";

import Supporthome from "./Support/Supporthome";
import OperationalSupportServices from "./Support/OperationalSupportServices";

import Data from '../schema.json'

function ProductTabComponent({ setshow }) {
  const [isshow, setIsshow] = useState(false);
  const [Color, setColor] = useState(false);
  const [sublinks, setSublinks] = useState([]); // Define the sublinks state

  // Function to show selected tab and set its sublinks
  const showcomponent = (tab, sublinks) => {
    setIsshow(tab); // Set the active tab
    setSublinks(sublinks); // Set the sublinks of the selected tab
    if (Color === 'lightcoral') return;
    setColor('lightcoral');
  };

  // Accessing the 'Support' section from JSON
  const supportLinks = Data[2]?.links || []; 

  return (
    <div className="tab">
      <div className="tab-list">
        <ul className="list-link">
          <li onClick={() => showcomponent("Support Home", [])} className="main-page">
            Support
          </li>

          {/* Dynamically render support links */}
          {supportLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => showcomponent(link.name, link.sublinks)} // Pass sublinks when a section is clicked
            >
              {link.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="list-content">
     
        {isshow === false && <Supporthome />}
        {isshow === "Support Home" && <Supporthome />}
        {isshow === "Operational Support" && (
          <OperationalSupportServices sublinks={sublinks} setshow={setshow} />
        )}
      </div>
    </div>
  );
}

export default ProductTabComponent;

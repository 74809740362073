import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/softskills.png";
import { useEffect } from "react";

const SoftSkillDevelopement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Soft Skill Development</h1>
            <h2>
              {" "}
              Your trusted partner in enhancing soft skills for professional
              growth.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Acetians Soft Skill Development Programs</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies offers comprehensive training programs
                designed to enhance communication, leadership, and interpersonal
                skills for career success.
              </h2>
            </li>
            <li>
              <button>See All Programs</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://cdn.elearningindustry.com/wp-content/uploads/2019/10/7-Benefits-That-Highlight-The-Importance-Of-Soft-Skills-In-The-Workplace.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Effective Communication Skills</h1>
            </li>
            <li>
              <p>
                Acetians provides training in communication techniques that help
                individuals express ideas clearly and confidently in both
                personal and professional settings.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Verbal Communication</h1>
                    </li>
                    <li>
                      <p>
                        Improve your public speaking and presentation skills,
                        enabling you to engage and influence others effectively.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Non-Verbal Communication</h1>
                    </li>
                    <li>
                      <p>
                        Master body language and other non-verbal cues to
                        enhance the effectiveness of your communication.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Team Building and Collaboration</h1>
            </li>
            <li>
              <p>
                Our workshops are designed to enhance collaboration and
                teamwork, ensuring better coordination and problem-solving
                skills within teams.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://hoteng.com/wp-content/uploads/500x300_SSK.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Conflict Resolution and Negotiation</h1>
            </li>
            <li>
              <p>
                Learn to manage conflicts and negotiate effectively to create
                win-win situations that benefit all parties involved.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians Soft Skill Development: Trusted Globally</h1>
            </li>
            <li>
              <h2>
                Our soft skill programs have empowered professionals across the
                globe to achieve career success by improving key interpersonal
                skills.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SoftSkillDevelopement;

import React from "react";

const ProductAndServicehome = () => {
  const productsAndServices = [
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-Dm9yupZGZAN__XWf0I2Mf1PTpXMeD4barg&s",
      headText: "Consulting services",
      textInfo:
        "Consulting services provide expert advice and strategic solutions to businesses, enhancing efficiency, solving challenges, and driving growth through specialized knowledge in areas like management, technology, finance, operations, and market research.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfuikbvCMzH_uq77jaJhDjvaRUs2e6bhVpdQ&s",
      headText: "Hardware Services",
      textInfo:
        "Acetians Technologies provides comprehensive hardware services, including installation, maintenance, and repair of robotic systems, ensuring optimal performance, reliability, and seamless integration with advanced technologies to meet diverse client requirements.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpvT65fXWu6QV0S3LSnp77z8LZUz2o0idNRQ&s",
      headText: "More Services",
      textInfo:
        "Power BI is a powerful data analytics tool enabling businesses to visualize, analyze, and share insights from data through interactive dashboards, fostering data-driven decision-making and enhanced business performance.",
    },
  ];

  return (
    <div className="main-page-content">
      {productsAndServices.map((item, index) => (
        <div key={index}>
          <div>
            <img
              src={item.imgSrc}
              className="content-img"
              alt="product-service"
            />
          </div>
          <div>
            <p className="head-text">{item.headText}</p>
            <p className="text-info">{item.textInfo}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductAndServicehome;

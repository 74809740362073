import React from "react";
import { Link } from "react-router-dom";
import Img from "./EnterpricesSolimg/ERP.png";
import { useEffect } from "react";

const NetworkDevices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Empowering Businesses with Acetians ERP Solutions</h1>
            <h2>
              Transform your business processes with our innovative ERP tools
              and services.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-9 " id="globalpre">
        <li>
          <h1>Acetians ERP: Driving Business Efficiency</h1>
        </li>
        <li>
          <p>
            Acetians' ERP system provides comprehensive tools to manage and
            automate critical business functions, ensuring that your business is
            agile, efficient, and adaptable to changes in the market.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul className="Compo-9">
                <li>
                  <h1>Unified Business Processes</h1>
                </li>
                <li>
                  <p>
                    Our ERP solutions integrate all aspects of your business,
                    from accounting to inventory management, allowing for better
                    decision-making and operational efficiency.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Advanced Analytics and Reporting</h1>
                </li>
                <li>
                  <p>
                    With Acetians ERP, you gain access to real-time business
                    insights through advanced analytics, helping you monitor
                    performance and forecast future trends with confidence.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Customizable ERP Solutions</h1>
                </li>
                <li>
                  <p>
                    Tailor your ERP system to your business needs with Acetians'
                    customizable solutions that provide flexibility while
                    improving operational control and efficiency.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Cloud-Based ERP for Greater Accessibility</h1>
                </li>
                <li>
                  <p>
                    Acetians' cloud-based ERP solutions offer scalability,
                    security, and accessibility from anywhere, ensuring your
                    business stays connected across departments and locations.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>AI-Powered Automation</h1>
                </li>
                <li>
                  <p>
                    Leverage AI capabilities within Acetians' ERP tools to
                    automate routine tasks, enabling your team to focus on
                    strategic initiatives that drive business growth.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Enhance Your Business Operations with Acetians ERP</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Optimized Resource Management</h1>
                </li>
                <li>
                  <p>
                    Acetians' ERP helps businesses manage their resources more
                    effectively, providing accurate data for decision-making and
                    improving the allocation of time, materials, and manpower.
                  </p>
                </li>
                <li>
                  <h1>Get More from Your Business Operations</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Seamless Collaboration</h1>
                </li>
                <li>
                  <p>
                    With Acetians' ERP, your teams can collaborate in real time
                    across various departments, ensuring smooth communication
                    and eliminating silos in your organization.
                  </p>
                </li>
                <li>
                  <h1>Contact Us for More Info</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians ERP: Trusted by Global Enterprises</h1>
            </li>
            <li>
              <h2>
                Acetians ERP has empowered businesses around the world, helping
                them streamline operations, improve customer satisfaction, and
                drive growth with reliable, real-time insights.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Transform Your Supply Chain with Acetians ERP</h1>
            </li>
            <li>
              <p>
                Acetians ERP enhances supply chain management by integrating key
                processes, optimizing logistics, and improving inventory
                management, giving your business a competitive edge.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default NetworkDevices;

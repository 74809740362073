import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "../LEARNIMAGES/onlinelearning.png";

const OnlineLearningModules = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Online Learning Modules</h1>
            <h2>
              {" "}
              Unlock the potential of self-paced learning with our comprehensive
              online modules.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-12" id="globalpre">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIrwjfxgRu_vrub3SOol4B7Hrk9JlMDq5sfg&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Learn Anytime, Anywhere</h1>
            </li>
            <li>
              <p>
                Access our learning modules from anywhere, at any time, and on
                any device. Our online courses are designed to fit into your
                busy schedule, enabling you to learn at your convenience.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-9">
        <li>
          <h1>Develop Skills with Interactive Learning</h1>
        </li>
        <li>
          <p>
            Our interactive learning modules provide hands-on experience,
            quizzes, and practical exercises, ensuring that you not only learn
            the theory but also apply the knowledge.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Video Tutorials</h1>
                </li>
                <li>
                  <p>
                    Watch expert-led video tutorials that cover a wide range of
                    topics, from beginner to advanced levels.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Assignments and Quizzes</h1>
                </li>
                <li>
                  <p>
                    Test your understanding through assignments and quizzes that
                    reinforce the learning process.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Live Webinars</h1>
                </li>
                <li>
                  <p>
                    Join live webinars hosted by experts to deepen your
                    understanding and ask questions in real-time.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Practical Projects</h1>
                </li>
                <li>
                  <p>
                    Work on real-world projects that allow you to apply your
                    skills and enhance your practical knowledge.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Certifications</h1>
                </li>
                <li>
                  <p>
                    Earn certifications after completing each module, showcasing
                    your skills and knowledge to future employers.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Discover the Best Online Learning Tools</h1>
            </li>
            <li>
              <h2>
                Explore the latest online learning tools designed to enhance
                your learning experience with intuitive interfaces and engaging
                content.
              </h2>
            </li>
            <li>
              <button>See all tools</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Transform your career with continuous learning</h1>
            </li>
            <li>
              <p>
                Stay ahead in your career by continuously upgrading your skills
                through our expertly curated online learning modules, ensuring
                you're always at the top of your field.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default OnlineLearningModules;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InstructureWorkshop/CyberSequrityBasic.png";

const CybersecurityTraining = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cybersecurity Training</h1>

            <h2> we offer cybersecurity training to protect your business.</h2>

            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-7" id="globalpre">
        <li>
          <h1>Enhance Your Cybersecurity Skills</h1>
        </li>
        <li>
          <p>
            Acetians’ cybersecurity training programs equip you with the
            necessary skills to defend against cyber threats and safeguard your
            organization’s digital assets.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile_495424719.img.png/1687847290722.png" />
                </li>
                <li>
                  <h1>Optimize Your Security Strategy</h1>
                </li>
                <li>
                  <p>
                    Learn how to design and implement advanced security
                    strategies to protect your infrastructure and reduce
                    vulnerabilities.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile_942430233.img.png/1687847290691.png" />
                </li>
                <li>
                  <h1>Incident Response Planning</h1>
                </li>
                <li>
                  <p>
                    Master the skills to create and execute an effective
                    incident response plan to mitigate the impact of security
                    breaches.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile.img.png/1687847290678.png" />
                </li>
                <li>
                  <h1>Security Awareness Training</h1>
                </li>
                <li>
                  <p>
                    Equip your team with the knowledge to recognize and respond
                    to potential threats, reducing human error in cybersecurity.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-1">
        <li>
          <h1>Explore Our Incident Management and Response Training</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://cypfer.com/wp-content/themes/cypfer/resources/block-preview-images/cypfer-placeholder-card.webp" />
                </li>
                <li>
                  <h1>Advanced Incident Response Strategies</h1>
                </li>
                <li>
                  <p>
                    Learn how to manage and respond to security incidents
                    effectively, minimizing damage and ensuring business
                    continuity.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://media.bizj.us/view/img/12781635/adobestock709433961jpeg*900xx8704-4896-16-0.jpg" />
                </li>
                <li>
                  <h1>Proactive Monitoring and Detection</h1>
                </li>
                <li>
                  <p>
                    Gain expertise in using monitoring systems to detect and
                    neutralize potential security threats before they escalate.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwaOmpUMreDnHg12VV95_IoEfqWdGrlyoC_w&s" />
                </li>
                <li>
                  <h1>Incident Communication Protocols</h1>
                </li>
                <li>
                  <p>
                    Learn how to establish clear communication strategies during
                    security incidents to reduce confusion and ensure rapid
                    recovery.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Forrester Recognizes Acetians as a Leader in Cybersecurity
                Training
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized for its comprehensive
                cybersecurity training programs that provide businesses with the
                necessary tools to fight cyber threats effectively.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/content/dam/cisco-cdc/site/images/backgrounds/solutions/iot/forrester-security-report-1920x1080.jpg" />
        </li>
      </ul>
      <ul className="business-component-1">
        <li>
          <img src="https://www.springboard.com/blog/wp-content/uploads/2021/06/shutterstock_505066678-scaled-scaled.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Cybersecurity Expertise at Acetians</h1>
            </li>
            <li>
              <p>
                Acetians Technologies specializes in delivering the latest in
                cybersecurity expertise to businesses, helping them enhance
                their security infrastructure and stay ahead of emerging
                threats.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cybersecurity Strategy</h1>
                    </li>
                    <li>
                      <p>
                        Develop robust cybersecurity strategies to ensure the
                        protection of your digital assets and data.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Incident Response & Recovery</h1>
                    </li>
                    <li>
                      <p>
                        Learn effective incident response tactics to swiftly
                        recover from cyberattacks and minimize downtime.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Continuous Monitoring</h1>
                    </li>
                    <li>
                      <p>
                        Implement continuous monitoring solutions to detect and
                        mitigate risks before they compromise your business.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CybersecurityTraining;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./CloudSolutionImages/cloudcostoptimization.png";
import { useEffect } from "react";

const CloudCostOptimization = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cloud Cost Optimization.</h1>
            <h2>
              {" "}
              we help businesses optimize cloud costs while enhancing
              performance, scalability, and security.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-7" id="globalpre">
        <li>
          <h1>Maximize Cloud Efficiency and Savings</h1>
        </li>
        <li>
          <p>
            Optimize your cloud infrastructure with Acetians' expertise. We
            ensure you pay only for the resources you need while maintaining top
            performance.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://static.vecteezy.com/system/resources/previews/017/431/842/non_2x/charge-money-through-cloud-storage-icon-outline-illustration-vector.jpg" />
                </li>
                <li>
                  <h1>Optimize Cloud Resources</h1>
                </li>
                <li>
                  <p>
                    Leverage advanced cloud optimization tools to eliminate
                    waste and improve cost-efficiency across your cloud
                    infrastructure.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/041/314/462/small_2x/trendy-design-of-cloud-earning-vector.jpg" />
                </li>
                <li>
                  <h1>Cloud Cost Management</h1>
                </li>
                <li>
                  <p>
                    Maximize ROI by implementing effective cloud cost management
                    strategies, ensuring you are getting the most value for your
                    cloud investment.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAN0GXwm77MnN68wockMSxi9ig7Qs1WBpW9x1u3FM9RzoNcFh1DaJPGu6VnM6li4F3Juc&usqp=CAU" />
                </li>
                <li>
                  <h1>Cloud Scalability Optimization</h1>
                </li>
                <li>
                  <p>
                    Ensure your cloud environment scales efficiently with demand
                    while avoiding over-provisioning and excessive costs.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Forrester names Acetians a Leader in Cloud Cost Optimization
                Solutions
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in cloud
                cost optimization, offering advanced tools and strategies that
                help businesses reduce cloud spending while improving
                performance.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://framerusercontent.com/images/KUWrLxiqk8g8knc4SzDWfwTPY.png" />
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://orca.security/wp-content/uploads/2023/02/Blog-graphic_Reducing-Cloud-Spend_Cover.jpg?w=1044" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data-Driven Cloud Cost Insights</h1>
            </li>
            <li>
              <p>
                Use data analytics to uncover valuable insights into your cloud
                spending. Acetians helps you make informed decisions that drive
                cost savings without sacrificing performance.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Get Started</button>
                </li>
                <li>
                  <button>Watch Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Save on Cloud Services</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Cloud Cost Optimization Suite</h3>
                </li>
                <li>
                  <h2>Streamline your cloud resource management</h2>
                </li>
                <li>
                  <p>
                    Access a comprehensive suite of tools that help optimize
                    your cloud infrastructure and reduce costs while maintaining
                    performance.
                  </p>
                </li>
                <li>
                  <h1>View Cloud Cost Optimization Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Cloud Cost Insights Platform</h3>
                </li>
                <li>
                  <h2>Optimize your cloud costs with actionable insights</h2>
                </li>
                <li>
                  <p>
                    Leverage data-driven insights to reduce cloud waste,
                    optimize utilization, and improve cost-efficiency across
                    your cloud environments.
                  </p>
                </li>
                <li>
                  <h1>View Cloud Cost Insights Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ AI-Powered Cloud Optimization Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians uses AI to drive smarter cloud cost
                optimization, ensuring efficiency and scalability for your
                business.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CloudCostOptimization;

import React from "react";
import { Link } from "react-router-dom";

function StoryCard() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  return (
    <div className="story__card_1">
      <ul>
        <li>
          
          <img
            src={
              "https://media.licdn.com/dms/image/D5612AQHdV7JF7Avgcw/article-cover_image-shrink_720_1280/0/1711964512962?e=2147483647&v=beta&t=1eat3uVu7d4KmWY6OBWadizbvrQDVhHxGhvxgJWv0xI"
            }
            className="Story_card_img"
          />
        </li>
        <li>
          <div className="blog_content">
            <h2>App development</h2>
            <h3>
            App development is the process of creating software applications for mobile devices, such as smartphones and tablets, or other platforms, including desktop computers and the web. 
            </h3>
            <Link
              onClick={scrollToTop}
              to="/solutions/digital-solution/web-application-developement"
            >
              Explore more
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default StoryCard;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./CloudSolutionImages/hybridcloudsetu.png";
import { useEffect } from "react";

const HybridCloudSetup = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Hybrid Cloud Setup</h1>
            <h2>
              {" "}
              we offer hybrid cloud solutions for flexibility and control.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Hybrid Cloud Solutions with Acetians</h1>
            </li>
            <li>
              <h2>
                Leverage the power of hybrid cloud to scale your infrastructure
                while maintaining security and performance. Acetians offers
                customized hybrid cloud strategies tailored to your business
                needs.
              </h2>
            </li>
            <li>
              <button>See all services</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEjrq60g8rpx9bKaFTQIx36PJ95cORc3Rl5w&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Hybrid Cloud Expertise</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we specialize in designing hybrid
                cloud architectures that ensure flexibility, scalability, and
                security. Our consultants help you integrate on-premises
                infrastructure with public and private cloud environments
                seamlessly.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cloud Infrastructure Strategy</h1>
                    </li>
                    <li>
                      <p>
                        Build a cloud strategy that optimizes the benefits of
                        both private and public clouds, ensuring seamless
                        workloads and secure data management.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Multi-Cloud and Hybrid Cloud Integration</h1>
                    </li>
                    <li>
                      <p>
                        Ensure smooth integration between your existing cloud
                        platforms and a hybrid setup, maximizing performance and
                        minimizing complexity.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Data Security & Compliance</h1>
                    </li>
                    <li>
                      <p>
                        Leverage strong security protocols and compliance
                        measures to protect your data across both private and
                        public cloud environments.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Hybrid Cloud Innovation</h1>
            </li>
            <li>
              <p>
                We drive innovation by implementing hybrid cloud solutions that
                allow businesses to stay ahead of the curve, enhancing
                performance and scalability for future growth.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start a Consultation</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://daisyuk.tech/wp-content/uploads/2020/08/256-Hybrid-cloud-image-Light-scaled.jpg" />
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ Hybrid Cloud Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians integrates hybrid cloud solutions to
                streamline your operations and empower your business growth.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default HybridCloudSetup;

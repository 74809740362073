import React from "react";
import { Link } from "react-router-dom";
import Img from "./InfraImages/Datastorage.png";
import { useEffect } from "react";

const BusinessStrategyConsulting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Data Center Setup</h1>
            <h2>
              {" "}
              we provide cloud security to protect data from cyber threats.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Cisco's Data Center Solutions</h1>
            </li>
            <li>
              <h2>
                To find the Networking, Security, and Cloud solutions that will
                enhance your data center, check out Cisco's complete index of
                data center offerings.
              </h2>
            </li>
            <li>
              <button>See all data center solutions</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://img.freepik.com/premium-photo/sleek-datacenter-setup-cuttingedge-server-room_1036975-192297.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data Center Infrastructure</h1>
            </li>
            <li>
              <p>
                Our data center solutions are designed to provide you with the
                flexibility and scalability needed to meet your organization's
                growing demands.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cisco UCS (Unified Computing System)</h1>
                    </li>
                    <li>
                      <p>
                        Integrate compute, networking, and storage into a
                        single, unified platform to optimize your data center
                        performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cisco HyperFlex Systems</h1>
                    </li>
                    <li>
                      <p>
                        A scalable hyper-converged infrastructure platform that
                        combines compute, storage, and networking for agile,
                        flexible operations.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cisco Nexus Switches</h1>
                    </li>
                    <li>
                      <p>
                        Ensure high availability and low-latency performance
                        with industry-leading data center networking solutions.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cisco CloudCenter</h1>
                    </li>
                    <li>
                      <p>
                        Manage, automate, and scale applications across
                        multi-cloud environments to optimize the performance of
                        your data center.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cisco ACI (Application Centric Infrastructure)</h1>
                    </li>
                    <li>
                      <p>
                        Simplify and automate your data center network with a
                        policy-based approach to managing applications and
                        workloads.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Small Business Data Center Solutions</h1>
            </li>
            <li>
              <p>
                Build a simple, secure, and scalable data center that meets the
                needs of your small business.
              </p>
            </li>
            <li>
              <button>Explore Cisco Data Center for Small Businesses</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.fieldlink.net/wp-content/uploads/2024/06/Data-Center-Careers.jpg" />
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://www.se.com/in/en/assets/v2/380/media/233534/900/885294096-IC-980x560.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Maximize Efficiency with Data Center Automation</h1>
            </li>
            <li>
              <p>
                Leverage Cisco’s AI-driven tools and automation technologies to
                reduce costs and improve the performance of your data center.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Secure Your Data Center with Cisco's Security Solutions</h1>
            </li>
            <li>
              <p>
                Protect your data center from evolving cyber threats with
                Cisco’s advanced security technologies designed to secure your
                critical assets.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Learn More</button>
                </li>
                <li>
                  <button>See All Security Solutions</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
      <ul className="business-component-5">
        <li>
          <img src="https://mycloudwiki.com/wp-content/uploads/2016/06/real-time-monitoring-diagram.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>
                Enhance Data Center Scalability with Cisco Cloud Solutions
              </h1>
            </li>
            <li>
              <p>
                Leverage hybrid cloud solutions to extend your data center
                capabilities and scale your infrastructure as your business
                grows.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global Enterprises Trust Cisco for Data Center Solutions</h1>
            </li>
            <li>
              <h2>
                Cisco is the trusted partner for building and securing data
                centers across the world. Let us help you with your next data
                center project.
              </h2>
            </li>
            <li>
              <button>Why Choose Cisco</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-9">
        <li>
          <h1>Cisco: Powering the Data Center of Tomorrow</h1>
        </li>
        <li>
          <p>
            With cutting-edge solutions, Cisco is driving innovation in data
            center architecture, making it easier to manage and scale your
            infrastructure.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default BusinessStrategyConsulting;

import React, { useState } from "react";
import ProductTabComponent from "./ProductTabComponent";
import SolutionTabComponent from "../components/SolutionTabComponent";
import Support from "./Support";
import Learn from "../components/Learn";
import Bottom_header from "../Headers_Components/Bottom_header";
import Responsive_nav_link from "../components/Responsive_nav_link";
import { useEffect } from "react";
import ConsultingServices from "./ProductandServices/ConsultingServices"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function Header() {

  const [show, setshow] = useState(false);
  const sublinks = [
    { name: "Business Strategy", path: "/business-strategy" },
    { name: "Consulting Services", path: "/consulting-services" },
    { name: "Digital Transformation", path: "/digital-transformation" },
    { name: "Technology Solutions", path: "/technology-solutions" },

    
  ];

  const renderComponent = () => {
    switch (show) {
      case "product":
        return <ProductTabComponent  setshow={setshow} />;
      case "solution":
        return <SolutionTabComponent setshow={setshow} />;
      case "Support":
        return <Support setshow={setshow} />;
      case "Learn":
        return <Learn setshow={setshow} />;
      case "nav-link":
        return <Responsive_nav_link onSelect={setshow} />;
      case "Resp_Product_list":
        return <ProductTabComponent setshow={setshow} />;
      case "Resp_Solution_list":
        return <SolutionTabComponent setshow={setshow} />;
      case "Resp_Support_list":
        return <Support setshow={setshow} />;
      case "Resp_Learn_list":
        return <Learn setshow={setshow} />;
      default:
        return null;
    }
  };

  const hidecomponent = () => {
    setshow(false);
  };


  return (
    <div className="Header-part">

      <div>
        <Bottom_header onSelect={setshow} gotohome={hidecomponent} />
      </div>

      <div className="tab-compo">
        {show && (
          <div className="component-wrapper">
           
            <div  className="closes-button" >

            <CloseRoundedIcon style={{
              fontSize:'35px'
            }}   onClick={hidecomponent}/>
            </div>
            
             
            {renderComponent()}
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;

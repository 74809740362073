import React from "react";
import { Link } from "react-router-dom";
import Img from "./DigitalExeImg/WebApplications.png";
import { useEffect } from "react";

const WebApplicationdDevelopement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Web Application Development Solutions</h1>
            <h2>
              we develop high-performance web apps tailored to your business
              needs.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>
            Explore our comprehensive Web Application Development services
          </h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg8SOd4d692mjWaoG4iSUBA4KEfBpVK6Oqqg&s" />
                </li>
                <li>
                  <h1>Custom Web Application Development</h1>
                </li>
                <li>
                  <p>
                    We design and develop custom web applications tailored to
                    your specific business requirements, ensuring they align
                    with your operational goals and objectives.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.beehexa.com/wp-content/uploads/2024/03/beehexa_image-6.png" />
                </li>
                <li>
                  <h1>Progressive Web App Development</h1>
                </li>
                <li>
                  <p>
                    We help you build Progressive Web Apps (PWAs) that combine
                    the best of web and mobile apps, providing users with
                    faster, reliable, and engaging experiences.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.webdesignerexpress.com/images/x2/api-integration.jpg" />
                </li>
                <li>
                  <h1>Responsive Web Design</h1>
                </li>
                <li>
                  <p>
                    Our responsive web designs ensure that your web applications
                    look great and function flawlessly on all devices, from
                    desktops to smartphones.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies Web Development Report 2024</h1>
            </li>
            <li>
              <p>
                Explore our latest insights from the web development landscape,
                including emerging technologies, development best practices, and
                case studies that help improve your application development
                process.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Save on Web Development Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Web Application Development Suite</h3>
                </li>
                <li>
                  <h2>End-to-end web application solutions</h2>
                </li>
                <li>
                  <p>
                    Build, deploy, and manage robust web applications with our
                    all-in-one web application development suite, tailored for
                    scalability and performance.
                  </p>
                </li>
                <li>
                  <h1>View Web Development Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Web Design Platform</h3>
                </li>
                <li>
                  <h2>Beautiful and functional web design</h2>
                </li>
                <li>
                  <p>
                    Our platform helps you create visually appealing and
                    functional web applications, ensuring a smooth user
                    experience across all devices.
                  </p>
                </li>
                <li>
                  <h1>View Web Design Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-6">
        <li>
          <h1>Enhance user engagement with Acetians’ Web Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://qat.com/wp-content/uploads/2021/09/enterprise-application-integration-s.png" />
                </li>
                <li>
                  <h1> . Power</h1>
                </li>
                <li>
                  <p>
                    Acetians is recognized for providing excellent user
                    experience through innovative web development and seamless
                    digital interfaces.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://5.imimg.com/data5/NB/SX/MY-4108331/web-application-development-250x250.jpg" />
                </li>
                <li>
                  <h1> . Power</h1>
                </li>
                <li>
                  <p>
                    Acetians is recognized for providing excellent user
                    experience through innovative web development and seamless
                    digital interfaces.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://5.imimg.com/data5/XP/TA/GLADMIN-30521882/enterprise-integration-service-500x500.png" />
                </li>
                <li>
                  <h1> . Power</h1>
                </li>
                <li>
                  <p>
                    Acetians is recognized for providing excellent user
                    experience through innovative web development and seamless
                    digital interfaces.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default WebApplicationdDevelopement;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./ConsultingService/RiskManagementConsulting.png";

const ITInfrastructureCounsilting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div className="WhyBharat" style={{ backgroundImage: `url(${Img})` }}>
        <ul>
          <li>
            <h1> Risk Management Services</h1>
            <h2>
              Protect your business with robust risk management strategies and
              solutions.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHRZW3aS6960atUAEcuU9N4z5E5kVdxp6PTg&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Comprehensive Risk Management</h1>
            </li>
            <li>
              <p>
                Our risk management solutions offer you the necessary tools to
                proactively identify, assess, and mitigate potential risks that
                could disrupt your operations and growth.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Risk Assessment Tools</h1>
                    </li>
                    <li>
                      <p>
                        Identify vulnerabilities and threats that could impact
                        your business operations and determine the potential
                        consequences.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Risk Mitigation Strategies</h1>
                    </li>
                    <li>
                      <p>
                        Develop and implement risk mitigation plans to reduce
                        the likelihood and impact of potential risks to your
                        business.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Incident Response</h1>
                    </li>
                    <li>
                      <p>
                        Prepare for and respond to risk events quickly and
                        effectively, minimizing the impact on your organization.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Compliance and Regulatory Risk</h1>
                    </li>
                    <li>
                      <p>
                        Ensure your business meets all regulatory requirements
                        and complies with relevant industry standards to avoid
                        legal and financial penalties.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Business Continuity Planning</h1>
                    </li>
                    <li>
                      <p>
                        Create effective business continuity plans to maintain
                        critical operations during and after any major risk
                        event.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Risk Management Tools</h1>
            </li>
            <li>
              <h2>
                To identify, assess, and mitigate risks across your business,
                check out our suite of comprehensive risk management solutions.
              </h2>
            </li>
            <li>
              <button>See all solutions</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-12">
        <li>
          <img src="https://www.fujitsu.com/ph/Images/riskmanagement_2016-11_tcm113-1237628.gif" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Proactive Risk Management for Critical Operations</h1>
            </li>
            <li>
              <p>
                Discover how proactive risk management approaches can help you
                safeguard your most critical business functions and ensure
                long-term success.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with our Risk Management Experts</h1>
            </li>
            <li>
              <p>
                Speak to one of our specialists to learn how we can help you
                mitigate risks, ensure compliance, and improve business
                continuity.
              </p>
            </li>
            <li>
              <button>Contact us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/spotlight.img.jpg/1693555465493.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Protecting Your Organization from Emerging Risks</h1>
            </li>
            <li>
              <h2>
                As new risks emerge, it's essential to stay ahead of potential
                threats. Let us help you build the resilience needed to protect
                your business in an ever-evolving risk landscape.
              </h2>
            </li>
            <li>
              <button>Why Choose Us</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ITInfrastructureCounsilting;

import React from "react";
import { Link } from "react-router-dom";

const ConsultingServices = ({ sublinks, setshow }) => {
  // Function to close the component when a link is clicked
  const closeComponent = () => {
    setshow(false); // Close the component (set show to false)
  };

  return (
    <div>
      {sublinks.length > 0 ? (
        <ul className="main-page-content">
          {sublinks.map((sublink, index) => (
            <div key={index}>
              <div>
                <img
                  src={sublink.image}
                  className="content-img"
                  alt="product-service"
                />
              </div>
              <div>
                <Link to={sublink.path}>
                  <p onClick={closeComponent}  className="head-text-1">{sublink.name}</p>
                </Link>
                <p className="text-info">{sublink.text}</p>
              </div>
            </div>
          ))}
        </ul>
      ) : (
        <p>No sublinks available.</p>
      )}
    </div>
  );
};

export default ConsultingServices;

import React from "react";
import { Link } from "react-router-dom";
import Img from "../Learn Images/PlacementAssestance.png";
import { useEffect } from "react";

const PlacementAssistance = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Placement Assistance</h1>
            <h2>
              Guiding you towards the perfect career opportunity with expert
              placement support.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://5.imimg.com/data5/XS/UP/GLADMIN-46561776/placement-assistance-250x250.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Comprehensive Placement Support</h1>
            </li>
            <li>
              <p>
                Our placement assistance services help you secure the job of
                your dreams. From resume building to interview coaching, we
                guide you every step of the way to ensure success in your job
                search.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Resume Building</h1>
                    </li>
                    <li>
                      <p>
                        Craft a standout resume with the help of our experts,
                        highlighting your skills and experience to impress
                        potential employers.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Interview Preparation</h1>
                    </li>
                    <li>
                      <p>
                        Receive personalized coaching and mock interviews to
                        help you confidently navigate any interview and leave a
                        lasting impression.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Job Matching</h1>
                    </li>
                    <li>
                      <p>
                        We connect you with top companies and match you with job
                        opportunities that align with your skills, experience,
                        and career goals.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Interview Scheduling</h1>
                    </li>
                    <li>
                      <p>
                        We assist with scheduling interviews with leading
                        employers and help coordinate all the necessary
                        logistics for your success.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Career Counseling</h1>
                    </li>
                    <li>
                      <p>
                        Receive professional career guidance to help you make
                        informed decisions and chart a clear path toward your
                        career success.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Placement Services</h1>
            </li>
            <li>
              <h2>
                Discover how our placement assistance services can help you land
                your ideal job faster and more effectively.
              </h2>
            </li>
            <li>
              <button>See all services</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-12">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAXeMDgXI2jagP9sJfihZuebw5W1tNh3jGPA&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Job Search Strategies for Success</h1>
            </li>
            <li>
              <p>
                Learn effective job search strategies that increase your chances
                of getting hired. We help you stand out in a competitive job
                market.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Tailored Career Pathways</h1>
            </li>
            <li>
              <h2>
                Get personalized advice and guidance tailored to your career
                goals and aspirations. Let us help you build a roadmap for
                success in your chosen field.
              </h2>
            </li>
            <li>
              <button>Why Choose Us</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Our Placement Experts</h1>
            </li>
            <li>
              <p>
                Speak with one of our experts to learn how we can assist you
                with job placement, career growth, and landing the job that's
                right for you.
              </p>
            </li>
            <li>
              <button>Contact us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/spotlight.img.jpg/1693555465493.jpg" />
        </li>
      </ul>
    </div>
  );
};

export default PlacementAssistance;

import React from "react";
import { Link } from "react-router-dom";

function InnovationCard({ imageSrc, title, description, exploreLink }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };

  return (
    <div className="innovation__card">
      <img src={imageSrc} alt="Innovation" />
      <div className="blog_content">
        <div>
          {" "}
          <Link onClick={scrollToTop} to={exploreLink}>
            {title}
          </Link>
        </div>

        <h3>{description}</h3>
      </div>
    </div>
  );
}

export default InnovationCard;

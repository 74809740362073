import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InstructureWorkshop/datascience.png";

const DataScienceBootcamps = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Data Science Bootcamps.</h1>
            <h2> we offer Data Science Bootcamps to kickstart your career.</h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Data Science</h1>
            </li>
            <li>
              <h2>
                Leverage our expert-led training to master the skills you need
                for a career in data science. Acetians offers comprehensive
                bootcamps tailored to your career goals.
              </h2>
            </li>
            <li>
              <button>See all bootcamps</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://images.unsplash.com/photo-1666875753105-c63a6f3bdc86?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGF0YSUyMHNjaWVuY2V8ZW58MHx8MHx8fDA%3D" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data Science Consulting Expertise</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers expert consulting services to help
                you navigate the data science landscape. From data analysis to
                machine learning implementation, we help you turn data into
                actionable insights.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Machine Learning Fundamentals</h1>
                    </li>
                    <li>
                      <p>
                        Build a strong foundation in machine learning algorithms
                        and techniques to analyze large datasets and generate
                        predictive models.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Advanced Data Analytics</h1>
                    </li>
                    <li>
                      <p>
                        Dive deep into advanced data analysis methods, including
                        deep learning and neural networks, to solve complex
                        problems.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Big Data Solutions</h1>
                    </li>
                    <li>
                      <p>
                        Learn how to work with big data technologies like Hadoop
                        and Spark to process and analyze massive datasets
                        efficiently.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile & Lean Data Science Methodologies</h1>
            </li>
            <li>
              <p>
                Acetians Technologies focuses on agile methodologies that
                enhance the efficiency of data science projects. Our bootcamp
                emphasizes lean data processes to reduce inefficiencies while
                increasing the value of your analyses.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.ibat.ie/plugins/coursefilter/images/DATA_ANALYTICS.JPG" />
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://www.investopedia.com/thmb/iDusjsH0gYqpGVQOILvKVSlHsVk=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/data-analytics-4198207-1-ad97301587ac43698a095690bc58c4c1.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Leadership in Data Science Education</h1>
            </li>
            <li>
              <p>
                Acetians Technologies provides world-class leadership training
                to develop visionary data science leaders capable of driving
                strategic initiatives.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global Data Science Education Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies offers data science bootcamps tailored to
                help students and professionals succeed in the global data
                science market.
              </h2>
            </li>
            <li>
              <button>Why Acetians</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Data Science for Business Transformation</h1>
        </li>
        <li>
          <p>
            Accelerate your business transformation with Acetians' data science
            expertise, helping you leverage data for smarter business decisions.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile.img.png/1687847290678.png" />
                </li>
                <li>
                  <h1>Optimize Data Strategies</h1>
                </li>
                <li>
                  <p>
                    Learn how to optimize data strategies to ensure data
                    accuracy and quality for better decision-making.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile_1254810483.img.png/1687847290735.png" />
                </li>
                <li>
                  <h1>Maximize ROI with Data Science</h1>
                </li>
                <li>
                  <p>
                    Discover how Acetians’ data science strategies can optimize
                    business performance and maximize ROI.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/blade_305119356/bladeContents/scroll_container/scrollContent/simple_tile_1809266851.img.png/1687847290706.png" />
                </li>
                <li>
                  <h1>Enhance Business Analytics</h1>
                </li>
                <li>
                  <p>
                    Drive business growth with advanced data analytics and
                    insights that empower your business decisions.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians' Data Science Bootcamps</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians' Data Science Bootcamps can transform your
                career and accelerate your journey to becoming a data science
                expert.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default DataScienceBootcamps;

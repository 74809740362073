import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InfraImages/itsystems.png";

const ITInfrastructureCounsilting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Scalable IT Systems</h1>
            <h2>
              {" "}
              Design and implement IT infrastructure solutions that grow with
              your business.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://media.licdn.com/dms/image/C4E12AQEU8n_3_nChRQ/article-cover_image-shrink_600_2000/0/1631005203371?e=2147483647&v=beta&t=j_U5Un8CTQqsVJnZT4IOfKwrYes6A5vA-Ca0PAxXoiE" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Comprehensive Scalable IT Solutions</h1>
            </li>
            <li>
              <p>
                Our scalable IT solutions are designed to meet the current and
                future needs of your business, ensuring that your infrastructure
                can grow and adapt with your organization's evolving
                requirements.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cloud Integration</h1>
                    </li>
                    <li>
                      <p>
                        Seamlessly integrate cloud technologies with your
                        existing IT infrastructure to enable flexibility,
                        scalability, and cost-efficiency.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Flexible Infrastructure Design</h1>
                    </li>
                    <li>
                      <p>
                        Build an IT infrastructure that can expand or contract
                        based on your business's needs, ensuring that your
                        resources are always optimized.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Automation and Orchestration</h1>
                    </li>
                    <li>
                      <p>
                        Automate key IT processes and orchestrate your systems
                        for greater efficiency, enabling your infrastructure to
                        scale automatically as your needs grow.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Data Center Optimization</h1>
                    </li>
                    <li>
                      <p>
                        Optimize your data centers with scalable solutions that
                        allow for seamless growth while reducing operational
                        costs and improving performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>High Availability Systems</h1>
                    </li>
                    <li>
                      <p>
                        Design and implement IT systems with built-in redundancy
                        to ensure high availability and minimize downtime, even
                        during periods of growth.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Scalable IT Solutions</h1>
            </li>
            <li>
              <h2>
                Discover our suite of solutions designed to help you create an
                IT infrastructure that grows alongside your business needs and
                supports your long-term goals.
              </h2>
            </li>
            <li>
              <button>See all solutions</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Our IT Infrastructure Experts</h1>
            </li>
            <li>
              <p>
                Speak with one of our specialists to learn how scalable IT
                systems can help your business grow efficiently and effectively.
              </p>
            </li>
            <li>
              <button>Contact us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/spotlight.img.jpg/1693555465493.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Optimizing IT for Business Agility</h1>
            </li>
            <li>
              <h2>
                Achieve business agility by designing IT systems that are
                flexible, scalable, and able to adapt to changing market
                conditions and demands.
              </h2>
            </li>
            <li>
              <button>Why Choose Us</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ITInfrastructureCounsilting;

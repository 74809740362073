import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "./InfraImages/Networkdesign.png";

const NetworkDesignConsulting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Network Design Services</h1>
            <h2>
              {" "}
              Building robust, scalable, and secure network infrastructures for
              your business.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Discover the best network design tools</h1>
            </li>
            <li>
              <h2>
                Explore advanced network design tools that enable you to plan,
                simulate, and optimize your network architecture for maximum
                performance.
              </h2>
            </li>
            <li>
              <button>See all tools</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-9">
        <li>
          <h1>
            Utilize scalable network architectures to drive business success
          </h1>
        </li>
        <li>
          <p>
            Our network design services help you create scalable and flexible
            network architectures that grow with your business, ensuring high
            performance and minimal disruption as your needs evolve.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>High Availability Network Design</h1>
                </li>
                <li>
                  <p>
                    Build resilient networks with redundant components and
                    failover systems to minimize downtime and ensure your
                    business stays online.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Optimized Network Traffic Management</h1>
                </li>
                <li>
                  <p>
                    Design networks that intelligently manage traffic flows,
                    prioritizing critical applications and ensuring efficient
                    resource allocation across your network.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Secure Network Design</h1>
                </li>
                <li>
                  <p>
                    Protect your data and operations with a secure network
                    design that incorporates encryption, firewalls, and access
                    control systems to safeguard against cyber threats.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Cloud Integration and Hybrid Networks</h1>
                </li>
                <li>
                  <p>
                    Design hybrid network infrastructures that seamlessly
                    integrate on-premises systems with cloud environments,
                    enabling flexible and scalable IT operations.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Future-Proof Network Design</h1>
                </li>
                <li>
                  <p>
                    Ensure your network is ready for the future with designs
                    that incorporate emerging technologies and evolving business
                    needs, from IoT to 5G integration.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Transform your network with innovative designs</h1>
            </li>
            <li>
              <p>
                Empower your business with cutting-edge network designs that
                enable seamless communication, collaboration, and scalability
                across your organization.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default NetworkDesignConsulting;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./DigitalExeImg/MobileAppOptimization.png";
import { useEffect } from "react";

const MobileAppOptimization = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Mobile App Optimization Services</h1>
            <h2>
              we optimize mobile apps for better performance and user
              experience.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Maximize Mobile App Performance with Advanced Optimization</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive Mobile App Optimization</h3>
                </li>
                <li>
                  <h2>Enhance App Speed and Responsiveness</h2>
                </li>
                <li>
                  <p>
                    Our mobile app optimization services focus on boosting
                    speed, performance, and user satisfaction by identifying
                    bottlenecks and enhancing the overall efficiency of your
                    application.
                  </p>
                </li>
                <li>
                  <h1>Discover Our Mobile App Optimization Solutions</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Optimize User Experience</h3>
                </li>
                <li>
                  <h2>Improve User Interface (UI) and User Experience (UX)</h2>
                </li>
                <li>
                  <p>
                    Our team works to ensure your mobile app delivers an
                    intuitive and enjoyable experience for users, with smooth
                    navigation and a responsive interface on both Android and
                    iOS devices.
                  </p>
                </li>
                <li>
                  <h1>Learn More About User Experience Optimization</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Acetians Technologies: Leading Mobile App Optimization Services
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies is dedicated to enhancing your mobile
                apps’ performance, ensuring faster load times, better
                responsiveness, and smoother user experiences. Our optimization
                techniques help you stay ahead in a competitive app market.
              </p>
            </li>
            <li>
              <button>Read Our Latest Insights</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.searchonic.com/_nuxt/img/App-store-optimization.3d5b9fa.jpg" />
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global Mobile App Optimization Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies provides global app optimization services
                designed to enhance the performance and scalability of your
                mobile applications across different regions and devices.
              </h2>
            </li>
            <li>
              <button>Explore Our Global Solutions</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-7">
        <li>
          <h1>Unlock Efficiency with Mobile App Optimization</h1>
        </li>
        <li>
          <p>
            Acetians Technologies helps you unlock operational efficiencies by
            optimizing your mobile applications, ensuring they run smoothly
            across all devices and platforms while enhancing user satisfaction
            and engagement.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize App Performance Across Devices</h1>
                </li>
                <li>
                  <p>
                    Use cutting-edge app optimization tools to ensure your app
                    performs consistently across different mobile devices and
                    operating systems.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Monitor App Performance 24/7</h1>
                </li>
                <li>
                  <p>
                    Gain 24/7 insights into your app’s performance, allowing you
                    to detect and resolve issues quickly, ensuring maximum
                    uptime and performance.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Real-Time Optimization Insights</h1>
                </li>
                <li>
                  <p>
                    Acetians’ real-time performance monitoring tools provide
                    instant feedback and early warnings, allowing you to address
                    performance issues before they impact user experience.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Boost Your Mobile App’s Performance with Expert Consulting</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>
                    Comprehensive Mobile App Optimization for Business Growth
                  </h1>
                </li>
                <li>
                  <p>
                    Our optimization strategies are designed to help your app
                    scale effectively, improving speed, usability, and overall
                    app performance.
                  </p>
                </li>
                <li>
                  <h1>Get in Touch with Us</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default MobileAppOptimization;

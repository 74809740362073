import React from "react";
import { Link } from "react-router-dom";
import Img from "./CloudSolutionImages/cloudmigration.png";
import { useEffect } from "react";

const CloudMigration = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cloud Migration Services.</h1>
            <h2>
              {" "}
              we guide your business through seamless cloud migration, ensuring
              minimal disruption and optimal performance in your new cloud
              environment.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our comprehensive Cloud Migration solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5OBpOZh7vGospY5JrfEMkc6iPuC7cJrxnEg&s" />
                </li>
                <li>
                  <h1>Cloud Migration Strategy</h1>
                </li>
                <li>
                  <p>
                    We provide expert advice on building a customized cloud
                    migration strategy that aligns with your unique business
                    needs and ensures smooth execution.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://cdn.techjockey.com/blog/wp-content/uploads/2023/02/15122535/What-is-Cloud-Migration-Benefits-Types-Strategy-_feature.jpg" />
                </li>
                <li>
                  <h1>Data Migration & Security</h1>
                </li>
                <li>
                  <p>
                    We ensure seamless data migration with secure methods,
                    minimizing downtime and ensuring data integrity throughout
                    the process.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu8xAViJvWctBDOp7tNwhwXgn1JSD0l9lHvw&s" />
                </li>
                <li>
                  <h1>Cloud Infrastructure Management</h1>
                </li>
                <li>
                  <p>
                    Manage and optimize your cloud infrastructure to ensure
                    performance, scalability, and reliability after the
                    migration process.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Forrester names Acetians a Leader in Cloud Migration</h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in the
                cloud migration field, offering cutting-edge tools and best
                practices to ensure successful and secure migrations.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.anblicks.com/wp-content/uploads/2022/08/why-is-it-better-to-migrate-on-premise-data-to-cloud.png" />
        </li>
      </ul>
      <ul className="business-component-1">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpZ9DtDybsU0v9uPGsfSpO97U-gxSfe_B_g&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Cloud Migration Expertise</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we specialize in making cloud
                migrations smooth, efficient, and secure, with tailored
                solutions for every business.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cloud Strategy Development</h1>
                    </li>
                    <li>
                      <p>
                        Develop a comprehensive strategy for moving to the cloud
                        with minimal risk and maximum benefit to your
                        operations.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Cost Optimization & ROI</h1>
                    </li>
                    <li>
                      <p>
                        Optimize cloud costs by utilizing the best practices in
                        cloud economics, and achieve better ROI from your cloud
                        investments.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Post-Migration Support</h1>
                    </li>
                    <li>
                      <p>
                        Receive comprehensive support after migration to ensure
                        that your systems remain optimized and secure in the
                        cloud.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CloudMigration;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./HardwareSolutionImages/hardwarementain.png";
import { useEffect } from "react";
const HardwareMaintenance = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Hardware Maintenance</h1>
            <h2>
              {" "}
              Ensuring your infrastructure runs smoothly with our expert support
              and services.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-4" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>
                Maximize the Life of Your Hardware with Acetians Maintenance
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers a wide range of hardware
                maintenance services designed to maximize the performance and
                lifespan of your equipment. Our expert technicians ensure
                minimal downtime and improved reliability.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Request a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://mglobalservices.com/wp-content/uploads/2020/12/hardware-maintenance-blog-featured.jpg" />
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://scot-comp.co.uk/wp-content/uploads/2023/09/computer-maintenance-and-repair.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Proactive Hardware Support for Seamless Operations</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we offer proactive hardware
                maintenance that helps prevent unexpected breakdowns and ensure
                your systems are always performing at their best. Our support
                services help maintain your critical infrastructure with regular
                checks and updates.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Request a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Acetians' Hardware Maintenance Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians provides customized hardware maintenance packages
                designed to meet the unique needs of your business, helping you
                avoid unplanned downtimes and enhancing your system's
                reliability.
              </h2>
            </li>
            <li>
              <button>See All Services</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-7">
        <li>
          <h1>Delivering Reliable Hardware Maintenance Services</h1>
        </li>
        <li>
          <p>
            Acetians Technologies ensures top-tier hardware maintenance services
            with a focus on reliability and minimal disruption to your daily
            operations. Our skilled technicians are available for on-site and
            remote support.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize Hardware Performance</h1>
                </li>
                <li>
                  <p>
                    Our maintenance services include hardware optimization to
                    ensure your systems are operating at peak efficiency,
                    improving overall performance and reducing energy
                    consumption.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Long-term System Reliability</h1>
                </li>
                <li>
                  <p>
                    We work with you to plan for long-term hardware health. Our
                    scheduled maintenance and quick response services help keep
                    your systems running smoothly, avoiding unnecessary hardware
                    failures.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Preventive Maintenance</h1>
                </li>
                <li>
                  <p>
                    We provide preventive maintenance services to identify and
                    fix issues before they cause any downtime, ensuring your
                    systems run continuously without unexpected interruptions.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Us for Hardware Maintenance Services</h1>
            </li>
            <li>
              <p>
                Contact our expert team to learn how Acetians Technologies can
                provide ongoing support and maintenance to ensure the
                reliability of your hardware systems.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://5.imimg.com/data5/SELLER/Default/2022/11/TU/BT/KV/8296280/hardware-maintenance.jpg" />
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians: Trusted by Leading Businesses</h1>
            </li>
            <li>
              <h2>
                No matter your hardware needs, Acetians Technologies has the
                experience and expertise to support your business. We deliver
                value with every service.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default HardwareMaintenance;

import React, { useState } from "react";
import Learnhome from "../components/Learn/Learnhome";
import Data from "../schema.json";
import CampusPrograms from "./Learn/CampusPrograms";
import AcetiansITAcademy from "./Learn/AcetiansITAcademy";
import SkillDevelopementPrograms from "./Learn/SkillDevelopementPrograms";
import AiEducatoresPRograms from "./Learn/AiEducatoresPRograms";
import InstructorLedWorkshop from "./Learn/InstructorLedWorkshop";

function ProductTabComponent({ setshow }) {
  const [isshow, setisshow] = useState(false);
  const [Color, setColor] = useState(false);
  const [sublinks, setSublinks] = useState([]); // State to hold sublinks

  const showcomponent = (tab, sublinks) => {
    setisshow(tab); // Set the component to show
    setSublinks(sublinks); // Set the sublinks to display in the respective component
    if (Color === "lightcoral") {
      return;
    }
    setColor("lightcoral");
  };
  const learnLinks = Data[3]?.links || [];

  return (
    <div className="tab">
      <div className="tab-list">
        <ul className="list-link">
          <li
            onClick={() => showcomponent("Learn Home", [])}
            className="main-page"
          >
            Learn
          </li>
          {learnLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => showcomponent(link.name, link.sublinks)} // Passing sublinks of clicked section
            >
              {link.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="list-content">
        {isshow === false && <Learnhome />}
        {isshow === "Learn Home" && <Learnhome />}
        {isshow === "Campus Programs" && (
          <CampusPrograms sublinks={sublinks} setshow={setshow} />
        )}
        {isshow === "Acetians IT Academy" && (
          <AcetiansITAcademy sublinks={sublinks} setshow={setshow} />
        )}
        {isshow === "Skill Development" && (
          <SkillDevelopementPrograms sublinks={sublinks} setshow={setshow} />
        )}
        {isshow === "AI Educator Programs" && (
          <AiEducatoresPRograms sublinks={sublinks} setshow={setshow} />
        )}
        {isshow === "Workshops" && (
          <InstructorLedWorkshop sublinks={sublinks} setshow={setshow} />
        )}
      </div>
    </div>
  );
}

export default ProductTabComponent;

import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "./CampusProgramImg/Hackthons.png";

const Hackathons = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Hackathon Programs</h1>
            <h2>
              {" "}
              we host hackathons to inspire innovation and solve real-world
              problems.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-2" id="globalpre">
        <li>
          <h1>Latest Hackathon News and Events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://cdn.prod.website-files.com/5e9aa66fd3886aa2b4ec01ca/658a45652a4e84000dc8e073_what%20is%20a%20hackerthon%20(1).webp" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Connect with Innovators at Global Hackathons</h1>
                </li>
                <li>
                  <p>
                    Join our global hackathons to collaborate with industry
                    experts, developers, and innovators. Discover new trends,
                    build cutting-edge solutions, and connect with others in the
                    tech community.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join a Hackathon</button>
                    </li>
                    <li>
                      <button>Learn More</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Upcoming Hackathon Challenges</h1>
            </li>
            <li>
              <p>
                Explore the exciting themes for our upcoming hackathons,
                focusing on cutting-edge technologies like AI, blockchain, IoT,
                and more. Stay tuned for registration details and event updates.
              </p>
            </li>
            <li>
              <button>Register Now</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Featured Hackathons and Challenges</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>AI Innovation Challenge</h3>
                </li>
                <li>
                  <h2>Create solutions using AI and machine learning</h2>
                </li>
                <li>
                  <p>
                    Dive into the world of AI and showcase your ability to solve
                    complex challenges using AI tools and frameworks. Work with
                    experts to develop scalable and impactful solutions.
                  </p>
                </li>
                <li>
                  <h1>Join the AI Challenge</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Blockchain for Good</h3>
                </li>
                <li>
                  <h2>Leverage blockchain technology to solve social issues</h2>
                </li>
                <li>
                  <p>
                    Join us to develop blockchain solutions that can address
                    social challenges and improve transparency, security, and
                    efficiency in various sectors.
                  </p>
                </li>
                <li>
                  <h1>Learn More About Blockchain</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Hackathon Partnerships and Sponsors</h1>
            </li>
            <li>
              <p>
                Acetians Technologies partners with global tech companies,
                universities, and innovation hubs to host large-scale hackathons
                and coding events. Join us as a sponsor or participant to
                support groundbreaking solutions.
              </p>
            </li>
            <li>
              <button>Become a Sponsor</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1mAuvHYA4hmcM08jNzjam6sgSgH53jV-UdCEmrZmGSDh-EsJu7iZjzBg94Welj11iOEU&usqp=CAU" />
        </li>
      </ul>
      <ul className="business-component-5">
        <li>
          <img src="https://img.freepik.com/free-vector/hackathon-isometric-landing-software-development_107791-2942.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Hackathon Mentoring and Training</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers mentoring and training to help
                hackathon participants refine their skills, learn new tools, and
                maximize their potential to succeed in hackathon challenges.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Training</button>
                </li>
                <li>
                  <button>Schedule a Mentorship</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Hackathons;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "../LEARNIMAGES/AICertificates.png";

const AICertificates = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> AI Certificates</h1>
            <h2>
              {" "}
              we provide AI certification programs for mastery and innovation.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://facialix.com/wp-content/uploads/2023/09/curso-inteligencia-artificial-certificado-facialix.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>AI Certification Programs</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers a variety of AI certification
                programs that cater to both beginners and experts. Our programs
                focus on key AI concepts, machine learning techniques, and
                real-world applications.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Machine Learning & Deep Learning</h1>
                    </li>
                    <li>
                      <p>
                        Get certified in machine learning and deep learning
                        techniques that empower you to build smarter systems and
                        predictive models.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>AI for Business Applications</h1>
                    </li>
                    <li>
                      <p>
                        Learn how to implement AI to solve business challenges,
                        enhance customer experiences, and optimize operations.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Ethical AI & Governance</h1>
                    </li>
                    <li>
                      <p>
                        Gain knowledge in the ethical considerations of AI
                        deployment and understand governance frameworks to
                        ensure responsible AI practices.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-1">
        <li>
          <h1>Explore our AI-powered certification solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://s3.amazonaws.com/jigyaasa_assets/images/new/AIZ-110_pro.png?1591249753.png?25102024" />
                </li>
                <li>
                  <h1>AI Implementation Strategies</h1>
                </li>
                <li>
                  <p>
                    Learn how to develop and implement AI-powered strategies in
                    your organization to drive innovation and improve business
                    outcomes.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-zHiv6fdoiKl1EPPj3hHHA_h9OjypCQUWDzbFx6r9R_jZ9KUKWKkSiJ3bPnx_GY8tixA&usqp=CAU" />
                </li>
                <li>
                  <h1>AI in Data Science</h1>
                </li>
                <li>
                  <p>
                    Explore the role of AI in data science, from predictive
                    analytics to natural language processing (NLP), and enhance
                    your data-driven decision-making.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKKZQgYJ7P1OCnRgqrfRgU7Nl3wiSKpVtRJw&s" />
                </li>
                <li>
                  <h1>AI & Cloud Computing</h1>
                </li>
                <li>
                  <p>
                    Dive into how AI integrates with cloud technologies,
                    enabling scalable AI models and solutions for enterprise
                    applications.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile & Lean AI Strategies</h1>
            </li>
            <li>
              <p>
                Acetians Technologies helps businesses implement agile and lean
                AI strategies that focus on continuous improvement and
                delivering value quickly. Learn how to apply AI in iterative
                cycles to solve complex business problems.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.nttdata.com/global/en/-/media/nttdataglobal/1_images/insights/generative-ai/generative-ai_d.jpg?h=1680&iar=0&w=2800&rev=4e69afcc968d4bab9480891634b63b34" />
        </li>
      </ul>

      <ul className="compo-6">
        <li>
          <h1>Enhance Skills with Acetians Technologies AI Programs</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://cdn.britannica.com/47/246247-050-F1021DE9/AI-text-to-image-photo-robot-with-computer.jpg" />
                </li>
                <li>
                  <h1>AI for Beginners</h1>
                </li>
                <li>
                  <p>
                    Recognized for providing accessible AI learning paths for
                    those new to the field, covering foundational AI concepts
                    and algorithms.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0N_NMfyOEUSwqQwafPbDC9mhvwcJkRsrJXA&s" />
                </li>
                <li>
                  <h1>Advanced AI Certification</h1>
                </li>
                <li>
                  <p>
                    Advanced certification for professionals seeking to deepen
                    their AI knowledge, with an emphasis on practical
                    applications and industry-relevant skills.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHg4oRCMzaOoUgpjUKbC2sIGkacweFpqUOJbqoISwkqBZK_A0V-R5aHmlmF4hdn5eCPMw&usqp=CAU" />
                </li>
                <li>
                  <h1>AI in Industry</h1>
                </li>
                <li>
                  <p>
                    Focus on how AI can revolutionize various industries, from
                    healthcare to finance, with real-world case studies and
                    insights.
                  </p>
                </li>
                <li>
                  <h2>View Disclaimer</h2>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Transform Your Career with AI Certifications</h1>
        </li>
        <li>
          <p>
            Unlock new career opportunities by gaining AI certifications through
            Acetians’ expert-led courses. Gain the skills and knowledge to
            thrive in the rapidly evolving AI industry.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Career Growth</h1>
                </li>
                <li>
                  <p>
                    Maximize your career potential with an AI certification that
                    provides you with the skills needed for top positions in the
                    AI industry.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Mastery</h1>
                </li>
                <li>
                  <p>
                    Achieve mastery in artificial intelligence with advanced
                    certifications that focus on machine learning, AI
                    algorithms, and neural networks.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI for Business Solutions</h1>
                </li>
                <li>
                  <p>
                    Gain the skills to integrate AI into business solutions,
                    enhancing decision-making processes and streamlining
                    operations.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Acetians AI Blogs</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Learn how Cisco harnesses AI-powered capabilities across our
                entire product and customer service portfolio. And find out why
                Cisco is leading the industry with innovation that enables AI
                infrastructure.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AICertificates;

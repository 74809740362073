import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./components/Contacts/ContactUs";
import Header from "./components/Header";
import Footer2 from "./components/Footer2";
import BusinessStrategyConsulting from "./components/ProductandServices/ConsultingService/BusinessStrategyConsulting";
import OnCampusTraining from "./components/Learn/Campus_Program/OnCampusTraining";
import ITInfrastructureCounsilting from "./components/ProductandServices/ConsultingService/ITInfrastructureCounsilting";
import MarketResearchService from "./components/ProductandServices/ConsultingService/MarketResearchService";
import RiskManagementCousulting from "./components/ProductandServices/ConsultingService/RiskManagementCousulting";
import FinancialPlanningCousulting from "./components/ProductandServices/ConsultingService/FinancialPlanningCousulting";
import CustomHardwareDevelopement from "./components/ProductandServices/HardwareSolution/CustomHardwareDevelopement";
import HardwareMaintenance from "./components/ProductandServices/HardwareSolution/HardwareMaintenance";
import NetworkDevices from "./components/ProductandServices/HardwareSolution/NetworkDevices";
import PreipheralDevices from "./components/ProductandServices/HardwareSolution/PreipheralDevices";
import ServerInstallations from "./components/ProductandServices/HardwareSolution/ServerInstallations";
import IncidentManagement from "./components/Support/OperationalsupportServices/IncidentManagement";
import ITAssetManagement from "./components/Support/OperationalsupportServices/ITAssetManagement";
import RemoteSupportServices from "./components/Support/OperationalsupportServices/RemoteSupportServices";
import SystemMonitoring from "./components/Support/OperationalsupportServices/SystemMonitoring";
import TechnicalSupport from "./components/Support/OperationalsupportServices/TechnicalSupport";
import CareerGuidanceWorkshops from "./components/Learn/Campus_Program/CareerGuidanceWorkshops";
import Hackathons from "./components/Learn/Campus_Program/Hackathons";
import InternshipProgram from "./components/Learn/Campus_Program/InternshipProgram";
import OnCampusTraining2 from "./components/Learn/Campus_Program/OnCampusTraining";
import SstudentBootcamps from "./components/Learn/Campus_Program/SstudentBootcamps";
import CertifiedITAourses from "./components/Learn/AcetiansITAcademy/CertifiedITAourses";
import IndustryExpertSessions from "./components/Learn/AcetiansITAcademy/IndustryExpertSessions";
import LiveProjectTraning from "./components/Learn/AcetiansITAcademy/LiveProjectTraning";
import OnlineLearningModules from "./components/Learn/AcetiansITAcademy/OnlineLearningModules";
import PlacementAssistance from "./components/Learn/AcetiansITAcademy/PlacementAssistance";

import CodingBootcamps from "./components/Learn/SkillDevelopementPrograms/CodingBootcamps";
import CommunicationsSkills from "./components/Learn/SkillDevelopementPrograms/CommunicationsSkills";
import LeadershipWorkshops from "./components/Learn/SkillDevelopementPrograms/LeadershipWorkshops";
import SoftSkillDevelopement from "./components/Learn/SkillDevelopementPrograms/SoftSkillDevelopement";
import TechnicalSkillsTraining from "./components/Learn/SkillDevelopementPrograms/TechnicalSkillsTraining";

import AICertificates from "./components/Learn/AIEducatorsPrograms/AICertificates";
import AICurriculumDesign from "./components/Learn/AIEducatorsPrograms/AICurriculumDesign";
import AIEthicsWorkshop from "./components/Learn/AIEducatorsPrograms/AIEthicsWorkshop";
import HandsOnAiProject from "./components/Learn/AIEducatorsPrograms/HandsOnAiProject";
import IntroducationsToAI from "./components/Learn/AIEducatorsPrograms/IntroducationsToAI";

import AdvancedProgramming from "./components/Learn/InstructorLedWorkshops/AdvancedProgramming";
import AgileMethology from "./components/Learn/InstructorLedWorkshops/AgileMethology";
import CloudComputiingBasics from "./components/Learn/InstructorLedWorkshops/CloudComputiingBasics";
import CybersecurityTraining from "./components/Learn/InstructorLedWorkshops/CybersecurityTraining";
import DataScienceBootcamps from "./components/Learn/InstructorLedWorkshops/DataScienceBootcamps";

import CloudCostOptimization from "./components/Solutions/CloudSolution/CloudCostOptimization";
import CloudDataStorage from "./components/Solutions/CloudSolution/CloudDataStorage";
import CloudMigration from "./components/Solutions/CloudSolution/CloudMigration";
import CloudSecurity from "./components/Solutions/CloudSolution/CloudSecurity";
import HybridCloudSetup from "./components/Solutions/CloudSolution/HybridCloudSetup";
import DataCenterSetup from "./components/Solutions/InfrastructureSolution/DataCenterSetup";
import DisasterRecovery from "./components/Solutions/InfrastructureSolution/DisasterRecovery";

import InfrastructureAutomation from "./components/Solutions/InfrastructureSolution/InfrastructureAutomation";
import NetworkDesgin from "./components/Solutions/InfrastructureSolution/NetworkDesgin";
import ScalableITSystems from "./components/Solutions/InfrastructureSolution/ScalableITSystems";
import CustomerJourneyMapping from "./components/Solutions/DigitalExperienceSolution/CustomerJourneyMapping";
import DigitalMarktingIntegration from "./components/Solutions/DigitalExperienceSolution/DigitalMarktingIntegration";
import MobileAppOptimization from "./components/Solutions/DigitalExperienceSolution/MobileAppOptimization";
import UserExperienceDesign from "./components/Solutions/DigitalExperienceSolution/UserExperienceDesign";
import WebApplicationdDevelopement from "./components/Solutions/DigitalExperienceSolution/WebApplicationdDevelopement";
import BusinessIntelligenceTools from "./components/Solutions/EnterpriseSolutions/BusinessIntelligenceTools";
import CustomerRelationshipManagement from "./components/Solutions/EnterpriseSolutions/CustomerRelationshipManagement";
import EnterpriseResourcePlanning from "./components/Solutions/EnterpriseSolutions/EnterpriseResourcePlanning";
import SupplyChainManagement from "./components/Solutions/EnterpriseSolutions/SupplyChainManagement";
import WorkforceManagementSystem from "./components/Solutions/EnterpriseSolutions/WorkforceManagementSystem";
import LoginModal from "./pages/LoginModle";
import Dataanalyticsconsultingservices from "./components/ProductandServices/DataAnnalytics.js/Dataanalyticsconsultingservices";
import PowerBI from "./components/ProductandServices/DataAnnalytics.js/PowerBI";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/contact-us"
          element={<ContactUs contact-us="contact-us" />}
        />
        {/* Product and Services */}
        /product-and-services
        <Route
          path="/product-and-services/consulting-services/business-strategy-consulting"
          element={<BusinessStrategyConsulting />}
        />
        <Route
          path="/product-and-services/consulting-services/it-infrastructure-consulting"
          element={<ITInfrastructureCounsilting />}
        />
        <Route
          path="/product-and-services/consulting-services/market-research-services"
          element={<MarketResearchService />}
        />
        <Route
          path="/product-and-services/consulting-services/risk-management"
          element={<RiskManagementCousulting />}
        />
        <Route
          path="/product-and-services/consulting-services/financial-planning"
          element={<FinancialPlanningCousulting />}
        />
        <Route
          path="/product-and-services/hardware-solution/custom-hardware-developement"
          element={<CustomHardwareDevelopement />}
        />
        <Route
          path="/product-and-services/hardware-solution/hardware-maintenance"
          element={<HardwareMaintenance />}
        />
        <Route
          path="/product-and-services/hardware-solution/network-devices"
          element={<NetworkDevices />}
        />
        <Route
          path="/product-and-services/hardware-solution/peripheral-devices"
          element={<PreipheralDevices />}
        />
        <Route
          path="/product-and-services/hardware-solution/server-installation"
          element={<ServerInstallations />}
        />
        <Route
          path="/product-and-services/more-services/data-analytics"
          element={<Dataanalyticsconsultingservices />}
        />
        <Route
          path="/product-and-services/more-services/power-bi"
          element={<PowerBI />}
        />
        {/* Support */}
        <Route
          path="/supports/operational-support/incident-management"
          element={<IncidentManagement />}
        />
        <Route
          path="/supports/operational-support/it-asset-management"
          element={<ITAssetManagement />}
        />
        <Route
          path="/supports/operational-support/remote-support-services"
          element={<RemoteSupportServices />}
        />
        <Route
          path="/supports/operational-support/system-monitoring"
          element={<SystemMonitoring />}
        />
        <Route
          path="/supports/operational-support/24*7-technical-support"
          element={<TechnicalSupport />}
        />
        {/* Learn */}
        <Route
          path="/learn/campus-programs/career-guidance-workshops"
          element={<CareerGuidanceWorkshops />}
        />
        <Route
          path="/learn/campus-programs/hackthons"
          element={<Hackathons />}
        />
        <Route
          path="/learn/campus-programs/internship-programs"
          element={<InternshipProgram />}
        />
        <Route
          path="/learn/campus-programs/oncampus-training"
          element={<OnCampusTraining2 />}
        />
        <Route
          path="/learn/campus-programs/student-bootcamps"
          element={<SstudentBootcamps />}
        />
        <Route
          path="/learn/acetians-it-academy/certified-it-courses"
          element={<CertifiedITAourses />}
        />
        <Route
          path="/learn/acetians-it-academy/industry-expert-sessions"
          element={<IndustryExpertSessions />}
        />
        <Route
          path="/learn/acetians-it-academy/live-project-traning"
          element={<LiveProjectTraning />}
        />
        <Route
          path="/learn/acetians-it-academy/online-learning-modules"
          element={<OnlineLearningModules />}
        />
        <Route
          path="/learn/acetians-it-academy/placement-assistance"
          element={<PlacementAssistance />}
        />
        <Route
          path="/learn/skill-development/technical-skills-training"
          element={<TechnicalSkillsTraining />}
        />
        <Route
          path="/learn/skill-development/soft-skills-developement"
          element={<SoftSkillDevelopement />}
        />
        <Route
          path="/learn/skill-development/coading-bootcamps"
          element={<CodingBootcamps />}
        />
        <Route
          path="/learn/skill-development/leadership-workshops"
          element={<LeadershipWorkshops />}
        />
        <Route
          path="/learn/skill-development/communication-skills"
          element={<CommunicationsSkills />}
        />
        <Route
          path="/learn/ai-educator-programs/introduction-to-ai"
          element={<IntroducationsToAI />}
        />
        <Route
          path="/learn/ai-educator-programs/ai-curriculum-design"
          element={<AICurriculumDesign />}
        />
        <Route
          path="/learn/ai-educator-programs/ai-ethics-workshop"
          element={<AIEthicsWorkshop />}
        />
        <Route
          path="/learn/ai-educator-programs/hands-on-ai-projects"
          element={<HandsOnAiProject />}
        />
        <Route
          path="/learn/ai-educator-programs/ai-certification"
          element={<AICertificates />}
        />
        <Route
          path="/learn/workshops/data-science-bootcamps"
          element={<DataScienceBootcamps />}
        />
        <Route
          path="/learn/workshops/cyber-security-training"
          element={<CybersecurityTraining />}
        />
        <Route
          path="/learn/workshops/cloud-computing-basics"
          element={<CloudComputiingBasics />}
        />
        <Route
          path="/learn/workshops/advanced-programming"
          element={<AdvancedProgramming />}
        />
        <Route
          path="/learn/workshops/agile-methodology"
          element={<AgileMethology />}
        />
        {/* Solutions */}
        <Route
          path="/solutions/cloud-solution/cloud-migration"
          element={<CloudMigration />}
        />
        <Route
          path="/solutions/cloud-solution/cloud-security"
          element={<CloudSecurity />}
        />
        <Route
          path="/solutions/cloud-solution/hybrid-cloud-setup"
          element={<HybridCloudSetup />}
        />
        <Route
          path="/solutions/cloud-solution/cloud-cost-optimization"
          element={<CloudCostOptimization />}
        />
        <Route
          path="/solutions/cloud-solution/cloud-data-storage"
          element={<CloudDataStorage />}
        />
        <Route
          path="/solutions/infrastructure-solution/data-center-setup"
          element={<DataCenterSetup />}
        />
        <Route
          path="/solutions/infrastructure-solution/network-design"
          element={<NetworkDesgin />}
        />
        <Route
          path="/solutions/infrastructure-solution/disaster-recovery"
          element={<DisasterRecovery />}
        />
        <Route
          path="/solutions/infrastructure-solution/infrastrusture-automation"
          element={<InfrastructureAutomation />}
        />
        <Route
          path="/solutions/infrastructure-solution/scalable-it-system"
          element={<ScalableITSystems />}
        />
        <Route
          path="/solutions/digital-solution/user-experience-design"
          element={<UserExperienceDesign />}
        />
        <Route
          path="/solutions/digital-solution/customer-journey-mapping"
          element={<CustomerJourneyMapping />}
        />
        <Route
          path="/solutions/digital-solution/digital-marketing-integration"
          element={<DigitalMarktingIntegration />}
        />
        <Route
          path="/solutions/digital-solution/web-application-developement"
          element={<WebApplicationdDevelopement />}
        />
        <Route
          path="/solutions/digital-solution/mobile-app-optimization"
          element={<MobileAppOptimization />}
        />
        <Route
          path="/solutions/enterprise-solutions/enterprise-resource-planning"
          element={<EnterpriseResourcePlanning />}
        />
        <Route
          path="/solutions/enterprise-solutions/customer-relationship-management"
          element={<CustomerRelationshipManagement />}
        />
        <Route
          path="/solutions/enterprise-solutions/business-intelligence-tools"
          element={<BusinessIntelligenceTools />}
        />
        <Route
          path="/solutions/enterprise-solutions/work-force-management-systems"
          element={<WorkforceManagementSystem />}
        />
        <Route
          path="/solutions/enterprise-solutions/supply-chain-management"
          element={<SupplyChainManagement />}
        />
        <Route path="/loginmodle" element={<LoginModal />} />
      </Routes>
      <Footer2 />
    </BrowserRouter>
  );
}

export default App;

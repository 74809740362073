import React from 'react';

const ProductAndServicehome = () => {
  const productsAndServices = [
    {
      imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_tlLPKWkv1gkZ6ta-nM4--ESmdpJgwiDYWg&s',
      headText: '24/7 Technical Support',
      textInfo: '24/7 Technical Support ensures continuous assistance for customers anytime, addressing issues promptly with expert solutions. It enhances reliability, minimizes downtime, and provides peace of mind with round-the-clock availability for seamless operations.',
     
    },
    {
      imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL1IT8JctPqokZlAVt4mfBZB1WReMI8jK22g&s',
      headText: 'System Monitoring',
      textInfo: 'System monitoring involves continuously tracking and analyzing the performance, health, and behavior of IT infrastructure to ensure reliability, optimize resource usage, detect issues, and maintain operational efficiency.',
    
    },
    {
      imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQviyO8kkPcugQakcN-YHUp6o8HUrZC8ewdiw&s',
      headText: 'Remote Support Services',
      textInfo: 'Remote support services provide assistance, troubleshooting, and maintenance for systems or equipment from a distance, ensuring rapid resolution and minimizing downtime, leveraging technology like internet, phone, or video conferencing.',
   
    },
  ];

  return (
    <div className='main-page-content'>
      {productsAndServices.map((item, index) => (
        <div key={index}>
          <div>
            <img src={item.imgSrc} className='content-img' alt="product-service" />
          </div>
          <div>
            <p className='head-text'>{item.headText}</p>
            <p className='text-info'>{item.textInfo}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductAndServicehome;

import React from "react";

const ProductAndServicehome = () => {
  const productsAndServices = [

    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxrD82ESKqgjTXakpVTfvZ2FWpJxldLKiY-Q&s",
      headText: "Cloud Solution",
      textInfo:
        "Cloud solutions provide scalable, on-demand access to computing resources, storage, and services over the internet. They enable businesses to reduce costs, enhance flexibility, and improve collaboration through efficient resource management.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLmXDfgzx3cUQW2LdEXVSvcWwuLg95dDG2CA&s",
      headText: "Infrastructure Solution",
      textInfo:
        "Infrastructure solutions encompass the design, implementation, and management of IT systems, networks, and hardware to ensure seamless operations, scalability, and efficiency for businesses, supporting digital transformation and innovation.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrGPX6M8RnK5-liVryF6bZBYHIAhwA5Rm6WQ&s",
      headText: "Digital Solution",
      textInfo:
        "A digital solution integrates technology to streamline processes, improve efficiency, and enhance user experiences through innovative tools, software, and services tailored to meet specific organizational or individual needs effectively.",
    },
    {
      imgSrc:
        "https://synxis.org/assets/images/enterprise.png",
      headText: "Enterprise Solutions",
      textInfo: "Enterprise solutions provide comprehensive software and services tailored to businesses, enabling streamlined operations, enhanced productivity, data-driven decision-making, and seamless integration across departments to achieve organizational goals efficiently.",
    },
  ];

  return (
    <div className="main-page-content">
      {productsAndServices.map((item, index) => (
        <div key={index}>
          <div>
            <img
              src={item.imgSrc}
              className="content-img"
              alt="product-service"
            />
          </div>
          <div>
            <p className="head-text">{item.headText}</p>
            <p className="text-info">{item.textInfo}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductAndServicehome;

import React from 'react'

const ModalCross = () => {
  return (
    <div>
        <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.34863 9.34836L12.0003 12M12.0003 12L14.6519 14.6517M12.0003 12L14.6519 9.34836M12.0003 12L9.34864 14.6517" stroke="#3A52EE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
    </div>
  )
}

export default ModalCross
import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./ConsultingService/MarketResearchServices.png";

const ITInfrastructureCounsilting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div className="WhyBharat" style={{ backgroundImage: `url(${Img})` }}>
        <ul>
          <li>
            <h1> Market Research Services</h1>
            <h2>
              Unlock the power of data-driven insights to guide your business
              decisions.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-13" id="globalpre">
        <li>
          <h1>Explore our comprehensive market research solutions</h1>
        </li>
        <li>
          <p>
            Learn more about our market research services, offering in-depth
            analysis and strategic insights to help you understand your
            industry, customer behavior, and competitor landscape.
          </p>
        </li>
        <li>
          <button>Explore services</button>
        </li>
      </ul>

      <ul className="business-component-12">
        <li>
          <img src="https://media.licdn.com/dms/image/v2/D4E12AQG1IGVvv2lmLQ/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1702627480848?e=2147483647&v=beta&t=icPDDgAIXijwojUbfK_7cE7FOuq-pFYTfl6cn5cdvoQ" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Making data actionable for strategic growth</h1>
            </li>
            <li>
              <p>
                Learn how market research insights can provide the foundation
                for your strategic decisions, enabling you to navigate market
                trends and identify growth opportunities.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-9">
        <li>
          <h1>Utilize customer insights to drive innovation</h1>
        </li>
        <li>
          <p>
            Our research services enable you to tap into customer preferences
            and behaviors, helping you innovate and adapt your products or
            services to meet market demands.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Customer Behavior Analysis</h1>
                </li>
                <li>
                  <p>
                    Gain a deep understanding of your target market's behavior,
                    motivations, and buying patterns.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Competitive Benchmarking</h1>
                </li>
                <li>
                  <p>
                    Stay ahead of competitors by analyzing market share,
                    positioning, and competitive trends.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Market Segmentation</h1>
                </li>
                <li>
                  <p>
                    Identify key customer segments to tailor your marketing
                    efforts and product offerings more effectively.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Industry Trends Analysis</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends and shifts within your
                    industry to help guide your strategic planning.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Forecasting and Trend Mapping</h1>
                </li>
                <li>
                  <p>
                    Utilize predictive analytics to forecast market trends and
                    anticipate shifts in customer behavior.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Discover the best market research tools</h1>
            </li>
            <li>
              <h2>
                Explore advanced market research tools designed to help you
                collect, analyze, and leverage data to make smarter business
                decisions.
              </h2>
            </li>
            <li>
              <button>See all tools</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Transform your business with customer-centric research</h1>
            </li>
            <li>
              <p>
                Empower your team with research-backed insights into customer
                needs, preferences, and expectations, enabling you to drive
                business outcomes with precision.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default ITInfrastructureCounsilting;

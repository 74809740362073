import React from "react";
import { Link } from "react-router-dom";
import Img from "./EnterpricesSolimg/Supplychain.png";
import { useEffect } from "react";

const PeripheralDevices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Supply Chain Solutions</h1>
            <h2>
              Your trusted partner for seamless and efficient supply chain
              management.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Acetians Supply Chain Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies provides advanced supply chain solutions
                designed for scalability, visibility, and efficiency, empowering
                businesses to optimize their operations.
              </h2>
            </li>
            <li>
              <button>See All Products</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://www.cisco.com/c/en_in/products/software/index/jcr:content/Grid/category_atl/layout-category-atl/spotlight_677629952_.img.jpg/1693300210307.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Real-Time Supply Chain Visibility</h1>
            </li>
            <li>
              <p>
                Acetians' solutions offer real-time insights into your entire
                supply chain, helping businesses track goods and manage
                inventory more efficiently.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Inventory Management Solutions</h1>
                    </li>
                    <li>
                      <p>
                        With advanced inventory tracking, Acetians ensures you
                        can effectively manage stock levels, reducing waste and
                        preventing stockouts.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Order Processing & Fulfillment</h1>
                    </li>
                    <li>
                      <p>
                        Streamline your order processing and fulfillment
                        operations to ensure on-time delivery and improve
                        customer satisfaction.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Supply Chain Automation</h1>
            </li>
            <li>
              <p>
                Leverage Acetians' supply chain automation tools to reduce
                manual tasks, minimize errors, and accelerate decision-making
                across your logistics operations.
              </p>
            </li>
            <li>
              <button>Explore Automation Solutions</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/contentReuse/en_in/dm/small-business/sb-solutions-spotlight-xl/jcr:content/Grid/category_atl_copy/layout-category-atl/spotlight_copy.img.jpg/1632720254172.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians Supply Chain Solutions Trusted Worldwide</h1>
            </li>
            <li>
              <h2>
                From small businesses to large enterprises, Acetians' supply
                chain solutions help businesses across the globe optimize their
                operations and reduce costs.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Supply Chain Optimization</h1>
            </li>
            <li>
              <p>
                Acetians Technologies helps businesses optimize their supply
                chain, reducing costs, improving delivery times, and maximizing
                operational efficiency.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default PeripheralDevices;

import React from "react";
import Img from "./EnterpricesSolimg/CRM.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const CustomerRelationshipManagement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Transform Your Business with Acetians CRM Solutions</h1>
            <h2>
              Empowering your team to build strong customer relationships with
              powerful CRM tools.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Acetians' CRM Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians provides customizable CRM solutions tailored to meet
                the unique needs of your business, helping you manage customer
                interactions, improve communication, and foster long-term
                relationships.
              </h2>
            </li>
            <li>
              <button>See All Services</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Maximize Customer Satisfaction with Acetians CRM</h1>
            </li>
            <li>
              <p>
                Acetians Technologies offers comprehensive CRM solutions
                designed to help businesses streamline their customer engagement
                processes, enhance communication, and improve customer
                satisfaction.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Request a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Us for CRM Solutions</h1>
            </li>
            <li>
              <p>
                Contact our team to learn how Acetians Technologies can help you
                build and maintain strong customer relationships with the right
                CRM tools for your business.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/spotlight.img.jpg/1693555465493.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians: Trusted by Leading Businesses</h1>
            </li>
            <li>
              <h2>
                With Acetians CRM solutions, leading businesses are enhancing
                their customer interactions, increasing sales, and fostering
                loyalty.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CustomerRelationshipManagement;

import React from 'react'


function StoryCard() {
  return (
    <div className='story__card_1'>
        <ul>
            <li>
            <img src={"https://media.licdn.com/dms/image/v2/D4D12AQHw70i5eS0iUg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1683650018098?e=2147483647&v=beta&t=b1Dd6l2199HWi1EAOlJQ8vcRjR8cPnpzT2V4aWtr7oc"} className='Story_card_img' />
            </li>
            <li>
                <div className='blog_content'>
                <h2>Security Solution</h2>
                <h3>
                A security solution is a system or tool designed to protect against threats, such as cyberattacks or physical harm. It can include technologies like firewalls, encryption, surveillance systems, and access control to ensure safety and privacy.</h3>   
                <a href=''>Explore more</a>
                </div>
            </li>
        </ul>
    </div>
  )
}

export default StoryCard
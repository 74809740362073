import React from "react";
import { Link } from "react-router-dom";
import Img from "./DigitalExeImg/UserExperienceDesign.png";
import { useEffect } from "react";

const UserExperienceDesign = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>User Experience Design</h1>
            <h2>
              we design user experiences to boost usability and engagement.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Optimize Your Digital Products with Exceptional UX Design</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive UX Research</h3>
                </li>
                <li>
                  <h2>Deep insights into user behaviors and needs</h2>
                </li>
                <li>
                  <p>
                    Our UX research services help you understand your users
                    better, ensuring that your digital products meet their needs
                    and expectations through effective design.
                  </p>
                </li>
                <li>
                  <h1>Explore Our UX Research Services</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>UI/UX Design Services</h3>
                </li>
                <li>
                  <h2>Design intuitive interfaces for better engagement</h2>
                </li>
                <li>
                  <p>
                    Our team crafts visually appealing and functional
                    interfaces, ensuring that your users enjoy seamless
                    interactions with your digital platforms.
                  </p>
                </li>
                <li>
                  <h1>Learn More About Our UI/UX Design</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Acetians: Delivering Outstanding User Experiences</h1>
            </li>
            <li>
              <p>
                We are committed to delivering user-centric design solutions
                that engage customers, improve usability, and create memorable
                experiences across all touchpoints.
              </p>
            </li>
            <li>
              <button>View Our Portfolio</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://miro.medium.com/v2/resize:fit:1200/1*1Bw8tqzEYn6LzmQInimbFA.png" />
        </li>
      </ul>

      <ul className="compo-6">
        <li>
          <h1>Design for Accessibility and Inclusivity</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://cdn.botpenguin.com/assets/website/User_Experience_ee9685eebc.png" />
                </li>
                <li>
                  <h1>User-Centered Design Principles</h1>
                </li>
                <li>
                  <p>
                    Our design approach is rooted in user-centered principles,
                    focusing on accessibility and inclusivity to create
                    experiences that are both functional and meaningful for
                    diverse audiences.
                  </p>
                </li>
                <li>
                  <h2>Learn More About Our Design Philosophy</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1ixzpnfIC3XjAqFSvCGY_9PUEzgYxHhmMvo70Smgcp4omLCfOSrbOE_pr01jbTxe64_E&usqp=CAU" />
                </li>
                <li>
                  <h1>Tailored Solutions for Every Industry</h1>
                </li>
                <li>
                  <p>
                    Whether it's e-commerce, healthcare, or education, we design
                    user experiences that align with your industry’s needs,
                    ensuring maximum engagement and retention.
                  </p>
                </li>
                <li>
                  <h2>Explore Industry-Specific UX Designs</h2>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Transform Your Business with User Experience Design</h1>
            </li>
            <li>
              <h2>
                Leverage Acetians Technologies' expertise to optimize your
                digital products with user-centric designs that drive customer
                satisfaction and business success.
              </h2>
            </li>
            <li>
              <button>Start Your Design Journey</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default UserExperienceDesign;

import React from "react";
import Img from "../images/contact-banner.jpg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ContactUs = () => {
  const submitbutton = () => {
    alert("Request Send");
  };
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  const countryNames = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Congo (Democratic Republic)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. Swaziland)",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Contact Us</h1>
          </li>
        </ul>
      </div>

      <div className="Contact-Body" id="globalpre">
        <div className="Contact-top">
          <h2>Contact Acetians</h2>
          <p>
            To get global contact information, please make your selections in
            the drop-down menus.
          </p>
        </div>

        <div className="Contact-Main">
          <div className="Contact-bodys">
            <div className="contact-container">
              <div className="contact-section"></div>

              <div className="contact-section">
                <h1>Acetians partners</h1>
                <p>
                  Become a partner, locate a partner, get updates, and partner
                  support.
                </p>
                <Link to="#" className="Link-button-2">
                  Explore Acetians partners &gt;
                </Link>
              </div>

              <div className="contact-section">
                <h1>Acetians offices</h1>
              </div>

              <div className="contact-section-2">
                <h4>
                  <strong>Head Office</strong>
                </h4>

                <p>
                  4th Floor Sishan House, 119, Shahpur Jat, New Delhi-110049.
                </p>
              </div>

              <div className="contact-section-2">
                <h5>Kanpur office</h5>

                <p>
                  120/611, Central Excise Colony, Lajpat Nagar, Shastri Nagar,
                  Kanpur, Uttar Pradesh 208005.
                </p>
              </div>
              <div className="contact-section-2">
                <h4>
                  <strong>Patna Office</strong>
                </h4>

                <p>
                  5th Floor, 501, Kaushalya Estate, Bandar Bagicha, Dark Bunglow
                  Chowk, Patna -- 800001 (Bihar).
                </p>
              </div>
            </div>
            {/* <div className="contact-img">
              <img src="https://alpasbox.com/wp-content/uploads/2019/02/contact-lady.png"/>
            </div> */}
          </div>

          <div className="Contact-bodys">
            <div className="request-form-container">
              <h1> Request for a Call </h1>

              <form className="request-form">
                <div className="Box-1">
                  <div className="name-box">
                    <div className="name-wrapper">
                      <label className="name-label">First name *</label>
                      <input
                        type="text"
                        id="lastName"
                        className="name-input"
                        name="lastName"
                        required
                      />
                    </div>

                    <div className="name-wrapper">
                      <label className="name-label">Last name *</label>
                      <input
                        type="text"
                        id="lastName"
                        className="name-input"
                        name="lastName"
                        required
                      />
                    </div>
                  </div>

                  <div className="name-wrapper">
                    <label htmlFor="email" className="name-label">
                      Email address *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="Email-input"
                      required
                    />
                  </div>

                  <div className="name-wrapper">
                    <label htmlFor="country" className="name-label">
                      Country *
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="country-input"
                      required
                    >
                      <option className="option-country" value="">
                        Please select
                      </option>
                      {countryNames.map((country, index) => (
                        <option
                          key={index}
                          className="option-country"
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="name-wrapper">
                    <label htmlFor="phoneNumber" className="name-label">
                      Phone number *
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="phone-input"
                      required
                    />
                  </div>

                  <div className="name-wrapper">
                    <label htmlFor="country" className="name-label">
                      Company size *
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="country-input"
                      required
                    >
                      <option value="">Please Select</option>
                      <option value="">1 - 5</option>
                      <option value="">5 - 20</option>
                      <option value="">20 - 100</option>
                      <option value="">100 - Above</option>
                    </select>
                  </div>

                  <div className="name-wrapper">
                    <label htmlFor="phoneNumber" className="name-label">
                      Comments
                    </label>
                    <textarea
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="phone-input"
                      required
                    ></textarea>
                  </div>
                </div>

                <label className="checkbox-contact">
                  <div>
                    <input type="checkbox" name="emailOffers" />
                  </div>
                  <div>
                    I would like Acetians to email me updates on offers,
                    promotions, and the latest news about their products and
                    services.
                  </div>
                </label>

                <button
                  onClick={submitbutton}
                  type="submit"
                  className="submit-button"
                >
                  Request a call
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./CampusProgramImg/OnCampusTraining.png";

const OnCampusTraining = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> On-Campus Training Solutions</h1>
            <h2>
              {" "}
              we offer on-campus training in the latest tech for career success.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Boost Your Career with Industry-Relevant Skills</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Technical Training Programs</h3>
                </li>
                <li>
                  <h2>Gain Hands-on Experience with Real-World Applications</h2>
                </li>
                <li>
                  <p>
                    Our technical training programs provide students with
                    practical, hands-on experience in key areas like software
                    development, data analytics, networking, and cloud
                    computing, ensuring that they are well-prepared for industry
                    challenges.
                  </p>
                </li>
                <li>
                  <h1>Explore Our Technical Training Courses</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Soft Skills Development</h3>
                </li>
                <li>
                  <h2>
                    Enhance Communication, Leadership, and Problem-Solving
                    Skills
                  </h2>
                </li>
                <li>
                  <p>
                    Acetians Technologies focuses on holistic development,
                    offering training to improve soft skills such as teamwork,
                    leadership, and communication, which are critical for
                    professional growth.
                  </p>
                </li>
                <li>
                  <h1>Learn More About Soft Skills Training</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Acetians Technologies: Bridging the Gap Between Education and
                Industry
              </h1>
            </li>
            <li>
              <p>
                We at Acetians Technologies are dedicated to bridging the gap
                between academic learning and real-world industry demands. Our
                training programs are designed to empower students with the
                tools and knowledge they need to become leaders in their fields.
              </p>
            </li>
            <li>
              <button>Read More About Our Training Programs</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/content/dam/cisco-cdc/site/images/backgrounds/solutions/iot/forrester-security-report-1920x1080.jpg" />
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global Opportunities for Students</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies provides students with the opportunity to
                gain global exposure through our on-campus training initiatives.
                Our global network ensures that students are prepared for
                international career prospects.
              </h2>
            </li>
            <li>
              <button>Explore Our Global Training Opportunities</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Developing the Future Workforce</h1>
        </li>
        <li>
          <p>
            Our training solutions focus on the evolving needs of industries
            worldwide, ensuring that students are equipped with the skills
            needed for the jobs of tomorrow. Acetians Technologies helps
            students stay ahead in an increasingly competitive job market.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Enhance Your Programming Skills</h1>
                </li>
                <li>
                  <p>
                    Learn the fundamentals and advanced concepts of software
                    development with our coding bootcamps and workshops.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Learn Cloud Computing</h1>
                </li>
                <li>
                  <p>
                    Gain hands-on experience in cloud technologies like AWS,
                    Azure, and Google Cloud, and build cloud-based applications.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Network & Cybersecurity Essentials</h1>
                </li>
                <li>
                  <p>
                    Understand the basics of networking and cybersecurity, and
                    learn how to protect sensitive data in the digital age.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default OnCampusTraining;

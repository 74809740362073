import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./CampusProgramImg/StudentBootcamp.png";

const StudentBootcamps = () => {
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Student Bootcamp Programs</h1>
            <h2>
              {" "}
              Our Bootcamp programs provide job-ready skills for today’s digital
              workforce.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Launch Your Career with Acetians Bootcamps</h1>
            </li>
            <li>
              <h2>
                Take advantage of our bootcamp programs to acquire
                industry-recognized certifications, build a strong portfolio,
                and connect with top tech companies looking for talented
                graduates.
              </h2>
            </li>
            <li>
              <button>Get Started Today</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>Kickstart Your Career in Tech</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive Bootcamp Programs</h3>
                </li>
                <li>
                  <h2>Intensive training for in-demand skills</h2>
                </li>
                <li>
                  <p>
                    Our bootcamps offer hands-on training in software
                    development, data science, cloud computing, and more,
                    preparing you for the most competitive roles in tech.
                  </p>
                </li>
                <li>
                  <h1>Explore Our Bootcamp Programs</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Real-World Projects</h3>
                </li>
                <li>
                  <h2>Apply your skills to solve real problems</h2>
                </li>
                <li>
                  <p>
                    Gain practical experience by working on live projects that
                    will enhance your portfolio and prepare you for real-world
                    challenges.
                  </p>
                </li>
                <li>
                  <h1>Start Working on Projects Today</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Join Our Global Network of Learners</h1>
            </li>
            <li>
              <p>
                As a part of Acetians Bootcamps, you’ll become part of a global
                community, gaining access to exclusive networking opportunities,
                career support, and industry events.
              </p>
            </li>
            <li>
              <button>Apply Now</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://c0.wallpaperflare.com/preview/504/693/68/work-editor-text-learn.jpg" />
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://t3.ftcdn.net/jpg/06/04/21/56/360_F_604215694_olsR4NK3knYzemsY87AQRv8S5qcBHy0n.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>24/7 Career Support from Acetians Technologies</h1>
            </li>
            <li>
              <p>
                Our career support team is here for you every step of the way,
                offering resume building, interview preparation, and job
                placement assistance to ensure you succeed in the tech industry.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Personalized Career Coaching</h1>
                    </li>
                    <li>
                      <p>
                        Get one-on-one career coaching sessions to tailor your
                        job search strategy and enhance your career prospects.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Interview Preparation</h1>
                    </li>
                    <li>
                      <p>
                        Our team helps you prepare for interviews with mock
                        sessions, feedback, and tips to ensure you’re ready to
                        impress top employers.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Job Placement Assistance</h1>
                    </li>
                    <li>
                      <p>
                        We have strong connections with leading tech companies
                        and offer job placement assistance to help you secure
                        your first role in the industry.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile and Adaptable Learning Paths</h1>
            </li>
            <li>
              <p>
                Acetians Bootcamps offer flexible learning paths tailored to
                your goals, so you can learn at your own pace and develop the
                skills most relevant to your career ambitions.
              </p>
            </li>
            <li>
              <button>Learn More About Our Programs</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://e0.pxfuel.com/wallpapers/684/330/desktop-wallpaper-boot-camp-animated-word-cloud-text-design-animation-kinetic-typography-motion-background-videoblocks.jpg" />
        </li>
      </ul>
    </div>
  );
};

export default StudentBootcamps;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./DigitalExeImg/DigitalMarketing.png";
import { useEffect } from "react";

const DigitalMarktingIntegration = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Digital Marketing Integration</h1>
            <h2>
              We provide seamless marketing integration to align strategies and
              amplify results.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-2" id="globalpre">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR20Ewm1IIPFrEMQH0bDpFI4YQ5TAKgiorytads9L4EdX8EKrsTIbzSYy7kVJCbBGsEJxQ&usqp=CAU" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Connect with your peers in The Global Gateway</h1>
                </li>
                <li>
                  <p>
                    Join the Digital Marketing Integration community in The
                    Global Gateway and explore how businesses are leveraging
                    integrated digital marketing solutions to enhance customer
                    experience and drive business growth.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Join the community</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>2024 Digital Marketing Integration Insights Report</h1>
            </li>
            <li>
              <p>
                Learn how top organizations are integrating their marketing
                strategies to improve ROI, streamline campaigns, and achieve
                better customer targeting across multiple channels.
              </p>
            </li>
            <li>
              <button>Download the report</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>Supercharge Your Marketing with Integration Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Omnichannel Marketing Integration</h3>
                </li>
                <li>
                  <h2>Seamless Integration Across Multiple Channels</h2>
                </li>
                <li>
                  <p>
                    Our integrated marketing solutions enable you to run
                    synchronized campaigns across email, social media, search
                    engines, and more, ensuring a unified customer experience.
                  </p>
                </li>
                <li>
                  <h1>View Marketing Integration Solutions</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Data-Driven Marketing Insights</h3>
                </li>
                <li>
                  <h2>Enhance Marketing Efficiency with Analytics</h2>
                </li>
                <li>
                  <p>
                    Our solutions provide data-driven insights, enabling
                    businesses to track customer behavior and optimize campaigns
                    for better engagement and conversion rates.
                  </p>
                </li>
                <li>
                  <h1>View Data-Driven Solutions</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile Digital Marketing Strategies</h1>
            </li>
            <li>
              <p>
                Acetians Technologies implements agile strategies for digital
                marketing integration, allowing businesses to quickly adjust to
                market changes and customer behavior, ensuring effective
                marketing performance.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/contentReuse/en_in/dm/small-business/sb-solutions-spotlight-xl/jcr:content/Grid/category_atl_copy/layout-category-atl/spotlight_copy.img.jpg/1632720254172.jpg" />
        </li>
      </ul>
    </div>
  );
};

export default DigitalMarktingIntegration;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import img from "./ConsultingService/ITInfrastructureConsulting.png";
const ITInfrastructureCounsilting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div className="WhyBharat" style={{ backgroundImage: `url(${img})` }}>
        <ul>
          <li>
            <h1> IT Infrastructure Consulting</h1>
            <h2>
              Empowering businesses with cutting-edge IT infrastructure
              solutions.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      {/* <div
        className="main_carousel"
        style={{
            backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="business-console">
          <h1>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              Home
            </Link>
          </h1>
          <h2>&sdot; IT Infrastructure Consulting</h2>
        </div>
        <h1>IT Infrastructure Consulting</h1>
        <h2>Empowering businesses with cutting-edge IT infrastructure solutions.</h2>
        <button className="btn btn-explore">TALK TO AN EXPERT</button>
      </div> */}

      <ul className="business-component-3" id="globalpre">
        <li>
          <img src="https://www.veritis.com/wp-content/uploads/2019/06/it-infrastructure-services-1.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Maximize Data Utilization Across Your Network</h1>
            </li>
            <li>
              <p>
                Leverage the power of data from your network to drive business
                outcomes. With Cisco Spaces, we enable you to harness real-time
                data analytics that can lead to smarter decision-making,
                operational efficiency, and enhanced customer experiences.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Empower Your Workforce with Secure Hybrid Solutions</h1>
            </li>
            <li>
              <p>
                We help you build a hybrid work environment that ensures
                seamless collaboration and secure access to resources. Our IT
                consulting services focus on building a flexible, secure, and
                productive workplace, whether your team is working from the
                office or remotely.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-12">
        <li>
          <img src="https://labyrinthit.com/wp-content/uploads/2021/04/it-infrastructure.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Ensure Mission-Critical Operations with High Availability</h1>
            </li>
            <li>
              <p>
                We assist businesses in creating resilient IT infrastructure
                that supports critical operations. Our solutions include
                high-availability configurations and disaster recovery planning,
                minimizing downtime and ensuring your operations run smoothly.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Insights on Emerging IT Trends</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Stay ahead of the curve with expert insights on the latest IT
                trends and technologies. We provide guidance on emerging
                technologies such as artificial intelligence, machine learning,
                and cloud-native infrastructure that can revolutionize your IT
                strategy.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ITInfrastructureCounsilting;

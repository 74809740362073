import React from "react";
import Img from "./operationalSupportImg/ItAssestManagement.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ITAssetManagement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>IT Asset Management</h1>
            <h2>
              we offer IT asset management to simplify your assets and optimize
              resources.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Save on IT Asset Management Software</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians IT Asset Management Suite</h3>
                </li>
                <li>
                  <h2>One platform for managing all your IT assets</h2>
                </li>
                <li>
                  <p>
                    Get a complete, scalable solution for tracking, managing,
                    and optimizing your IT assets, ensuring seamless lifecycle
                    management from procurement to retirement.
                  </p>
                </li>
                <li>
                  <h1>View IT Asset Management Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians IT Asset Optimization Platform</h3>
                </li>
                <li>
                  <h2>Maximize the value of your IT assets</h2>
                </li>
                <li>
                  <p>
                    Our IT asset optimization tools help you identify
                    underutilized assets, reduce waste, and ensure that all
                    assets are fully aligned with your operational needs.
                  </p>
                </li>
                <li>
                  <h1>View IT Asset Optimization Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Cisco 2024 State of IT Asset Management Report</h1>
            </li>
            <li>
              <p>
                Explore insights from over 1000 professionals worldwide in the
                2024 report, shedding light on the evolving trends, challenges,
                and technologies in IT asset management.
              </p>
            </li>
            <li>
              <button>Download the report</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://infraon.io/blog/wp-content/uploads/2023/04/two-colleagues-working-late-office-blue-light-night-view1-min.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Strategic IT Asset Leadership Consulting</h1>
            </li>
            <li>
              <p>
                Acetians Technologies provides expert consulting in IT asset
                management strategy, helping businesses establish leadership
                practices for better asset governance and long-term
                sustainability.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile IT Asset Management Strategies</h1>
            </li>
            <li>
              <p>
                Acetians Technologies focuses on agile methodologies for IT
                asset management, allowing for greater flexibility and
                responsiveness to your asset needs. Our strategies ensure better
                cost control and asset utilization.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.microbyte.com/wp-content/uploads/2024/09/A-Guide-to-Responsible-IT-Asset-Disposition.jpg" />
        </li>
      </ul>
    </div>
  );
};

export default ITAssetManagement;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./HardwareSolutionImages/ServerInstallations.png";
import { useEffect } from "react";
const ServerInstallations = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Server Installation Services</h1>
            <h2>
              {" "}
              We provide expert server installation solutions for businesses of
              all sizes, ensuring your systems are reliable and secure.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://content.jdmagicbox.com/comp/def_content/server-installation-services/ctul1eoudg-server-installation-services-2-rrke1.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Server Installation</h1>
            </li>
            <li>
              <p>
                Our server installation services ensure your infrastructure is
                set up with reliability, scalability, and security in mind.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 1: Define Your Server Requirements</h1>
                    </li>
                    <li>
                      <p>
                        Before installation, we work with you to assess the
                        hardware and software requirements for your server,
                        based on your business needs.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 2: Choose the Right Hardware</h1>
                    </li>
                    <li>
                      <p>
                        Whether it's physical or virtual, selecting the correct
                        hardware is crucial for the long-term success of your
                        server infrastructure.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>
                        Step 3: Install and Configure the Operating System
                      </h1>
                    </li>
                    <li>
                      <p>
                        Installing the correct operating system is key. We help
                        you choose between Linux, Windows Server, or other
                        operating systems, ensuring it's configured for security
                        and performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 4: Network Configuration and Security</h1>
                    </li>
                    <li>
                      <p>
                        Setting up the network and security protocols is
                        essential to keep your server safe from external threats
                        while ensuring smooth connectivity.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 5: Install Server Software and Applications</h1>
                    </li>
                    <li>
                      <p>
                        We install the necessary software for your server,
                        whether it’s web hosting, databases, or other
                        applications specific to your business.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 6: Ongoing Maintenance and Monitoring</h1>
                    </li>
                    <li>
                      <p>
                        Our job doesn’t end with installation. We offer
                        continuous monitoring and regular updates to keep your
                        server in top shape.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Our Server Experts</h1>
            </li>
            <li>
              <p>
                Get in touch with one of our experts to discuss your server
                installation needs and how we can help you set up a robust
                infrastructure.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://5.imimg.com/data5/RH/NS/MY-26240794/server-support-services-500x500.jpg" />
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Ensure Your Server is Ready for Growth</h1>
            </li>
            <li>
              <p>
                Proper server installation ensures that your infrastructure can
                scale with your business. Let us set you up for future success.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Your Server Installation</button>
                </li>
                <li>
                  <button>Watch Our Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Optimize Your Server with Our Expertise</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Maximize Your Server's Efficiency</h1>
                </li>
                <li>
                  <p>
                    Work with us to fine-tune your server for optimal
                    performance, ensuring smooth and reliable operation at all
                    times.
                  </p>
                </li>
                <li>
                  <h1>Get Expert Advice</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Upgrade Your Server Infrastructure</h1>
                </li>
                <li>
                  <p>
                    Whether you're upgrading your hardware or transitioning to a
                    cloud environment, we provide the expertise to guide you
                    through the process.
                  </p>
                </li>
                <li>
                  <h1>Contact Our Team</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Acetians Server Insights</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Learn more about how to optimize your server infrastructure with
                the latest technologies and best practices.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ServerInstallations;

import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/Codingbootcamp.png";
import { useEffect } from "react";

const CodingBootcamps = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Coding Bootcamps</h1>
            <h2>
              {" "}
              Acquire In-Demand Tech Skills in a Short Time with Intensive
              Coding Programs.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-comopnent-6" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Successful Tech Career</h1>
            </li>
            <li>
              <h2>
                Our coding bootcamps are designed to help you master programming
                languages, web development, and other technical skills,
                preparing you for a successful career in the tech industry.
              </h2>
            </li>
            <li>
              <button>Why Join Our Bootcamps?</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://www.dice.com/binaries/content/gallery/dice/insights/2019/01/Bootcamp-MOOC-Learning-Tech-Coding-Programming-Dice-1024x640.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Comprehensive Coding Bootcamp Programs</h1>
            </li>
            <li>
              <p>
                Our coding bootcamps cover everything from basic programming
                concepts to advanced web development, data science, and software
                engineering. Learn real-world skills with hands-on projects.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Your Bootcamp Journey</button>
                </li>
                <li>
                  <button>Request More Information</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Our Coding Bootcamp Tracks</h1>
            </li>
            <li>
              <h2>
                We offer multiple bootcamp tracks, including full-stack
                development, front-end development, data science, and more.
                Choose the track that best suits your career goals and
                interests.
              </h2>
            </li>
            <li>
              <button>See All Bootcamps</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://kingslanduniversity.com/wp-content/uploads/2021/03/Coding-Bootcamp.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Our Coding Bootcamp Process</h1>
            </li>
            <li>
              <p>
                Our bootcamps are designed to take you from beginner to
                job-ready. From learning programming fundamentals to building
                complex applications, we provide the tools and mentorship you
                need to succeed.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Initial Assessment</h1>
                    </li>
                    <li>
                      <p>
                        We begin with an initial assessment to understand your
                        goals and current skill level. Our team will recommend
                        the best bootcamp track for you.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Interactive Learning</h1>
                    </li>
                    <li>
                      <p>
                        Participate in hands-on coding exercises, build
                        real-world projects, and collaborate with peers to
                        solidify your understanding of key concepts.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Capstone Project</h1>
                    </li>
                    <li>
                      <p>
                        Apply your skills in a final capstone project, where you
                        will build a fully functional app or website. This
                        project will be an impressive addition to your
                        portfolio.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Job Support & Networking</h1>
                    </li>
                    <li>
                      <p>
                        Our bootcamp doesn’t end with graduation. We provide
                        ongoing job support, including resume reviews, interview
                        coaching, and access to our network of hiring partners.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Coding Bootcamp Blogs</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Stay up-to-date with the latest trends in coding, tech careers,
                and bootcamp success stories through our blog posts. Learn more
                about the skills in demand and how to get started in tech.
              </p>
            </li>
            <li>
              <button>Read Our Blog</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CodingBootcamps;

import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "./InfraImages/infraautomation.png";

const InfrastructureAutomationConsulting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Infrastructure Automation Consulting</h1>
            <h2>
              {" "}
              Streamlining operations with intelligent infrastructure automation
              solutions.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-7" id="globalpre">
        <li>
          <h1>Design and Implement Automated Infrastructure Solutions</h1>
        </li>
        <li>
          <p>
            Our Infrastructure Automation Consulting services help you design
            and implement automated systems that increase efficiency and
            scalability. From automated provisioning to lifecycle management, we
            provide solutions that reduce manual errors and accelerate your IT
            processes.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Automate Infrastructure Provisioning</h1>
                </li>
                <li>
                  <p>
                    We streamline your infrastructure provisioning by automating
                    the setup and configuration of hardware and software
                    resources. This ensures consistent environments and faster
                    deployment times, enabling your team to focus on innovation.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Improve Operational Efficiency</h1>
                </li>
                <li>
                  <p>
                    Automate routine tasks, such as patch management and system
                    monitoring, to free up your team’s time. Our solutions allow
                    for smarter decision-making and more efficient IT
                    operations, reducing the complexity of day-to-day
                    management.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Enhance Infrastructure Scalability</h1>
                </li>
                <li>
                  <p>
                    With infrastructure automation, scaling your systems becomes
                    a seamless process. Automatically scale up or down based on
                    demand, ensuring that your infrastructure remains agile and
                    cost-effective as your business grows.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://inventive-it.com/wp-content/uploads/2021/05/cloud-infrastructure-automation-tools.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>
                Maximize Data Utilization Across Your Automated Infrastructure
              </h1>
            </li>
            <li>
              <p>
                Leverage the power of automated data analytics to gain
                actionable insights across your infrastructure. With real-time
                monitoring and reporting, you can optimize performance, predict
                potential failures, and improve decision-making processes.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Empower Your Workforce with Automated Solutions</h1>
            </li>
            <li>
              <p>
                Automate the manual and repetitive tasks within your IT
                infrastructure, allowing your team to focus on high-value work.
                We help you deploy solutions that increase productivity and
                reduce errors, creating a more agile workforce.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Insights on Emerging Trends in Infrastructure Automation</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Stay informed with expert insights on the latest trends in
                infrastructure automation. Learn how technologies like AI,
                machine learning, and advanced orchestration tools are
                transforming how businesses manage their IT environments.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default InfrastructureAutomationConsulting;

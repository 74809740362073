import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InstructureWorkshop/AlgiMethology.png";

const AgileMethology = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Agile Methodology</h1>
            <h2>
              {" "}
              We use Agile to drive flexibility, speed, and high-quality
              results.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our Agile Development solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://media.licdn.com/dms/image/v2/D4D12AQHcOWePHgtW0Q/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1679183893475?e=2147483647&v=beta&t=RtKSIwmA3ECMtdrTlCj2gBedgHIVM5qRqQVPFW8skUQ" />
                </li>
                <li>
                  <h1>Iterative Development & Continuous Improvement</h1>
                </li>
                <li>
                  <p>
                    We implement iterative development cycles that prioritize
                    continuous improvement and regular feedback loops, enabling
                    you to get the product you need in shorter timeframes.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://businessmap.io/wp-content/uploads/website-images/new-blog/what-is-agile-cross-functional-team.png" />
                </li>
                <li>
                  <h1>Cross-functional Collaboration</h1>
                </li>
                <li>
                  <p>
                    Our Agile teams work collaboratively across functions,
                    bringing together developers, designers, and business
                    experts to solve problems more effectively and deliver
                    high-quality solutions faster.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvghEtC7sAUFBEFTaZiKTRi_kC4gZDWG-VPw&s" />
                </li>
                <li>
                  <h1>Flexibility and Adaptability</h1>
                </li>
                <li>
                  <p>
                    Agile is all about adaptability. We help you stay responsive
                    to changing requirements, whether they come from the market,
                    customers, or your internal stakeholders.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiYCxxqSUMFcLLq-gqEpX4rlholQ0GEIsaxSx7_8Qg0HkqIWcBIajDNhgKWcv7VaDM774&usqp=CAU" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Agile Methodology Forum</h1>
                </li>
                <li>
                  <p>
                    Stay ahead of the curve in Agile practices by joining our
                    Agile Methodology Forum. Learn from industry experts,
                    network with professionals, and explore new ways to
                    implement Agile in your organization.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://static.vecteezy.com/system/resources/previews/002/314/272/non_2x/devops-engineers-web-design-with-people-vector.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data-Driven Agile Strategy Insights</h1>
            </li>
            <li>
              <p>
                Leverage data analytics to drive your Agile practices,
                uncovering insights that inform better decision-making and
                improve overall project success rates.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Get Started</button>
                </li>
                <li>
                  <button>Watch Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Deliver Superior Agile Experiences</h1>
        </li>
        <li>
          <p>
            Our Agile methodology accelerates your digital transformation,
            ensuring faster time-to-market and continuous delivery of
            customer-focused products.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize Iterations</h1>
                </li>
                <li>
                  <p>
                    Streamline your iterative processes, ensuring faster
                    delivery and continuous improvement.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Maximize Agile ROI</h1>
                </li>
                <li>
                  <p>
                    Leverage Agile frameworks to maximize ROI by optimizing
                    time, resources, and productivity across your business
                    processes.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Enhance Business Agility</h1>
                </li>
                <li>
                  <p>
                    Implement Agile strategies that enhance responsiveness to
                    market changes and improve long-term business
                    sustainability.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AgileMethology;

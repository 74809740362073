import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Dataanalyticsconsultingservices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${"https://img.lovepik.com/background/20211021/large/lovepik-data-analysis-background-image_500442691.jpg"})`,
        }}
      >
        <ul>
          <li>
            <h1>Data analytics consulting services.</h1>
            <h2>Turn your raw data into actionable insights</h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="dataanalytics-1" id="globalpre">
        <li>
          <img
            src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2024/06/data-analytics-intro.webp"
            alt=""
          />
        </li>
        <li>
          <h1>
            Leverage your data for a competitive edge with our analytics
            solutions
          </h1>
          <p>
            In an era dominated by data, overwhelming complexity remains a
            critical hurdle for modern enterprises. At Acetians Technologies,
            our experts harness the transformative power of AI-driven analytics
            to unravel this intricacy. We empower organizations to derive
            actionable intelligence, enhance operational efficiency, and secure
            a formidable competitive edge. Partner with us to unlock the
            untapped potential of your data and drive enduring success.
          </p>
        </li>
      </ul>

      <ul className="dattaanalytics-2">
        <li>
          <h1>Our data analytics consulting solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <h3>Data strategy</h3>
              <p>
                We craft a customized data strategy encompassing people,
                processes, and tech, enabling maximum data value. Our data
                consultants ensure that our exclusive methodology aligns with
                your business objectives for enduring success.
              </p>
            </li>
            <li>
              <h3>Analytics Insights</h3>
              <p>
                Our data analytics strategies provide actionable insights,
                helping businesses make data-driven decisions and improve
                performance.
              </p>
            </li>
            <li>
              <h3>Data Integration</h3>
              <p>
                We create data analytics strategies that integrate diverse
                sources, ensuring seamless analysis and better decision-making.
              </p>
            </li>
            <li>
              <h3>Real-Time Analytics</h3>
              <p>
                Our strategies leverage real-time data analytics to provide
                timely insights, improving efficiency and decision accuracy.
              </p>
            </li>
            <li>
              <h3>Data Storytelling</h3>
              <p>
                Our data analytics strategies help translate complex data into
                clear narratives, enhancing understanding and decision-making.
              </p>
            </li>
            <li>
              <h3>Data Performance Analytics</h3>
              <p>
                We design data analytics strategies that focus on tracking
                performance metrics and delivering actionable insights.
              </p>
            </li>
            <li>
              <h3>Data Automation</h3>
              <p>
                Our data analytics strategies streamline automation, reducing
                manual tasks and improving efficiency.
              </p>
            </li>
            <li>
              <h3>Scalable Analytics</h3>
              <p>
                We create scalable data analytics strategies that adapt to
                business growth, enabling better insights and decision-making.
              </p>
            </li>
            <li>
              <h3>Collaboration Analytics</h3>
              <p>
                Our data analytics strategies enhance collaboration, ensuring
                teams work together with shared insights for better outcomes.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="dataanalytics-3">
        <li>
          <img
            src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2024/05/data-assessment.webp"
            alt=""
          />
        </li>
        <li>
          <h4>Data assessment</h4>
          <h1>Take a data assessment to understand your data landscape</h1>
          <p>
            Identify opportunities and gaps in your current data landscape,
            ensure informed decision making and optimized data strategies for
            business growth.
          </p>
        </li>
      </ul>

      <ul className="dataanalytics-4">
        <li>
          <h1>Problems we solve with our data and analytics services</h1>
          <p>
            <span className="dataanalytics-4-bold">Data quality issues:</span>{" "}
            Our AI-driven data analytics services ensure accurate, consistent,
            and reliable data for better decision-making.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Data quality issues:</span>{" "}
            Our AI-driven data analytics services ensure accurate, consistent,
            and reliable data for better decision-making.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Data quality issues:</span>{" "}
            Our AI-driven data analytics services ensure accurate, consistent,
            and reliable data for better decision-making.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Data quality issues:</span>{" "}
            Our AI-driven data analytics services ensure accurate, consistent,
            and reliable data for better decision-making.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Data quality issues:</span>{" "}
            Our AI-driven data analytics services ensure accurate, consistent,
            and reliable data for better decision-making.
          </p>
        </li>

        <li>
          <img
            src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2023/07/common-data.webp"
            alt=""
          />
        </li>
      </ul>

      <ul className="dataanalytics-5">
        <li>
          <ul>
            <li>
              <img
                src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2023/05/healthcare-dashboard.webp"
                alt=""
              />
            </li>
            <li>
              <h2>Healthcare</h2>
              <ul>
                <li>
                  Anticipates patient outcomes by analyzing historical data,
                  aiding early intervention and improving treatment success
                  rates.
                </li>
                <li>
                  Customizes treatments by analyzing genetic, lifestyle, and
                  medical data, ensuring targeted and effective care.
                </li>
                <li>
                  Tracks and analyzes health data to predict, monitor, and
                  control disease outbreaks, safeguarding public health.
                </li>
                <li>
                  Optimizes resource use, staff scheduling, and workflows in
                  healthcare facilities, reducing costs and enhancing patient
                  care.
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <img
                src="https://dce0qyjkutl4h.cloudfront.net/wp-content/webp-express/webp-images/uploads/2022/05/oil-gas-dashboard.jpg.webp"
                alt=""
              />
            </li>
            <li>
              <h2>Oil & gas</h2>
              <ul>
                <li>
                  Analyzes seismic data to identify potential oil reserves,
                  reducing exploration costs and improving success rates.
                </li>
                <li>
                  Monitors equipment performance to predict failures, minimizing
                  downtime and enhancing operational efficiency.
                </li>
                <li>
                  Streamlines logistics and inventory management, ensuring
                  timely delivery and cost reduction.
                </li>
                <li>
                  Identifies safety hazards and market risks through data
                  analysis, supporting proactive decision-making and compliance.
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <img
                src="https://dce0qyjkutl4h.cloudfront.net/wp-content/webp-express/webp-images/uploads/2022/05/energy-dashboard.jpg.webp"
                alt=""
              />
            </li>
            <li>
              <h2>Energy</h2>
              <ul>
                <li>
                  Predicts energy needs using consumption patterns, enabling
                  efficient grid management and reducing energy waste.
                </li>
                <li>
                  Identifies inefficiencies in energy use, guiding strategies to
                  reduce costs and carbon footprints.
                </li>
                <li>
                  Predicts failures in energy infrastructure through monitoring
                  and analytics, ensuring uninterrupted power supply.
                </li>
                <li>
                  Analyzes energy pricing and market trends to inform
                  competitive strategies and investment decisions.
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <img
                src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2023/01/retail-analysis-report-1.webp"
                alt=""
              />
            </li>
            <li>
              <h2>Retail</h2>
              <ul>
                <li>
                  Predicts product demand, optimizing inventory and reducing
                  overstock or shortages.
                </li>
                <li>
                  Uses market trends to determine competitive pricing and
                  maximize profits.
                </li>
                <li>
                  Identifies suspicious transactions, reducing fraud risks and
                  ensuring secure operations.
                </li>
                <li>
                  Tracks foot traffic and sales patterns to enhance layouts and
                  boost revenue.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Dataanalyticsconsultingservices;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "../LEARNIMAGES/liveproject.png";

const LiveProjectTraning = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Live Project Training</h1>
            <h2>
              {" "}
              Enhance your skills with hands-on experience in real-world
              projects.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-7" id="globalpre">
        <li>
          <h1>Work on Real-World Projects</h1>
        </li>
        <li>
          <p>
            Our Live Project Training provides an opportunity to work on actual
            industry projects, enabling you to gain practical experience.
            Collaborate with industry professionals and enhance your
            problem-solving and technical skills.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Learn with Industry Experts</h1>
                </li>
                <li>
                  <p>
                    Our experienced trainers provide mentorship and guidance
                    while you work on real-time projects. Gain insights into
                    industry best practices and stay updated with the latest
                    technologies.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Gain Practical Knowledge</h1>
                </li>
                <li>
                  <p>
                    Our Live Project Training bridges the gap between
                    theoretical knowledge and practical application. You will
                    gain hands-on experience in various domains, including
                    software development, data analytics, and IT infrastructure
                    management.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Build a Strong Portfolio</h1>
                </li>
                <li>
                  <p>
                    By working on live projects, you will build a robust
                    portfolio that showcases your practical skills and technical
                    expertise to potential employers, boosting your career
                    prospects.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwnl-gHopgeWpPf_7h64nDmI6d2BV7RVsMrg&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Collaborate with Peers and Industry Leaders</h1>
            </li>
            <li>
              <p>
                Our Live Project Training fosters collaboration, where you will
                work alongside peers, industry experts, and mentors to tackle
                real-world challenges and find effective solutions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Gain Exposure to Emerging Technologies</h1>
            </li>
            <li>
              <p>
                Get hands-on exposure to the latest technologies like cloud
                computing, machine learning, data science, and cybersecurity as
                part of your live project experience.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-12">
        <li>
          <img src="https://technocampustraining.wordpress.com/wp-content/uploads/2014/10/pro.jpg?w=705" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Boost Your Career with Live Project Training</h1>
            </li>
            <li>
              <p>
                With the practical experience gained through our Live Project
                Training, you will have a competitive edge in the job market,
                making you an attractive candidate for top employers.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Stay Ahead with Industry-Relevant Projects</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Our Live Project Training incorporates real-time projects
                relevant to current industry trends, ensuring that you are
                prepared for the challenges of the evolving IT landscape.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LiveProjectTraning;

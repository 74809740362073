import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./ConsultingService/FinancialPlanningConsulting.png";

const ITInfrastructureCounsilting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Financial Planning</h1>
            <h2>
              Empowering your financial future with expert guidance and
              strategies.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Connect With Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-13" id="globalpre">
        <li>
          <h1>Explore Comprehensive Financial Planning Solutions</h1>
        </li>
        <li>
          <p>
            Discover how our financial planning services help individuals and
            businesses achieve their financial goals. From investment strategies
            to retirement planning, we offer expert insights tailored to your
            needs.
          </p>
        </li>
        <li>
          <button>Explore Solutions</button>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Plan for a Secure Retirement</h1>
            </li>
            <li>
              <p>
                Our retirement planning services ensure that you have a
                structured and effective strategy to secure your future. We'll
                guide you through investment choices, tax optimization, and
                wealth management.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Consultation</button>
                </li>
                <li>
                  <button>Watch a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img
            src="https://www.goldenbulls.co.in/media/financial-plan-1.jpg"
            alt="Financial planning"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Trusted Financial Advisors for Your Future</h1>
            </li>
            <li>
              <h2>
                Whether you're planning for retirement or managing your assets,
                our experts are here to provide reliable, informed advice to
                help you achieve your financial goals.
              </h2>
            </li>
            <li>
              <button>Why Choose Us</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img
            src="https://thumbs.dreamstime.com/z/financial-planning-consulting-focused-wealth-management-strategies-optimize-secure-retirement-attain-goals-320122756.jpg"
            alt="Investment Strategy"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
        <li>
          <ul>
            <li>
              <h1>Maximize Your Wealth and Investment Potential</h1>
            </li>
            <li>
              <p>
                Unlock the full potential of your investments. Our financial
                consultants offer personalized investment strategies to help
                grow your wealth while minimizing risks.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Consultation</button>
                </li>
                <li>
                  <button>Watch a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Acetians Financial Insights</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Stay informed with the latest financial trends, tips, and
                insights. Learn how to manage risks, grow your assets, and make
                smarter financial decisions with expert advice from Acetians.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ITInfrastructureCounsilting;

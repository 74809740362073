import React from "react";
import { Link } from "react-router-dom";
import Img from "./operationalSupportImg/TechnicalSupport.png";
import { useEffect } from "react";

const TechnicalSupport = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Technical Support</h1>
            <h2>
              we provide end-to-end technical support designed to ensure
              seamless business operations and enhanced system performance.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Maximize Your IT Infrastructure Efficiency</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Comprehensive IT Support Services</h3>
                </li>
                <li>
                  <h2>
                    Tailored technical support to suit your business needs
                  </h2>
                </li>
                <li>
                  <p>
                    Our IT support services offer proactive monitoring,
                    troubleshooting, and real-time solutions to ensure your
                    infrastructure operates optimally at all times.
                  </p>
                </li>
                <li>
                  <h1>Discover More About Our IT Support</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Cloud Infrastructure Support</h3>
                </li>
                <li>
                  <h2>Scalable solutions for your cloud environment</h2>
                </li>
                <li>
                  <p>
                    Leverage our cloud support services to optimize your cloud
                    computing resources and maintain a secure, reliable cloud
                    environment.
                  </p>
                </li>
                <li>
                  <h1>Learn About Cloud Solutions</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-6">
        <li>
          <h1>Ensure Operational Continuity with Proactive Support</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPYQXKC0zMu-yjp4j9rqiafhEzfPsYHtq_oQ&s" />
                </li>
                <li>
                  <h1> . Power Recognition</h1>
                </li>
                <li>
                  <p>
                    Acetians Technologies has been recognized for delivering
                    exceptional customer service in technical support for over
                    12 years in a row.
                  </p>
                </li>
                <li>
                  <h2>View Full Recognition Details</h2>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://unity-connect.com/wp-content/uploads/2023/03/Difference-Between-Technical-Support-and-Desktop-Support_2165113523.jpg" />
                </li>
                <li>
                  <h1> . Power Awards</h1>
                </li>
                <li>
                  <p>
                    Our commitment to excellence in customer service and tech
                    support has earned us consistent recognition from . Power.
                  </p>
                </li>
                <li>
                  <h2>See Full Award Details</h2>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://static.vecteezy.com/system/resources/thumbnails/004/942/838/small/technical-support-icon-in-robotic-hand-customer-help-tech-support-customer-service-business-and-technology-concept-illustration-vector.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>24/7 Business Support from Acetians Technologies</h1>
            </li>
            <li>
              <p>
                We offer round-the-clock support to ensure that your systems,
                networks, and applications are running smoothly at all times.
                Our team is always ready to help address any issues promptly and
                efficiently.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Incident Management & Resolution</h1>
                    </li>
                    <li>
                      <p>
                        Address IT incidents quickly and ensure minimal
                        disruption to your business operations with our
                        effective incident management process.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Performance Optimization</h1>
                    </li>
                    <li>
                      <p>
                        Continuously monitor and optimize the performance of
                        your IT infrastructure to ensure your systems are always
                        running at peak performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Data Backup & Recovery</h1>
                    </li>
                    <li>
                      <p>
                        Implement effective data backup and recovery strategies
                        to safeguard your business-critical data and prevent
                        loss.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Technical Support Solutions with Acetians</h1>
            </li>
            <li>
              <h2>
                Take advantage of our expertise and cutting-edge solutions to
                optimize your technology infrastructure. Acetians Technologies
                offers the best in class technical support for your business
                operations.
              </h2>
            </li>
            <li>
              <button>Explore Support Services</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TechnicalSupport;

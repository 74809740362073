import React, { useState } from "react";
import Soluction from './Solutions/Soluction';
import Data from '../schema.json'; // Importing JSON data
import CloudSoluction from "./Solutions/CloudSoluction";
import InfrastructureSoluction from "./Solutions/InfrastructureSoluction";
import DigitalExperienceSoluctions from "./Solutions/DigitalExperienceSoluctions";
import EnterpriseSoluction from "./Solutions/EnterpriseSoluction";


function ProductTabComponent({ setshow }) {  // Receive setshow as a prop
  const [isshow, setIsshow] = useState(false);
  const [Color, setColor] = useState(false);
  const [sublinks, setSublinks] = useState([]); // To store sublinks for the selected solution

  // Function to show selected tab and set its sublinks
  const showcomponent = (tab, sublinks) => {
    setIsshow(tab);
    setSublinks(sublinks); // Set the sublinks based on the clicked solution
    if (Color === 'lightcoral') return;
    setColor('lightcoral');
  };

  // Accessing the 'Solutions' section from the JSON
  const solutionsLinks = Data[1]?.links || []; 

  return (
    <div className="tab">
      <div className="tab-list">
        <ul className="list-link">
          <li onClick={() => showcomponent("Solutions Home", [])} className="main-page">
            Solutions
          </li>

          {/* Dynamically render the solutions links */}
          {solutionsLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => showcomponent(link.name, link.sublinks)} // Passing sublinks of clicked section
            >
              {link.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="list-content">
        {isshow === false && <Soluction />}
        {isshow === "Solutions Home" && <Soluction />}
        {isshow === "Cloud Solution" && <CloudSoluction sublinks={sublinks} setshow={setshow} />}
        {isshow === "Infrastructure Solution" && <InfrastructureSoluction sublinks={sublinks} setshow={setshow} />}
        {isshow === "Digital Solution" && <DigitalExperienceSoluctions sublinks={sublinks} setshow={setshow} />}
        {isshow === "Enterprise Solutions" && <EnterpriseSoluction sublinks={sublinks} setshow={setshow} />}
      </div>

    </div>
  );
}

export default ProductTabComponent;

import React from "react";
import {
  MenuOutlined,
  LanguageOutlined,
  Person2Outlined,
  SearchOffOutlined,
  SearchOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Link } from "react-router-dom";
import Logo from "./Acetians r logo (1).png";
import LoginModal from "../pages/LoginModle";
import { useState } from "react";

const Bottom_header = ({ onSelect, gotohome }) => {
  // Function to close the component when a link is clicked

  const [showlogin, setshowlogin] = useState(false);

  const closeModal = () => setshowlogin(false);

  const scrollTobottom = () => {
    window.scrollTo({
      bottom: 100,
      behavior: "smooth", // This makes the scroll smooth
    });
  };

  const [shownav, setshownav] = useState(true);
  const showicon = () => {
    setshownav(!shownav);
  };

  return (
    <div className="bottom__header">
      <ul>
        <li>
          <Link onClink={gotohome} to="/">
            <li>
              <img src={Logo} onClick={gotohome} className="Logo-Acetians" />
            </li>
          </Link>

          <ul className="bottom_header_links">
            <li onClick={() => onSelect("product")} className="click">
              Product and Services
            </li>
            <li onClick={() => onSelect("solution")}>Solutions </li>
            <li onClick={() => onSelect("Support")}>Support </li>
            <li onClick={() => onSelect("Learn")}>Learn</li>
          </ul>
        </li>

        <ul className="show">
          <li>
            {/* <li>
              {" "}
              <Link
                onClick={() => setshowlogin(true)}
                className="Register-button"
              >
                {" "}
                <Person2Outlined style={{ color: "gray", width: "20px" }} />
              </Link>
              {showlogin && <LoginModal closeModal={closeModal} />}
            </li> */}

            <li className="menu-icon">
              {shownav ? (
                <MenuOutlined
                  onClick={() => {
                    onSelect("nav-link");
                    showicon();
                  }}
                />
              ) : (
                <CloseRoundedIcon
                  onClick={() => {
                    showicon();
                    gotohome();
                  }}
                />
              )}
            </li>
          </li>
        </ul>

        <li>
          <ul className="bottom_header_links_last">
            <li>
              {" "}
              <Link onClick={gotohome} to="/contact-us">
                Contact Us
              </Link>{" "}
            </li>
            {/* <li>
              {" "}
              <Link
                onClick={() => setshowlogin(true)}
                className="Register-button"
              >
                {" "}
                <Person2Outlined className="Login-Icon" />
              </Link>
              {showlogin && <LoginModal closeModal={closeModal} />}
            </li> */}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Bottom_header;

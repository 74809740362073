import React from "react";
import { Link } from "react-router-dom";
import Img from "./operationalSupportImg/InsidentManagement.png";
import { useEffect } from "react";

const IncidentManagement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Incident Management</h1>
            <h2>
              Modern" could be a smaller and simpler alternative to
              "state-of-the-art
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our comprehensive Incident Management solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtEY5CRTmAc_Uf-Xo9CdH-TMsljZJftnKDnQ&s" />
                </li>
                <li>
                  <h1>Advanced Incident Response Strategies</h1>
                </li>
                <li>
                  <p>
                    Implement advanced incident response strategies that
                    mitigate the impact of incidents and ensure operational
                    continuity. Our solutions are designed to be scalable and
                    flexible for diverse industries.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.manageengine.com/products/service-desk/it-incident-management/images/guide-for-it-incident-management-guide.png" />
                </li>
                <li>
                  <h1>Proactive Monitoring and Detection</h1>
                </li>
                <li>
                  <p>
                    Enhance your organization's security posture with proactive
                    monitoring and detection tools that can identify incidents
                    before they escalate, enabling rapid response and
                    resolution.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDxcimf5zlZVZh8ZzL_NWCG1ZKxJH7J6BxU6Dxh552ntTPZKFF7km6w-dpDGV3gp5SUZs&usqp=CAU" />
                </li>
                <li>
                  <h1>Incident Communication Protocols</h1>
                </li>
                <li>
                  <p>
                    Develop clear and effective communication protocols to
                    ensure all stakeholders are informed during incidents,
                    reducing confusion and facilitating a smoother recovery
                    process.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://cdn.shopify.com/s/files/1/0576/7063/1573/files/1_b6dad74b-6886-4960-ad3e-fecbad7c2d2d_600x600.png?v=1689710222" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Incident Management Forum</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends in incident management
                    by joining our Incident Management Forum. Network with
                    professionals, access expert insights, and learn how to
                    better handle incidents in your organization.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies Incident Management Report 2024</h1>
            </li>
            <li>
              <p>
                Get insights from our latest survey, covering incident
                management trends, response techniques, and real-world case
                studies from industry leaders to help improve your response
                plans.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Save on Incident Management Software</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Incident Management Suite</h3>
                </li>
                <li>
                  <h2>Streamline your incident response</h2>
                </li>
                <li>
                  <p>
                    Get all the tools you need for efficient incident management
                    in one comprehensive software suite.
                  </p>
                </li>
                <li>
                  <h1>View Incident Management Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Incident Response Platform</h3>
                </li>
                <li>
                  <h2>Optimize your incident response process</h2>
                </li>
                <li>
                  <p>
                    Maximize response efficiency and minimize downtime with
                    Acetians’ powerful incident response platform designed for
                    critical systems.
                  </p>
                </li>
                <li>
                  <h1>View Incident Response Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default IncidentManagement;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./HardwareSolutionImages/pheripheraldevices.png";
import { useEffect } from "react";
const PeripheralDevices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Peripheral Devices</h1>
            <h2>
              {" "}
              Your trusted partner for reliable, high-performance peripherals.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent " id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Explore Acetians Peripheral Devices</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies offers a comprehensive range of peripheral
                devices designed for efficiency, quality, and seamless
                integration with your existing systems.
              </h2>
            </li>
            <li>
              <button>See All Products</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPySYgB3BSTSQpBwISmXJrexkl_PWW6ZzzKA&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>High-Performance Monitors</h1>
            </li>
            <li>
              <p>
                Acetians provides cutting-edge monitors that offer stunning
                visual clarity, ideal for any business or personal use.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>4K Ultra HD Monitors</h1>
                    </li>
                    <li>
                      <p>
                        Experience unparalleled picture quality with 4K
                        monitors, perfect for professional workstations or media
                        consumption.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Curved Displays</h1>
                    </li>
                    <li>
                      <p>
                        Enhance productivity with immersive curved displays,
                        providing an expansive view for multitasking.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Advanced Keyboards & Mice</h1>
            </li>
            <li>
              <p>
                From ergonomic keyboards to ultra-precise mice, our peripherals
                improve your typing comfort and precision.
              </p>
            </li>
            <li>
              <button>Explore Keyboard & Mice</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdfbWNG6AghA-1FvAMKIQGbH2W7IQa8PWqA&s" />
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Wireless Peripherals</h1>
            </li>
            <li>
              <p>
                Acetians provides cutting-edge wireless devices, ensuring your
                workspace remains clutter-free and efficient.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://spca.education/wp-content/uploads/2023/07/OPD.webp" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Optimized Connectivity Devices</h1>
            </li>
            <li>
              <p>
                Ensure seamless network connections with our advanced routers,
                adapters, and other connectivity devices.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians Peripheral Devices Trusted Globally</h1>
            </li>
            <li>
              <h2>
                No matter your business needs, Acetians offers the perfect
                peripherals for enhanced productivity and performance.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default PeripheralDevices;

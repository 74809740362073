import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InstructureWorkshop/CloudComputingBasic.png";

const CloudComputiingBasics = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cloud Computing Basics</h1>
            <h2>
              {" "}
              we offer cloud computing solutions to transform your business.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-2" id="globalpre">
        <li>
          <h1>The latest: Cloud Computing News and Updates</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://www.cisco.com/c/en_in/solutions/industries/manufacturing/jcr:content/Grid/category_atl/layout-category-atl/blade/bladeContents/spotlight_969515212.img.jpg/1641791342633.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Cloud Computing Forum</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends in cloud computing by
                    joining our Cloud Computing Forum. Network with
                    professionals, access expert insights, and learn how to
                    better leverage the cloud for your business.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-2">
        <li>
          <ul>
            <li>
              <h1>Agile & Scalable Cloud Solutions</h1>
            </li>
            <li>
              <p>
                Acetians Technologies specializes in cloud solutions that are
                agile and scalable, helping businesses reduce infrastructure
                costs and enhance flexibility. We ensure seamless cloud
                integration and management.
              </p>
            </li>
            <li>
              <button>Learn more</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.ingenious.co.uk/wp-content/uploads/2023/08/What-is-Cloud-Computing.jpeg" />
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Forrester names Acetians a Leader in Cloud Solutions</h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in cloud
                computing solutions, offering innovative cloud services to
                accelerate your digital transformation and reduce operational
                complexity.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://pandorafms.com/blog/wp-content/uploads/2023/11/DALL%C2%B7E-2023-11-27-13.56.23-A-minimalistic-and-modern-illustration-for-a-blog-post-about-Cloud-Computing.-The-design-includes-simple-symbols-representing-cloud-storage-data-tran.png" />
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Deliver Superior Cloud-Based Experiences</h1>
        </li>
        <li>
          <p>
            Accelerate your business growth with Acetians’ expertise in cloud
            computing. We help you optimize and scale your digital platforms to
            deliver superior user experiences.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/content/dam/cisco-cdc/site/images/icons-and-shapes/products/icon-product-information-matrix.svg" />
                </li>
                <li>
                  <h1>Optimize Cloud Performance</h1>
                </li>
                <li>
                  <p>
                    Leverage advanced cloud tools to optimize the performance of
                    your cloud-based applications and services for long-term
                    success.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/content/dam/cisco-cdc/site/images/icons-and-shapes/products/icon-compatibility-matrix.svg" />
                </li>
                <li>
                  <h1>Maximize Cloud ROI</h1>
                </li>
                <li>
                  <p>
                    Maximize your cloud investment with Acetians’
                    observability-driven strategies across all stages of your
                    business lifecycle.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/content/dam/cisco-cdc/site/images/icons-and-shapes/products/icon-interoperability-matrix.svg" />
                </li>
                <li>
                  <h1>Enhance Cloud Scalability</h1>
                </li>
                <li>
                  <p>
                    Ensure that your cloud infrastructure can scale seamlessly
                    with demand, improving efficiency and reducing costs.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies Cloud Computing Report 2024</h1>
            </li>
            <li>
              <p>
                Get insights from our latest survey, covering cloud computing
                trends, technologies, and strategies for optimizing your
                business' cloud adoption and usage.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Deliver Superior Cloud-Based Experiences</h1>
        </li>
        <li>
          <p>
            Accelerate your cloud adoption with Acetians’ expertise. We help
            optimize performance, scalability, and security in your cloud-based
            environments.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize Cloud Performance</h1>
                </li>
                <li>
                  <p>
                    Leverage cutting-edge tools for optimizing user experiences
                    and operational efficiency across your cloud solutions.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Maximize Cloud ROI</h1>
                </li>
                <li>
                  <p>
                    Enhance business performance and maximize cloud ROI with
                    Acetians' cloud strategies and tools tailored to your
                    specific needs.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Enhance Cloud Scalability</h1>
                </li>
                <li>
                  <p>
                    Scale your cloud infrastructure to meet growing demands and
                    improve operational efficiency with Acetians' cloud
                    solutions.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ Cloud-Powered Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians leverages cloud technologies to streamline
                business processes, improve collaboration, and drive innovation
                for your company’s future.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CloudComputiingBasics;

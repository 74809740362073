import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/AiHAndsonprojects.png";

const HandsOnAiProject = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Hands-On AI Project</h1>
            <h2>
              {" "}
              Join Acetians' Hands-On AI Project for practical learning and
              expert guidance.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our hands-on AI project solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSNL_S2RrcDIOh4Tx8XUnTnN7wZChZa2SvYQ&s" />
                </li>
                <li>
                  <h1>AI Model Development</h1>
                </li>
                <li>
                  <p>
                    Get hands-on experience in building and deploying machine
                    learning models that can solve real-world business problems.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjp6Umz6VeRq_9yj_8Ula8vp9YuF3ijutFmw&s" />
                </li>
                <li>
                  <h1>AI Data Analysis</h1>
                </li>
                <li>
                  <p>
                    Learn how to collect, clean, and analyze data to uncover
                    insights using AI-powered tools and algorithms.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://automationedge.com/es/wp-content/uploads/2020/08/AI-Powered-Automation-For-Managed-Service-Providers.jpg" />
                </li>
                <li>
                  <h1>AI-powered Automation</h1>
                </li>
                <li>
                  <p>
                    Gain practical experience in automating processes and tasks
                    using AI technologies to increase efficiency and
                    productivity.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>Latest News: AI Events and Learning Opportunities</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://imt-soft.com/wp-content/uploads/2023/05/AI-testing.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join Our AI Learning Workshop</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends and breakthroughs in AI
                    by joining our hands-on AI learning workshop. Collaborate
                    with experts, explore AI use cases, and sharpen your skills.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the Workshop</button>
                    </li>
                    <li>
                      <button>Explore AI Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Innovation through AI Technologies</h1>
            </li>
            <li>
              <p>
                Leverage the latest advancements in AI to transform your
                business strategy and operations with cutting-edge AI solutions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start a Consultation</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://itsm.tools/wp-content/uploads/2018/05/automation-ai-blog-img.gif" />
        </li>
      </ul>
      <ul className="business-component-5">
        <li>
          <img src="https://images.prismic.io//intuzwebsite/ZhY3QDjCgu4jzt9b_HowAIinBusinessProcessAutomationisTransformingWorkflows_%402x-1.png?w=2400&q=80&auto=format,compress&fm=png8" />
        </li>
        <li>
          <ul>
            <li>
              <h1>AI Leadership in the Industry</h1>
            </li>
            <li>
              <p>
                Learn from industry leaders who are driving AI innovation. Get
                insights and best practices for implementing AI in your
                business.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global AI Solutions for Businesses</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies helps businesses scale with AI-driven
                strategies tailored for global markets.
              </h2>
            </li>
            <li>
              <button>Why Acetians</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Unlock the Future with AI-Driven Solutions</h1>
        </li>
        <li>
          <p>
            Speed up your digital transformation with AI-powered tools and
            techniques that drive operational efficiency and innovation.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize with AI</h1>
                </li>
                <li>
                  <p>
                    Leverage AI to optimize your business processes and improve
                    user experiences.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Performance Boost</h1>
                </li>
                <li>
                  <p>
                    Improve overall business performance with AI-based
                    optimization tools.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Transformative AI Solutions</h1>
                </li>
                <li>
                  <p>
                    Explore AI-driven transformations and how to implement them
                    to create value for your business.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default HandsOnAiProject;

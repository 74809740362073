import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/introtoai.png";
import { useEffect } from "react";
const IntroducationsToAI = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Introduction to AI</h1>
            <h2> we offer AI training to help businesses integrate AI.</h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-5" id="globalpre">
        <li>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyt6x8bUjK5YCXlp3Rjj4N1mtvCfeLAJY2tA&s" />
        </li>
        <li>
          <ul>
            <li>
              <h1>AI and Machine Learning for Strategic Advantage</h1>
            </li>
            <li>
              <p>
                Acetians Technologies helps businesses adopt machine learning
                models to gain a competitive edge by predicting trends and
                optimizing operations.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free AI Trial</button>
                </li>
                <li>
                  <button>Schedule an AI Workshop</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>AI-Powered Solutions for Global Markets</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies enables businesses to tap into the global
                AI market by customizing solutions that are scalable across
                borders.
              </h2>
            </li>
            <li>
              <button>Why AI with Acetians</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-7">
        <li>
          <h1>Transform Your Business with AI</h1>
        </li>
        <li>
          <p>
            Use AI to improve customer experience, streamline processes, and
            make data-driven decisions that drive innovation.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Optimization</h1>
                </li>
                <li>
                  <p>
                    Leverage AI to optimize your operations, automate tasks, and
                    enhance overall business efficiency.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Strategies for Growth</h1>
                </li>
                <li>
                  <p>
                    Maximize ROI with AI-powered strategies that align with your
                    business goals, enhancing growth and scalability.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>AI Performance Enhancement</h1>
                </li>
                <li>
                  <p>
                    Enhance business performance using AI tools that fine-tune
                    operations, streamline workflows, and ensure data integrity.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-4">
        <li>
          <h1>Maximize AI Implementation</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians AI Solutions Suite</h3>
                </li>
                <li>
                  <h2>Unlock the potential of AI for your business</h2>
                </li>
                <li>
                  <p>
                    Get a complete suite of tools designed to integrate AI
                    seamlessly into your business processes.
                  </p>
                </li>
                <li>
                  <h1>Explore AI Solutions Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians AI Response Platform</h3>
                </li>
                <li>
                  <h2>Automate and optimize AI-driven processes</h2>
                </li>
                <li>
                  <p>
                    Maximize the efficiency of AI tools and enhance automation
                    across critical systems with Acetians’ AI platform.
                  </p>
                </li>
                <li>
                  <h1>Explore AI Response Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ AI-Powered Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians integrates AI into business strategies,
                offering innovative solutions that foster long-term success.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians AI Insights Report 2024</h1>
            </li>
            <li>
              <p>
                Get the latest insights on AI adoption trends, case studies, and
                techniques from global leaders in AI to guide your business
                strategy.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default IntroducationsToAI;

import React, { useState } from "react";
import Data from '../schema.json';
import ProductAndServicehome from "../components/ProductandServices/ProductAndServicehome";
import Network from "../components/ProductandServices/Network";
import ConsultingServices from "./ProductandServices/ConsultingServices";
import DataAnalytics from "./ProductandServices/DataAnalytics";

function ProductTabComponent({ setshow }) {  // Receive setshow as a prop
  const [isshow, setIsshow] = useState("Products and Services Home");
  const [sublinks, setSublinks] = useState([]); 
  const [Color, setColor] = useState(""); 

  const showComponent = (tab, sublinks) => {
    setIsshow(tab);
    setSublinks(sublinks); 
    if (Color === "lightcoral") return;
    setColor("lightcoral");
  };

  const productLinks = Data[0]?.links || []; 

  return (
    <div className="tab">
      <div className="tab-list">
        <ul className="list-link">
          <li
            
            onClick={() => showComponent("Products and Services Home")}
            className="main-page"
            
          >
           
            Products and Services
          
           
          </li>

          {productLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => showComponent(link.name, link.sublinks)}
              className={isshow === link.name ? "selected" : ""}
            >
              {link.name}
            </li>
          ))}
        </ul>
        
      </div>

      <div className="list-content">
        {isshow === "Products and Services Home" && <ProductAndServicehome />}
        {isshow === "Consulting Services" && <ConsultingServices sublinks={sublinks} setshow={setshow} />} {/* Pass setshow to ConsultingServices */}
        {isshow === "Hardware Solution" && <Network sublinks={sublinks} setshow={setshow} />}
        {isshow === "More Services" && <DataAnalytics sublinks={sublinks} setshow={setshow} />}
      </div>
    </div>
  );
}

export default ProductTabComponent;

import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/AIworkshop.png";

const AIEthicsWorkshop = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> AI Ethics Workshop</h1>
            <h2> we host AI workshops to explore ethical AI implementation.</h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-4" id="globalpre">
        <li>
          <h1>Understanding AI Ethics</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Ethical Frameworks in AI</h3>
                </li>
                <li>
                  <h2>Creating Ethical AI Systems</h2>
                </li>
                <li>
                  <p>
                    Learn how to implement ethical principles in AI systems,
                    focusing on fairness, transparency, accountability, and
                    privacy.
                  </p>
                </li>
                <li>
                  <h1>Join Our Workshop</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>AI and Social Impact</h3>
                </li>
                <li>
                  <h2>Ensuring AI Benefits All</h2>
                </li>
                <li>
                  <p>
                    Explore the social implications of AI and how to ensure its
                    benefits are distributed equitably across society.
                  </p>
                </li>
                <li>
                  <h1>Register for the Workshop</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global AI Ethics Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies helps businesses navigate the global
                landscape of AI ethics, providing frameworks that align with
                international standards and regulations.
              </h2>
            </li>
            <li>
              <button>Why Ethical AI Matters</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://cdn.prod.website-files.com/624717c641bda0f076a77654/6687cabddf471a1137619662_AI%20Ethics.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>AI Ethics Consulting Expertise</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we specialize in AI ethics consulting,
                helping organizations integrate ethical AI practices and ensure
                compliance with emerging regulations.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>AI Bias Mitigation</h1>
                    </li>
                    <li>
                      <p>
                        Learn how to identify and mitigate bias in AI systems to
                        ensure fairness and equality.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Privacy and Security in AI</h1>
                    </li>
                    <li>
                      <p>
                        Understand how to protect privacy and security in AI
                        systems to prevent misuse and maintain trust.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Ethical AI Implementation</h1>
                    </li>
                    <li>
                      <p>
                        Develop actionable strategies for integrating ethical
                        principles into AI project lifecycles.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians AI Ethics Report 2024</h1>
            </li>
            <li>
              <p>
                Download our latest report on AI ethics, exploring key trends,
                challenges, and best practices for implementing responsible AI
                across industries.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AIEthicsWorkshop;

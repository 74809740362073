import React from "react";
import {
  CloudOutlined,
  HandshakeOutlined,
  HardwareOutlined,
  OutboundOutlined,
  PrecisionManufacturing,
} from "@mui/icons-material";

const HoverBoxes = () => {
  const boxes = [
    {
      image:
        "https://futransolutions.com/wp-content/uploads/2023/09/MicrosoftTeams-image-50.jpg",
      text: "Gen AI",
      icon: <HandshakeOutlined />,
    },
    {
      image:
        "https://www.device42.com/blog/wp-content/uploads/2023/03/14_01edge-cloud-computing.jpg",
      text: "Cloud",
      icon: <CloudOutlined />,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGMa9qPM6QdDUPBJzgtXG14yVNqF1dlBVsBw&s",
      text: "Consultancy",
      icon: <OutboundOutlined />,
    },
    {
      image:
        "https://media.istockphoto.com/id/1496920323/photo/thinking-robot.jpg?s=612x612&w=0&k=20&c=heLdpKSUvt0w7dtm5IXmu4JJ5GvPvdpcywrqcL78P9s=",
      text: "Humanoid",
      icon: <HardwareOutlined />,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDmVFeu41N8PHVepGz--cUL15_mrJViIy4wA&s",
      text: "Outsourcing",
      icon: <OutboundOutlined />,
    },
  ];

  return (
    <div className="vertical">
      <h1> Our Core Services</h1>
      <div className="row-container">
        {boxes.map((box, index) => (
          <div className="box" key={index}>
            <img src={box.image} alt={`Box ${index + 1}`} />
            <div className="text-overlay">
              <div className="icon">{box.icon}</div>
              <p>{box.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoverBoxes;

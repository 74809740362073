import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "./CampusProgramImg/CerrierGuidWorkshop.png";

const CareerGuidanceWorkshops = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Career Guidance Workshops</h1>
            <h2>
              {" "}
              we offer career guidance workshops to unlock professional
              potential.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our comprehensive Career Guidance Workshops</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTniI1BjqYsEGRPLI3nIu0BDNJIrlw5w_S1rg&s" />
                </li>
                <li>
                  <h1>Resume Building and Job Search Strategies</h1>
                </li>
                <li>
                  <p>
                    Learn how to create a standout resume, tailor your
                    applications, and optimize your job search strategy to
                    increase your chances of landing the job you want.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPFgkMxAcPWfLpkkuSQI0KWYtpYjHtWHaQkMNT37SWe__OXc1XWTkgFjpYx_nnKZl4V6s&usqp=CAU" />
                </li>
                <li>
                  <h1>Interview Techniques and Confidence Building</h1>
                </li>
                <li>
                  <p>
                    Equip yourself with interview strategies and the confidence
                    to answer questions effectively. Gain insights into body
                    language, communication skills, and common interview
                    mistakes to avoid.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://img.freepik.com/free-vector/career-development-infographics-banner-ladder-success_107791-3509.jpg" />
                </li>
                <li>
                  <h1>Career Pathways and Skill Development</h1>
                </li>
                <li>
                  <p>
                    Understand various career pathways in your field and
                    identify the skills you need to develop to succeed in your
                    chosen career. Tailor your learning to meet industry demands
                    and excel.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-2">
        <li>
          <h1>Latest: News and Events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://static.vecteezy.com/system/resources/previews/002/831/255/non_2x/shortcut-or-advancement-in-career-development-or-work-to-achieve-target-skip-step-to-reach-goal-or-beginner-mistake-by-try-hard-way-to-success-concept-businessman-skip-stair-step-to-reach-target-vector.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Career Guidance Forum</h1>
                </li>
                <li>
                  <p>
                    Stay updated on the latest trends in career development by
                    joining our Career Guidance Forum. Network with
                    professionals, gain insights from experts, and learn how to
                    further your career goals.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies Career Development Report 2024</h1>
            </li>
            <li>
              <p>
                Gain valuable insights from our latest survey, covering emerging
                career trends, job market analysis, and strategies for
                professional growth to help guide your career decisions.
              </p>
            </li>
            <li>
              <button>Download the Report</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>Save on Career Development Programs</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Career Growth Suite</h3>
                </li>
                <li>
                  <h2>Accelerate your career development</h2>
                </li>
                <li>
                  <p>
                    Access a complete set of tools designed to help you navigate
                    your career journey with confidence and clarity.
                  </p>
                </li>
                <li>
                  <h1>View Career Growth Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Professional Development Platform</h3>
                </li>
                <li>
                  <h2>Enhance your professional skills</h2>
                </li>
                <li>
                  <p>
                    Optimize your skill set and position yourself for success in
                    any career field with Acetians’ advanced professional
                    development platform.
                  </p>
                </li>
                <li>
                  <h1>View Professional Development Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Forrester names Acetians a Leader in Career Development
                Solutions
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in the
                field of career development solutions, offering innovative
                resources and platforms to help individuals grow professionally.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://media.istockphoto.com/id/889968488/photo/skill-concept.jpg?s=612x612&w=0&k=20&c=yDPoLvtvsnNbJQffOO8iMtfofeYLZ-r4PIdFCH7hBtc=" />
        </li>
      </ul>
    </div>
  );
};

export default CareerGuidanceWorkshops;

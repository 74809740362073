import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/Technicalskill.jpg";
import { useEffect } from "react";

const TechnicalSkillsTraining = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Technical Skills Training</h1>
            <h2>
              {" "}
              We offer expert technical training to enhance your skills in
              cutting-edge technologies and boost your career potential.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://www.investopedia.com/thmb/uDGDPxBbTGCjsHLlgv0HC-IE8NY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/technical-skills.asp-final-2bfdf377f91841919654e2c42b7cb0e2.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Technical Skills Training Programs</h1>
            </li>
            <li>
              <p>
                Our technical skills training programs are designed to equip
                professionals with the knowledge and expertise in key areas such
                as programming, networking, cybersecurity, and cloud computing.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 1: Identify Key Skill Areas</h1>
                    </li>
                    <li>
                      <p>
                        We begin by understanding your current skill level and
                        career goals, then recommend training programs that best
                        suit your development needs.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 2: Select the Right Learning Path</h1>
                    </li>
                    <li>
                      <p>
                        Whether it's mastering a new programming language or
                        gaining expertise in cloud platforms, we help you select
                        the right courses to enhance your technical
                        capabilities.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 3: Hands-On Practice and Simulations</h1>
                    </li>
                    <li>
                      <p>
                        Practical exercises and simulations are integrated into
                        our programs to ensure that you gain real-world
                        experience in the technical areas you're learning.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 4: Certification and Skill Assessment</h1>
                    </li>
                    <li>
                      <p>
                        Upon completion, you'll be assessed on your newly
                        acquired skills and can earn industry-recognized
                        certifications to validate your technical expertise.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Step 5: Continuous Learning and Career Support</h1>
                    </li>
                    <li>
                      <p>
                        Technical skills are constantly evolving. We offer
                        ongoing support, keeping you updated with the latest
                        trends and technologies in the field.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-11">
        <li>
          <ul>
            <li>
              <h1>Connect with Our Training Experts</h1>
            </li>
            <li>
              <p>
                Reach out to our experts to discuss your technical training
                needs and explore how we can help you achieve your professional
                goals.
              </p>
            </li>
            <li>
              <button>Contact Us</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://media.licdn.com/dms/image/v2/D4E12AQGZO9iEw5MGIA/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1692733334512?e=2147483647&v=beta&t=2FWLvZMhHQKCE4nLA1K8EnDHGNRYjr4Dqns-aIntHtw" />
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Prepare for Future Technologies</h1>
            </li>
            <li>
              <p>
                Our training programs ensure you're prepared for the future of
                technology, helping you stay competitive and ahead in your
                career.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Your Training Today</button>
                </li>
                <li>
                  <button>Watch Our Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Enhance Your Skills with Our Expertise</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Maximize Your Learning Potential</h1>
                </li>
                <li>
                  <p>
                    Collaborate with us to develop a learning plan that
                    maximizes your technical skillset, ensuring you're equipped
                    to succeed in any role.
                  </p>
                </li>
                <li>
                  <h1>Get Expert Guidance</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Upgrade Your Technical Knowledge</h1>
                </li>
                <li>
                  <p>
                    Whether you're improving your coding skills or exploring new
                    technologies, we offer expert guidance for your continuous
                    growth.
                  </p>
                </li>
                <li>
                  <h1>Contact Our Team</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TechnicalSkillsTraining;

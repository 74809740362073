import React from "react";
import img3 from "../ConsultingService/ConsultingService/ITInfrastructureConsulting.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PowerBI = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${"https://learn.microsoft.com/en-us/training/media/products/power-platform/power%20bi/power-bi-overview.png"})`,
        }}
      >
        <ul>
          <li>
            <h1>Microsoft Power BI Services.</h1>
            <h2>
              Power BI Services is a cloud platform for sharing and managing
              data insights
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="dataanalytics-4" id="globalpre">
        <li>
          <h1>Transform Data into Insights</h1>
          <p>
            <span className="dataanalytics-4-bold">
              Data Visualization Made Easy:
            </span>{" "}
            Create interactive and visually engaging dashboards with
            drag-and-drop simplicity.
          </p>
          <p>
            <span className="dataanalytics-4-bold">
              Seamless Data Integration:
            </span>{" "}
            Connect to hundreds of data sources, including Excel, databases, and
            cloud services.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Real-Time Analytics:</span>{" "}
            Monitor your business metrics in real time to make informed
            decisions quickly.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Collaborate and Share:</span>{" "}
            Share dashboards and reports securely across teams and
            organizations.
          </p>
          <p>
            <span className="dataanalytics-4-bold">AI-Driven Insights:</span>{" "}
            Use built-in AI features to uncover trends and make data
            predictions.
          </p>
        </li>

        <li>
          <img src="https://timesproweb-static-backend-prod.s3.ap-south-1.amazonaws.com/Blog_Page_On_What_Is_Power_BI_bf715502b4.webp" />
        </li>
      </ul>

      <ul className="dataanalytics-1">
        <li>
          <img src="https://www.beyondkey.com/blog/wp-content/uploads/2021/08/components.png" />
        </li>
        <li>
          <h1>PowerBI component</h1>
          <p>
            <span className="dataanalytics-4-bold">Power Map :</span> A 3D data
            visualization tool for mapping and analyzing geographical and
            time-based data in Power BI.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Power Query:</span> A tool in
            Power BI and Excel that helps transform, clean, and combine data
            from various sources, simplifying the process of preparing data for
            analysis.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Power View:</span> A data
            visualization tool in Power BI and Excel that allows users to create
            interactive, interactive reports and dashboards with rich,
            interactive charts, graphs, and tables.
          </p>
          <p>
            <span className="dataanalytics-4-bold">Power Pivot:</span> A tool in
            Power BI and Excel for creating advanced data models and performing
            complex calculations.
          </p>
        </li>
      </ul>

      <ul className="dattaanalytics-2">
        <li>
          <h1>Microsoft Power BI consulting solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <h3>Power BI Strategy</h3>
              <p>
                We develop tailored data strategies using Power BI, focusing on
                processes, tools, and insights to drive business success.
              </p>
            </li>
            <li>
              <h3>Data Visualization</h3>
              <p>
                Our Power BI experts craft visual strategies that simplify
                complex data and highlight actionable insights for better
                decision-making.
              </p>
            </li>
            <li>
              <h3>Business Analytics</h3>
              <p>
                Power BI enables us to build data-driven strategies that align
                with your goals, enhancing performance and long-term success.
              </p>
            </li>
            <li>
              <h3>Performance Insights</h3>
              <p>
                Using Power BI, we deliver actionable insights through
                data-driven strategies to optimize business performance.
              </p>
            </li>
            <li>
              <h3>Data-Driven Decisions</h3>
              <p>
                We create Power BI-based strategies that transform data into
                meaningful insights, supporting better decision-making.
              </p>
            </li>
            <li>
              <h3>Power BI Solutions</h3>
              <p>
                We offer customized Power BI solutions, ensuring your data
                strategy aligns with business objectives for long-term growth.
              </p>
            </li>
            <li>
              <h3>Reporting Excellence</h3>
              <p>
                Our Power BI strategies focus on creating insightful reports,
                enhancing visibility, and improving data-driven decision-making.
              </p>
            </li>
            <li>
              <h3>Data Integration</h3>
              <p>
                We use Power BI to develop strategies that integrate diverse
                data sources, ensuring seamless and efficient analysis.
              </p>
            </li>
            <li>
              <h3>Real-Time Analytics</h3>
              <p>
                Power BI helps us build strategies that provide real-time
                analytics, enabling quick, informed business decisions.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="dataanalytics-3">
        <li>
          <img src="https://dce0qyjkutl4h.cloudfront.net/wp-content/uploads/2024/05/data-assessment.webp" />
        </li>
        <li>
          <h4>Data assessment</h4>
          <h1>Take a data assessment to understand your data landscape</h1>
          <p>
            Identify opportunities and gaps in your current data landscape,
            ensure informed decision making and optimized data strategies for
            business growth.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default PowerBI;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./CampusProgramImg/IntershipProgram.png";

const InternshipProgram = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Internship Programs at Acetians Technologies</h1>
            <h2>
              {" "}
              We offer internships with hands-on projects and skill development
              for tech careers.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="compo-2" id="globalpre">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSew9yAdhhfnWe6lvN_wgu1xENBCCtYf9WcYsu7ct9GbLoStbQfdDZGFTNhAg3qqdk_HSk&usqp=CAU" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>
                    Connect with Industry Leaders through Our Internship Program
                  </h1>
                </li>
                <li>
                  <p>
                    Join our internship program and connect with industry
                    leaders, attend workshops, and gain insight into
                    cutting-edge technology trends. It's an opportunity to build
                    your network and jumpstart your career.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Apply Now</button>
                    </li>
                    <li>
                      <button>Learn More</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-3">
        <li>
          <ul>
            <li>
              <h1>2024 Internship Experience Report</h1>
            </li>
            <li>
              <p>
                Learn about the experiences of previous interns at Acetians
                Technologies, and how our programs have helped launch successful
                careers in the tech industry.
              </p>
            </li>
            <li>
              <button>Download the report</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>
            Empower Your Career with an Internship at Acetians Technologies
          </h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>
                    Internship Opportunities in IT and Software Development
                  </h3>
                </li>
                <li>
                  <h2>Gain Practical Experience in Cutting-Edge Projects</h2>
                </li>
                <li>
                  <p>
                    Our internship program offers hands-on experience in
                    real-world IT and software development projects. You will
                    work closely with experts to solve complex challenges and
                    enhance your technical skills.
                  </p>
                </li>
                <li>
                  <h1>View Available Internship Positions</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Internship in Data Analytics & Machine Learning</h3>
                </li>
                <li>
                  <h2>Dive into Data-Driven Projects</h2>
                </li>
                <li>
                  <p>
                    Join our internship program in data analytics and machine
                    learning, where you will gain experience working with real
                    datasets, predictive modeling, and data visualization.
                  </p>
                </li>
                <li>
                  <h1>Apply for Data Analytics Internship</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-5">
        <li>
          <img src="https://t4.ftcdn.net/jpg/04/04/26/31/360_F_404263181_UpEigOt09RQ9Z5bjZ8MU5vFjGspNkyA5.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Expert Guidance for Career Development</h1>
            </li>
            <li>
              <p>
                Receive mentorship from top professionals in the tech industry
                who are dedicated to helping you succeed and grow in your
                career.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Schedule a Meeting</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default InternshipProgram;

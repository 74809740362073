import React from "react";
import { Link } from "react-router-dom";
import Img from "./ConsultingService/BusinessStrategyConsulting.png";
import { useEffect } from "react";
const BusinessStrategyConsulting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <ul>
        <li>
          <div
            className="WhyBharat"
            style={{
              backgroundImage: `url(${Img})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <ul>
              <li>
                <h1> Business Strategy Consulting.</h1>
                <h2>
                  {" "}
                  Together we can create a winning strategy that elevates your
                  business.
                </h2>
                <li className="btn-explore">
                  <Link to="/contact-us">Explore Acetians</Link>
                </li>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <ul className="business-component-4" id="globalpre">
            <li>
              <ul>
                <li>
                  <h1>Fostering Innovation & Creativity</h1>
                </li>
                <li>
                  <p>
                    Build a culture of innovation that drives continuous
                    improvement and creates long-term value for your business.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Start Free Trial</button>
                    </li>
                    <li>
                      <button>Watch A Demo</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
            </li>
          </ul>

          <ul className="business-comopnent">
            <li>
              <ul>
                <li>
                  <h1>Explore Business Strategy Services</h1>
                </li>
                <li>
                  <h2>
                    Our comprehensive consulting services help businesses unlock
                    their true potential by enhancing leadership, operations,
                    and market strategies.
                  </h2>
                </li>
                <li>
                  <button>See All Services</button>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="business-comopnent-7">
            <li>
              <h1>Deliver Superior Business Experiences</h1>
            </li>
            <li>
              <p>
                Our approach ensures that your customers have a seamless and
                exceptional experience, improving satisfaction and loyalty.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                    </li>
                    <li>
                      <h1>Enhance Experiences</h1>
                    </li>
                    <li>
                      <p>
                        Optimize user experiences and business performance with
                        advanced consulting services.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                    </li>
                    <li>
                      <h1>Optimize</h1>
                    </li>
                    <li>
                      <p>
                        Maximize your business outcomes with consulting
                        solutions that drive performance.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <img src="https://media.istockphoto.com/photos/business-in-the-new-era-in-the-office-in-the-modern-digital-age-picture-id1419786425" />
                    </li>
                    <li>
                      <h1>Innovate</h1>
                    </li>
                    <li>
                      <p>
                        Drive innovation and gain competitive advantage with
                        expert strategy consulting.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="business-component-2">
            <li>
              <ul>
                <li>
                  <h1>Tailored Business Solutions</h1>
                </li>
                <li>
                  <p>
                    Get personalized strategies for your business challenges,
                    from organizational design to digital transformation.
                  </p>
                </li>
                <li>
                  <button>Explore Tailored Solutions</button>
                </li>
              </ul>
            </li>
            <li>
              <img src="https://k3techs.com/wp-content/uploads/2024/04/top-view-of-a-business-meeting-with-four-participants-including-an-it-strategy-consultant-discussing-graphs-and-charts-at-a-table-with-laptops-and-documents.jpg" />
            </li>
          </ul>

          <ul className="business-component-1">
            <li>
              <img src="https://www.theforage.com/blog/wp-content/uploads/2022/08/strategy-consultant.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Strategic Consulting</h1>
                </li>
                <li>
                  <p>
                    Our expert consultants bring deep industry knowledge and
                    tailored solutions to guide businesses through challenges
                    and growth opportunities.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Market Strategy</h1>
                        </li>
                        <li>
                          <p>
                            Leverage insights and data-driven strategies to
                            expand market reach and drive growth.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Business Transformation</h1>
                        </li>
                        <li>
                          <p>
                            Our transformation framework helps businesses adapt
                            to changing market conditions and technological
                            innovations.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Leadership Development</h1>
                        </li>
                        <li>
                          <p>
                            Build strong leadership teams that can navigate
                            uncertainty and lead change successfully.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <h1>Innovation & Growth</h1>
                        </li>
                        <li>
                          <p>
                            Unlock opportunities for innovation and sustainable
                            growth to future-proof your business.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="business-component-3">
            <li>
              <img src="https://tbcservices.in/wp-content/uploads/2022/09/1.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Drive Strategic Business Outcomes</h1>
                </li>
                <li>
                  <p>
                    Using advanced data analytics, we help you identify
                    opportunities for operational efficiency and improved
                    decision-making.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Start Free Trial</button>
                    </li>
                    <li>
                      <button>Watch A Demo</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <ul className="business-comopnent-6">
            <li>
              <ul>
                <li>
                  <h1>Trusted by Businesses Worldwide</h1>
                </li>
                <li>
                  <h2>
                    No matter your business's needs, our consulting services are
                    designed to help you succeed.
                  </h2>
                </li>
                <li>
                  <button>Learn More</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default BusinessStrategyConsulting;

import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  X,
  YouTube,
} from "@mui/icons-material";
import React, { useState } from "react";
import Data from "../schema.json";
import { Link } from "react-router-dom";
import Logo from "../Headers_Components/Acetians r logo (1).png";
import TapTop from "../pages/TapTop";

const Footer2 = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const solutionsLinks =
    Data.find((item) => item.name === "Solutions")?.links || [];
  const productsLinks =
    Data.find((item) => item.name === "Product & Services")?.links || [];
  const supportLinks =
    Data.find((item) => item.name === "Support")?.links || [];
  const learnLinks = Data.find((item) => item.name === "Learn")?.links || [];

  
  const handleMouseEnter = (linkName) => {
    setHoveredLink(linkName);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const roductandServices = [
    {
      name: "Business Strategy Consulting",
      path: "/product-and-services/consulting-services/business-strategy-consulting",
    },
    {
      name: "IT Infrastructure Consulting",
      path: "/product-and-services/consulting-services/it-infrastructure-consulting",
    },
    {
      name: "Market Research Services",
      path: "/product-and-services/consulting-services/it-infrastructure-consulting",
    },
    {
      name: "Risk Management Consulting",
      path: "/product-and-services/consulting-services/risk-management",
    },
    {
      name: "Financial Planning Consulting",
      path: "/product-and-services/consulting-services/financial-planning",
    },
  ];
  const cloudSolution = [
    {
      name: "Cloud Migration",
      path: "/solutions/cloud-solution/cloud-migration",
    },
    {
      name: "Cloud Security",
      path: "/solutions/cloud-solution/cloud-security",
    },
    {
      name: "Hybrid Cloud Setup",
      path: "/solutions/cloud-solution/hybrid-cloud-setup",
    },
    {
      name: "Cloud Cost Optimization",
      path: "/solutions/cloud-solution/cloud-cost-optimization",
    },
    {
      name: "Cloud Data Storage",
      path: "/solutions/cloud-solution/cloud-data-storage",
    },
  ];

  const supports = [
    {
      name: "24/7 Technical Support",
      path: "/supports/operational-support/24*7-technical-support",
    },
    {
      name: "System Monitoring",
      path: "/supports/operational-support/system-monitoring",
    },
    {
      name: "Incident Management",
      path: "/supports/operational-support/incident-management",
    },
    {
      name: "IT Asset Management",
      path: "/supports/operational-support/it-asset-management",
    },
    {
      name: "Remote Support Services",
      path: "/supports/operational-support/remote-support-services",
    },
  ];

  const Learn = [
    {
      name: "On-Campus Training",
      path: "/learn/campus-programs/oncampus-training",
    },
    {
      name: "Internship Programs",
      path: "/learn/campus-programs/internship-programs",
    },
    {
      name: "Student Bootcamps",
      path: "/learn/campus-programs/student-bootcamps",
    },
    {
      name: "Hackathons",
      path: "/learn/campus-programs/hackthons",
    },
    {
      name: "Career Guidance Workshops",
      path: "/learn/campus-programs/career-guidance-workshops",
    },
  ];

  return (
    <div className="footer-top">
      <ul className="Footer_Links">
        
        <li>
          <ul>
            <li>
              <h3>Product and Services</h3>
              <ul
                style={{
                  textDecoration: "none",
                }}
              >
                {roductandServices.map((path, index) => (
                  <li
                    style={{
                      textDecoration: "none",
                    }}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(path.name)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to={path.path}
                      onClick={scrollToTop}
                      style={{ textDecoration: "none" }}
                    >
                      {path.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <h3>Solutions</h3>
              <ul
                style={{
                  textDecoration: "none",
                }}
              >
                {cloudSolution.map((link, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => handleMouseEnter(link.name)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Link
                      to={link.path}
                      onClick={scrollToTop}
                      style={{ textDecoration: "none" }}
                    >
                      {link.name}
                    </Link>
                    {hoveredLink === link.name && link.sublinks && (
                      <ul className="sublinks">
                        {link.sublinks.map((sublink, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              to={sublink.path}
                              onClick={scrollToTop}
                              style={{ textDecoration: "none" }}
                            >
                              {sublink.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <h3>Support</h3>
              <ul
                style={{
                  textDecoration: "none",
                }}
              >
                {supports.map((link, index) => (
                  <li
                    style={{
                      textDecoration: "none",
                    }}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(link.name)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to={link.path}
                      onClick={scrollToTop}
                      style={{ textDecoration: "none" }}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>

        {/* Learn Links */}
        <li>
          <ul>
            <li>
              <h3>Learn</h3>
              <ul
                style={{
                  textDecoration: "none",
                }}
              >
                {Learn.map((link, index) => (
                  <li
                    style={{
                      textDecoration: "none",
                    }}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(link.name)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to={link.path}
                      onClick={scrollToTop}
                      style={{ textDecoration: "none" }}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="Footer2">
        <li>
          <ul>
            <li>
              <Link to="/">
                <img
                  src={Logo}
                  onClick={scrollToTop}
                  className="Logo-Acetians-2"
                />
              </Link>
            </li>
            <li>
              <ul>
                <h6>&#169;2024 Acetians Technologies Pvt Ltd</h6>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/acetianstechnologies/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook style={{ color: "#1877F2" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://in.linkedin.com/company/acetianstechnologies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn style={{ color: "#0077B5" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/c/AcetiansTechnologies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTube style={{ color: "	#FF0000" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/acetianstech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <X />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/acetians_technologies/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram style={{ color: "#E1306C" }} />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Footer2;

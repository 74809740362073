import React from "react";
import { Link } from "react-router-dom";
import Img from "./CloudSolutionImages/CloudSequrity.png";
import { useEffect } from "react";

const CloudSecurity = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Cloud Security Solutions</h1>
            <h2>
              {" "}
              We provide cloud security to protect your business and data.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="compo-1" id="globalpre">
        <li>
          <h1>Explore our comprehensive Cloud Security solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiR21XLz7bD_aYi2jEMq7zP8h2p33xlgzN2w&s" />
                </li>
                <li>
                  <h1>Advanced Threat Protection</h1>
                </li>
                <li>
                  <p>
                    Protect your cloud infrastructure with advanced threat
                    protection strategies that detect and mitigate risks before
                    they can affect your operations. Our solutions utilize
                    real-time threat intelligence to secure your cloud
                    environments.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGs1gTmGcTSW_omeEb7f4_o8MevXedkw2O-Q&s" />
                </li>
                <li>
                  <h1>Data Encryption and Compliance</h1>
                </li>
                <li>
                  <p>
                    Ensure your data remains secure in transit and at rest with
                    robust encryption protocols. Our cloud security solutions
                    are designed to meet global compliance standards, including
                    GDPR, HIPAA, and others.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTvrRlmm7iVtnFFeNOVoOg9Qtq9dEQP4spKQ&s" />
                </li>
                <li>
                  <h1>Identity and Access Management</h1>
                </li>
                <li>
                  <p>
                    Implement strong identity and access controls to ensure that
                    only authorized users and devices can access critical cloud
                    resources. We provide solutions to manage permissions,
                    authentication, and multi-factor authentication.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>
                Forrester names Acetians a Leader in Cloud Security Solutions
              </h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in cloud
                security, providing innovative solutions to safeguard cloud
                infrastructure against emerging cyber threats.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.ssl2buy.com/wp-content/uploads/2022/03/what-is-cloud-security-and-computing.png" />
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>The latest: News and events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://www.cisco.com/c/en_in/solutions/industries/manufacturing/jcr:content/Grid/category_atl/layout-category-atl/blade/bladeContents/spotlight_969515212.img.jpg/1641791342633.jpg" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join our Cloud Security Forum</h1>
                </li>
                <li>
                  <p>
                    Stay up-to-date with the latest trends in cloud security by
                    joining our Cloud Security Forum. Network with experts, gain
                    insights, and learn how to protect your cloud infrastructure
                    more effectively.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/enterprise-networks/dna-spaces/index/jcr:content/Grid/category_atl_37b8_co/layout-category-atl/anchor_info_af98.img.jpg/1678428630174.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data-Driven Cloud Security Insights</h1>
            </li>
            <li>
              <p>
                Acetians leverages advanced data analytics to provide actionable
                insights into your cloud security posture, ensuring your
                business can proactively respond to threats and vulnerabilities.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Get Started</button>
                </li>
                <li>
                  <button>Watch Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-7">
        <li>
          <h1>Deliver Superior Cloud Security Experiences</h1>
        </li>
        <li>
          <p>
            Safeguard your digital assets and optimize your cloud security
            posture with Acetians’ full-stack observability expertise, ensuring
            maximum protection across your cloud environments.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize</h1>
                </li>
                <li>
                  <p>
                    Leverage cutting-edge tools for optimizing cloud security
                    and ensuring long-term protection against evolving threats.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize</h1>
                </li>
                <li>
                  <p>
                    Maximize cloud security ROI by implementing
                    observability-driven strategies to safeguard your
                    infrastructure and data.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize</h1>
                </li>
                <li>
                  <p>
                    Enhance your cloud security posture with Acetians' tailored
                    solutions designed to keep your business safe and secure.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CloudSecurity;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InfraImages/datarecovery.png";

const DisasterRecoveryConsulting = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Disaster Recovery Consulting</h1>
            <h2>
              {" "}
              Protect your business with expert disaster recovery strategies.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-component-5" id="globalpre">
        <li>
          <img
            src="https://cloudian.com/wp-content/uploads/2021/11/disaster-recovery.jpeg"
            alt="Business Continuity"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
        <li>
          <ul>
            <li>
              <h1>Mitigate Risks and Maximize Recovery</h1>
            </li>
            <li>
              <p>
                Implementing the right disaster recovery solutions is critical.
                Our consultants help businesses design recovery strategies that
                minimize risks, reduce data loss, and accelerate the restoration
                of operations.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Consultation</button>
                </li>
                <li>
                  <button>Watch a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Prepare for Business Continuity</h1>
            </li>
            <li>
              <p>
                Our business continuity planning services ensure your
                organization is prepared for unexpected events. We'll guide you
                through risk assessment, backup strategies, and recovery
                protocols to safeguard your operations.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Consultation</button>
                </li>
                <li>
                  <button>Watch a Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img
            src="https://a.storyblok.com/f/157611/800x450/d3812fd050/disaster-recovery-plan.svg"
            alt="Disaster Recovery"
            style={{ width: "100%", height: "auto" }}
          />
        </li>
      </ul>
      <ul className="business-component-10">
        <li>
          <h1>Acetians Disaster Recovery Insights</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Stay informed with the latest disaster recovery trends, best
                practices, and insights. Learn how to safeguard your business
                against unforeseen events and ensure rapid recovery with expert
                advice from Acetians.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Reliable Disaster Recovery Experts</h1>
            </li>
            <li>
              <h2>
                Whether you're planning for natural disasters, cyberattacks, or
                system failures, our experts are here to provide proactive,
                reliable disaster recovery advice to safeguard your business.
              </h2>
            </li>
            <li>
              <button>Why Choose Us</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default DisasterRecoveryConsulting;

import React from "react";
import { Link } from "react-router-dom";
import Img from "../LEARNIMAGES/Leadershipskills.png";
import { useEffect } from "react";

const LeadershipWorkshops = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Empowering Leaders through Acetians Leadership Workshops</h1>
            <h2>
              {" "}
              Transforming potential into performance with our dynamic
              leadership training.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-12" id="globalpre">
        <li>
          <img src="https://cdn.prod.website-files.com/5e34e48ed8d0c2ed720fb988/651c261f5a9a0bf0aa38c856_leadership.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Unlock Your Leadership Potential</h1>
            </li>
            <li>
              <p>
                Acetians' Leadership Workshops are designed to equip
                professionals with the skills and mindset needed to lead
                effectively in today's fast-paced business environment.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-9">
        <li>
          <h1>Acetians Leadership Training: Leading with Impact</h1>
        </li>
        <li>
          <p>
            Our leadership workshops focus on empowering individuals to take
            charge with confidence, fostering team collaboration, and creating
            impactful decision-making strategies for long-term success.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Leadership Development</h1>
                </li>
                <li>
                  <p>
                    Our tailored workshops focus on building core leadership
                    competencies, enabling individuals to grow as strategic
                    thinkers and capable leaders within their organizations.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Strategic Thinking</h1>
                </li>
                <li>
                  <p>
                    Participants will enhance their ability to think critically
                    and strategically, helping them drive long-term
                    organizational goals and meet challenges with innovative
                    solutions.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Team Leadership</h1>
                </li>
                <li>
                  <p>
                    Our workshops focus on building stronger teams by enhancing
                    leadership skills that motivate, inspire, and improve team
                    cohesion, fostering a collaborative culture.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Developing Emotional Intelligence</h1>
                </li>
                <li>
                  <p>
                    A key aspect of effective leadership is emotional
                    intelligence. Our workshops help leaders improve their
                    empathy, communication, and self-awareness for better
                    decision-making.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Conflict Resolution Skills</h1>
                </li>
                <li>
                  <p>
                    We equip leaders with tools and techniques to resolve
                    conflicts efficiently, ensuring harmony within teams and
                    promoting a productive workplace environment.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians Leadership Workshops: Trusted by Top Companies</h1>
            </li>
            <li>
              <h2>
                Acetians has been instrumental in developing leaders for top
                organizations, helping professionals enhance their leadership
                abilities and drive change.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Enhance Your Leadership Skills</h1>
            </li>
            <li>
              <p>
                Join Acetians Leadership Workshops to develop essential skills
                that will help you lead with confidence, motivate your team, and
                achieve your business objectives.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
    </div>
  );
};

export default LeadershipWorkshops;

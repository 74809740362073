import React from "react";
import InnovationCard from "./InnovationCard";

function LatestInnovations() {
  // Sample data to pass to each InnovationCard
  const innovations = [
    {
      imageSrc:
        "https://www.scnsoft.com/blog-pictures/custom-software-development/are-cloud-computing-solutions-worth-implementing-them.png", // Example image URL
      title: "Cloud Solution",
      description:
        " Cloud Solution offers scalable, secure, and efficient cloud services to streamline operations, enhance collaboration, ensure data protection, and provide real-time insights for businesses of all sizes.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://img.freepik.com/free-photo/website-hosting-concept-with-bright-light_23-2149406783.jpg", // Example image URL
      title: "Consulting Services",
      description:
        "Consulting and Services Integration accelerates enterprise-wide business transformation with an agile, Machine First approach in the Business 4.0 world.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://www.intelligentcio.com/apac/wp-content/uploads/sites/44/2022/07/AdobeStock_507662376-600x344.jpeg", // Example image URL
      title: "Infrastructure Solution",
      description:
        " Infrastructure Solution delivers robust, reliable, and customizable IT frameworks, ensuring seamless integration, enhanced scalability, and optimized performance. We empower businesses with advanced tools, secure networks, and efficient management for long-term operational success.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://itbrief.com.au/uploads/story/2024/05/09/img-V7zPycVOstlq1wSzJ1MQ2Xl5.webp", // Example image URL
      title: "Operational Support Services",
      description:
        " Operational Support Services provide comprehensive assistance to streamline business processes, ensure system reliability, and minimize downtime. We deliver proactive monitoring, maintenance, troubleshooting, and expert guidance, enabling seamless operations and fostering organizational growth.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1344939844/photo/hand-holding-drawing-virtual-lightbulb-with-brain-on-bokeh-background-for-creative-and-smart.jpg?s=612x612&w=0&k=20&c=2GLUy6eqCSr0NFRO8CHm8_PUMy9Qc8ryqcsRoe0DEYM=", // Example image URL
      title: "Digital Experience Solution",
      description:
        " Digital Experience Solution enhances user engagement through innovative interfaces, personalized content, and seamless interactions. We integrate cutting-edge technologies to create immersive experiences, optimize customer journeys, and drive digital transformation for businesses worldwide.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://www.avgisolutions.com/uploads/page_contents/fc7aa8fac2498abc8d6331da8f208ade.jpg",
      title: "Infrastructure Managed Services",
      description:
        "Infrastructure Managed Services provide end-to-end management of IT infrastructure, ensuring optimal performance, scalability, and security. We deliver proactive monitoring, maintenance, and support to minimize disruptions, reduce costs, and empower seamless business operations.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://www.shutterstock.com/image-vector/software-development-coding-process-concept-600nw-1396210841.jpg",
      title: "Business App",
      description:
        "Business App solutions streamline workflows, enhance productivity, and foster collaboration. Tailored to meet unique needs, our apps integrate seamlessly with existing systems, offering intuitive interfaces, real-time insights, and scalable features for business growth.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://www.shutterstock.com/image-vector/hardware-software-computer-technology-background-600nw-2048513402.jpg",
      title: "Hardware Solution",
      description:
        "Hardware Solution offers cutting-edge, reliable, and scalable devices tailored to business needs. From procurement to deployment, we ensure seamless integration, robust performance, and long-term support to empower efficient and secure operations.",
      exploreLink: "/",
    },
    {
      imageSrc:
        "https://static.vecteezy.com/system/resources/previews/008/095/062/non_2x/outsourcing-and-hr-social-network-and-global-recruitment-concept-on-blue-background-global-recruitment-business-in-the-shape-of-polygonal-sphere-in-wireframe-hand-illustration-vector.jpg",
      title: "Outsourcing",
      description:
        "Outsourcing services provide expert talent and resources to handle business functions efficiently. We deliver cost-effective solutions, enhanced productivity, and operational scalability.",
      exploreLink: "/",
    },
  ];

  return (
    <div className="latest__innovations">
      <h1>Our Services</h1>
      <ul>
        {innovations.map((innovation, index) => (
          <li key={index}>
            <InnovationCard
              imageSrc={innovation.imageSrc}
              title={innovation.title}
              description={innovation.description}
              exploreLink={innovation.exploreLink}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LatestInnovations;

import React from "react";
import { Link } from "react-router-dom";
import Img from "./HardwareSolutionImages/networkdevices.png";
import { useEffect } from "react";

const NetworkDevices = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1> Empowering Your Network with Acetians Technologies</h1>
            <h2>
              {" "}
              Providing cutting-edge networking solutions for a connected
              future.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-component-12 " id="globalpre">
        <li>
          <img src="https://miro.medium.com/v2/resize:fit:1178/1*BF6lwjCzHdkQp-j-crEeLw.png" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Unlock Scalable Network Solutions</h1>
            </li>
            <li>
              <p>
                Explore how Acetians Technologies helps businesses scale their
                networks with reliable and high-performance devices, ensuring
                consistent connectivity for your mission-critical applications.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-9">
        <li>
          <h1>Acetians Technologies: Powering the Next-Gen Network</h1>
        </li>
        <li>
          <p>
            Acetians provides robust network infrastructure designed to optimize
            performance, security, and scalability. From routers to advanced
            network monitoring, we ensure your network stays ahead of the curve.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Networking Infrastructure</h1>
                </li>
                <li>
                  <p>
                    Our solutions empower businesses to build resilient,
                    future-proof networks capable of handling ever-growing data
                    traffic and increasing demand for high-bandwidth
                    applications.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Advanced Security</h1>
                </li>
                <li>
                  <p>
                    With Acetians' secure networking devices, your network
                    infrastructure is protected from emerging threats, ensuring
                    business continuity with proactive monitoring and advanced
                    encryption techniques.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>Optimized Performance</h1>
                </li>
                <li>
                  <p>
                    Acetians Technologies delivers cutting-edge hardware and
                    software solutions that maximize network throughput while
                    minimizing downtime, enabling your business to stay ahead in
                    the digital era.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Global Connectivity</h1>
                </li>
                <li>
                  <p>
                    With Acetians' global network of high-speed transceivers,
                    your business can seamlessly connect across regions,
                    ensuring low-latency and high-performance across your entire
                    infrastructure.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>AI-Driven Network Solutions</h1>
                </li>
                <li>
                  <p>
                    By incorporating AI technologies, Acetians is
                    revolutionizing the way networks are managed and optimized,
                    enabling smarter, faster decision-making for your business.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-8">
        <li>
          <h1>Boost Your Network with Acetians' Optical Transceivers</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h1>Upgrade and Save on High-Speed Optical Transceivers</h1>
                </li>
                <li>
                  <p>
                    Acetians' optical transceivers deliver lightning-fast data
                    transfer rates while ensuring long-term reliability, perfect
                    for scaling your network as your business grows.
                  </p>
                </li>
                <li>
                  <h1>Get the Details</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h1>High-Performance Networking Solutions</h1>
                </li>
                <li>
                  <p>
                    Whether you're upgrading to 100G or 400G, Acetians has the
                    right optical solutions to meet the demands of
                    high-performance networking environments.
                  </p>
                </li>
                <li>
                  <h1>Contact Us for More Info</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Acetians Technologies: Trusted by Enterprises Worldwide</h1>
            </li>
            <li>
              <h2>
                Our network solutions have earned the trust of global
                enterprises, helping them scale their infrastructure and meet
                their business goals with ease.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-4">
        <li>
          <ul>
            <li>
              <h1>Enhance Your Hybrid Learning Experience</h1>
            </li>
            <li>
              <p>
                Acetians Technologies provides education organizations with the
                tools to support hybrid learning environments, enabling students
                and educators to thrive in a digital-first world.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Free Trial</button>
                </li>
                <li>
                  <button>Watch A Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://img.freepik.com/free-vector/isometric-mobile-network-concept_1284-18852.jpg" />
        </li>
      </ul>

      <ul className="business-component-13">
        <li>
          <h1>Explore Acetians' Full-Stack Observability Solutions</h1>
        </li>
        <li>
          <p>
            Discover how Acetians' full-stack observability solutions can
            provide you with deep insights into your network performance,
            allowing for proactive troubleshooting and optimization.
          </p>
        </li>
        <li>
          <button>Explore Solutions</button>
        </li>
      </ul>
    </div>
  );
};

export default NetworkDevices;

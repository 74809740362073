import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./InstructureWorkshop/Advanceprogramming.png";

const AdvancedProgramming = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Advanced Programming</h1>
            <h2> we specialize in advanced coding and algorithms.</h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>

      <ul className="business-comopnent-7" id="globalpre">
        <li>
          <h1>Master Complex Algorithms</h1>
        </li>
        <li>
          <p>
            Accelerate your programming skills with Acetians’ deep dive into
            advanced algorithms. From dynamic programming to graph theory, we
            cover it all to enhance your coding expertise.
          </p>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Optimize Code</h1>
                </li>
                <li>
                  <p>
                    Learn advanced techniques for optimizing your code, ensuring
                    it runs faster and consumes fewer resources, even in the
                    most demanding applications.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Master Algorithms</h1>
                </li>
                <li>
                  <p>
                    Master the implementation of complex algorithms in
                    real-world projects, from sorting algorithms to search
                    strategies, enhancing problem-solving efficiency.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <img src="https://www.cisco.com/c/m/en_us/customer-experience/full-stack-observability/jcr:content/Grid/category_atl_copy_co/layout-category-atl/blade_1009707526_cop_308285405/bladeContents/thirds/Th-Third-1/tile_copy_copy_12918.img.png/1645122884426.png" />
                </li>
                <li>
                  <h1>Code Efficiency</h1>
                </li>
                <li>
                  <p>
                    Improve the efficiency of your code with advanced concepts
                    like multithreading, concurrency, and parallel computing to
                    handle high-performance tasks.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-3">
        <li>
          <img src="https://media2.dev.to/dynamic/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Frvdh61t3d9xze1m942wj.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Data Structures & Algorithms Insights</h1>
            </li>
            <li>
              <p>
                Deepen your understanding of data structures and algorithms,
                which are foundational to solving advanced programming problems
                with efficient solutions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Get Started</button>
                </li>
                <li>
                  <button>Watch Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-4">
        <li>
          <h1>Save on Development Tools</h1>
        </li>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <h3>Acetians Developer Suite</h3>
                </li>
                <li>
                  <h2>Streamline your coding workflow</h2>
                </li>
                <li>
                  <p>
                    Get access to all the tools you need for effective
                    development, from code editors to debuggers and profilers,
                    in one comprehensive software suite.
                  </p>
                </li>
                <li>
                  <h1>View Developer Suite</h1>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <h3>Acetians Code Debugging Platform</h3>
                </li>
                <li>
                  <h2>Optimize your code debugging process</h2>
                </li>
                <li>
                  <p>
                    Maximize code quality with Acetians' powerful debugging
                    platform designed to swiftly identify and resolve coding
                    errors.
                  </p>
                </li>
                <li>
                  <h1>View Debugging Platform</h1>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Forrester names Acetians a Leader in Programming Tools</h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in the
                field of programming tools, offering innovative solutions that
                help developers write cleaner, faster, and more efficient code.
              </p>
            </li>
            <li>
              <button>Get Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://media2.dev.to/dynamic/image/width=1600,height=900,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2F5zmhvdmabak0ik1ib9qp.png" />
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Explore Acetians’ AI-Powered Programming Solutions</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Discover how Acetians leverages AI to enhance programming
                productivity, using machine learning models and intelligent
                systems to optimize your codebase.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AdvancedProgramming;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "./EnterpricesSolimg/businesstoool.png";

const BusinessIntelligenceTools = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Business Intelligence Tools</h1>
            <h2>Unlock the Power of Data for Smarter Business Decisions.</h2>
            <li className="btn-explore">
              <Link to="/contact-us">Explore Acetians</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-component-1" id="globalpre">
        <li>
          <img src="https://www.cisco.com/c/en_in/products/software/index/jcr:content/Grid/category_atl/layout-category-atl/spotlight_677629952_.img.jpg/1693300210307.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Our Business Intelligence Development Process</h1>
            </li>
            <li>
              <p>
                Our BI development process ensures seamless integration of data
                from various sources, followed by in-depth analysis,
                visualization, and actionable insights that allow you to make
                smarter business decisions.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Initial Consultation</h1>
                    </li>
                    <li>
                      <p>
                        We start by understanding your business goals and
                        challenges to tailor the right BI tools and strategies
                        for your needs.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Data Integration and Analysis</h1>
                    </li>
                    <li>
                      <p>
                        After gathering the necessary data, we integrate it into
                        a unified system and perform thorough analysis to
                        uncover patterns and trends.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Business Intelligence Implementation</h1>
                    </li>
                    <li>
                      <p>
                        We then implement BI solutions, ensuring they align with
                        your business processes to generate real-time insights
                        and forecasts.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Post-Implementation Support</h1>
                    </li>
                    <li>
                      <p>
                        We provide ongoing support and training to ensure that
                        your team can fully leverage the BI tools and continue
                        driving success.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Business Intelligence</h1>
            </li>
            <li>
              <h2>
                At Acetians Technologies, we specialize in providing top-tier
                business intelligence tools that help businesses turn raw data
                into actionable insights for better decision-making.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/enterprise-networks/dna-spaces/index/jcr:content/Grid/category_atl_37b8_co/layout-category-atl/anchor_info_af98.img.jpg/1678428630174.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Advanced BI Solutions</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we provide business intelligence
                solutions that help organizations across various sectors like
                healthcare, finance, retail, and manufacturing to harness data
                for improved performance and strategic growth.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Your BI Journey</button>
                </li>
                <li>
                  <button>Request a Quote</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Our Expertise in Business Intelligence</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies offers cutting-edge business intelligence
                tools and services, including data visualization, predictive
                analytics, and data warehousing. Our solutions empower you to
                make informed decisions that drive growth.
              </h2>
            </li>
            <li>
              <button>See Our Work</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default BusinessIntelligenceTools;

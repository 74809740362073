import React from "react";
import { Link } from "react-router-dom";
import Img from "./HardwareSolutionImages/Hardwaredevelopement.png";
import { useEffect } from "react";

const CustomHardwareDevelopement = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Custom Hardware Development</h1>
            <h2>
              {" "}
              Transforming Your Ideas into Cutting-Edge Hardware Solutions.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-comopnent-6" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Custom Hardware Development</h1>
            </li>
            <li>
              <h2>
                At Acetians Technologies, we specialize in creating innovative,
                high-performance hardware solutions tailored to meet your unique
                business needs.
              </h2>
            </li>
            <li>
              <button>Why Acetians?</button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="business-component-3">
        <li>
          <img src="https://capuf.in/wp-content/uploads/2024/07/custom-embedded-hardware-development-by-capuf-embedded-embedded-design-house.jpg" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Custom Hardware Solutions for Every Industry</h1>
            </li>
            <li>
              <p>
                At Acetians Technologies, we design and develop custom hardware
                solutions that help businesses scale, improve efficiency, and
                gain a competitive edge in the market. Whether you need a custom
                PCB, embedded system, or IoT device, we have the expertise to
                deliver.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start Your Project</button>
                </li>
                <li>
                  <button>Request a Quote</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent">
        <li>
          <ul>
            <li>
              <h1>Explore Our Expertise in Custom Hardware</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies brings you the best in custom hardware
                design, prototyping, and manufacturing services. Check out our
                portfolio of successful projects for industries such as
                healthcare, automotive, and consumer electronics.
              </h2>
            </li>
            <li>
              <button>See Our Work</button>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-1">
        <li>
          <img src="https://media.licdn.com/dms/image/C4D12AQFgIuExQcyOHA/article-cover_image-shrink_720_1280/0/1541652791323?e=2147483647&v=beta&t=_56TmRoksFU3-zdr4zMKsr8_kMqxyQyjx54f53DKCBE" />
        </li>
        <li>
          <ul>
            <li>
              <h1>Acetians Custom Hardware Development Process</h1>
            </li>
            <li>
              <p>
                Our process ensures that your hardware product is designed,
                tested, and delivered to the highest standards. From concept to
                prototype to mass production, we guide you through every step of
                the development lifecycle.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Initial Consultation</h1>
                    </li>
                    <li>
                      <p>
                        We start with an in-depth consultation to understand
                        your business needs and technical requirements for the
                        hardware solution.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Prototyping and Testing</h1>
                    </li>
                    <li>
                      <p>
                        After conceptualizing the design, we build prototypes
                        and conduct rigorous testing to ensure functionality and
                        performance.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Manufacturing and Quality Assurance</h1>
                    </li>
                    <li>
                      <p>
                        Once the design is finalized, we handle the
                        manufacturing process, ensuring high-quality production
                        and compliance with industry standards.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <ul>
                    <li>
                      <h1>Post-Development Support</h1>
                    </li>
                    <li>
                      <p>
                        Our partnership doesn't end with delivery. We provide
                        ongoing support and maintenance to ensure your hardware
                        continues to perform optimally.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-component-10">
        <li>
          <h1>Acetians Hardware Development Blogs</h1>
        </li>
        <li>
          <ul>
            <li>
              <p>
                Learn how Acetians Technologies is transforming hardware
                development with cutting-edge designs, innovative solutions, and
                the latest in technology.
              </p>
            </li>
            <li>
              <button>Read More</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CustomHardwareDevelopement;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Img from "../LEARNIMAGES/AIdesign.png";

const AICurriculumDesign = () => {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="WhyBharat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <ul>
          <li>
            <h1>Curriculum Design</h1>
            <h2>
              {" "}
              we design dynamic curriculums to enhance learning outcomes.
            </h2>
            <li className="btn-explore">
              <Link to="/contact-us">TALK TO AN EXPERT</Link>
            </li>
          </li>
        </ul>
      </div>
      <ul className="business-component-4" id="globalpre">
        <li>
          <ul>
            <li>
              <h1>Innovative Curriculum Solutions</h1>
            </li>
            <li>
              <p>
                We provide schools, universities, and educational organizations
                with innovative curriculum frameworks that foster creativity,
                critical thinking, and student engagement.
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <button>Start a Curriculum Consultation</button>
                </li>
                <li>
                  <button>Watch a Curriculum Design Demo</button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://www.cisco.com/c/en_in/solutions/industries/education/jcr:content/Grid/category_atl/layout-category-atl/spotlight.img.png/1687847291455.png" />
        </li>
      </ul>
      <ul className="compo-5">
        <li>
          <ul>
            <li>
              <h1>Acetians Leads in Curriculum Innovation</h1>
            </li>
            <li>
              <p>
                Acetians Technologies has been recognized as a leader in
                educational innovation, delivering forward-thinking curriculum
                solutions to improve learning outcomes.
              </p>
            </li>
            <li>
              <button>Get the Report</button>
            </li>
          </ul>
        </li>
        <li>
          <img src="https://redresscompliance.com/wp-content/uploads/2024/06/Benefits-of-Using-AI-in-Curriculum-Development-1024x585.webp" />
        </li>
      </ul>
      <ul className="compo-2">
        <li>
          <h1>The Latest: Curriculum Design News and Events</h1>
        </li>
        <li>
          <ul>
            <li>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCBeQ-HFo3nGrq9kSxfVGpJlVv1QSxNDYvYg&s" />
            </li>
            <li>
              <ul>
                <li>
                  <h1>Join Our Curriculum Design Forum</h1>
                </li>
                <li>
                  <p>
                    Stay updated with the latest trends in curriculum design by
                    joining our Curriculum Design Forum. Connect with educators,
                    access expert insights, and explore the best practices for
                    developing impactful curriculums.
                  </p>
                </li>
                <li>
                  <ul>
                    <li>
                      <button>Join the Community</button>
                    </li>
                    <li>
                      <button>Explore Resources</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="business-comopnent-6">
        <li>
          <ul>
            <li>
              <h1>Global Curriculum Design Solutions</h1>
            </li>
            <li>
              <h2>
                Acetians Technologies helps educational institutions expand
                their curriculum offerings globally, adapting content for
                diverse learning needs.
              </h2>
            </li>
            <li>
              <button>Why Choose Acetians for Curriculum Design</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AICurriculumDesign;

import React from 'react'


function StoryCard() {
  return (
    <div className='story__card'>
        <ul>
            <li>
            <img src={"https://media.assettype.com/analyticsinsight%2F2024-07%2Fb20442aa-0dc3-40ed-a3bb-6e6083f933ca%2FTop_10_Personal_Robots_that_You_Can_Buy_in_2023.jpg"} className='Story_card_img'/>
            </li>
            <li>
                <div className='blog_content'>
                <h2>Humanoid Robotics</h2>
                <h3>
                Humanoid robotics refers to robots designed to resemble and mimic human appearance and behavior. These robots typically have a head, torso, arms, and legs, and are built to perform tasks similar to those of humans, such as walking, talking, or interacting with their environment. They are often used in research, entertainment, healthcare, and customer service.</h3>   
                <a href=''>Explore more</a>
                </div>
            </li>
        </ul>
    </div>
  )
}

export default StoryCard
import React from "react";

const ProductAndServicehome = () => {
  const productsAndServices = [
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpMFRGLM4FhbyDygpY9_pqvlRi5dP45cjwhw&s",
      headText: "Campus Programs",
      textInfo:
        "Campus programs at Acetians Technologies foster innovation, learning, and skill development. They aim to engage students through hands-on projects, mentorship, and real-world problem-solving, preparing them for future careers in robotics and technology.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ4D492MuAdnu7rh4bhnNxCV_f3FPXPzKqkQ&s",
      headText: "Acetians IT Academy",
      textInfo:
        "Acetians IT Academy offers cutting-edge training in robotics, artificial intelligence, and software development, empowering learners to build innovative solutions and thrive in the tech industry.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQErdLQZN_oWwb1oJ1FokIa3QpRQx2RDNdQdQ&s",
      headText: "Skill Development",
      textInfo:
        "Skill development refers to the process of improving and acquiring new abilities to enhance professional growth, productivity, and adaptability in the workplace. It empowers individuals to perform tasks more efficiently and stay relevant in an ever-changing environment.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMxDEEhl68XLHHoE8kQxGXttRbzPDMb1UkddCKLiylTNwTGX1HL-Jtm2t1Y19OH8CPQhI&usqp=CAU",
      headText: "AI Educator Programs",
      textInfo:
        "AI Educator Programs aim to integrate artificial intelligence into education, enhancing learning experiences, automating administrative tasks, and providing personalized instruction tailored to individual student needs.",
    },
    {
      imgSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5DuCQZFVTKebPG_rJAM-309G_60f3HL7RWQ&s",
      headText: "Workshops",
      textInfo:
        "Workshops are interactive sessions focused on skill development, learning, and hands-on activities. They provide practical knowledge, foster collaboration, and encourage participants to apply new techniques in real-world scenarios.",
    },
  ];

  return (
    <div className="main-page-content">
      {productsAndServices.map((item, index) => (
        <div key={index}>
          <div>
            <img
              src={item.imgSrc}
              className="content-img"
              alt="product-service"
            />
          </div>
          <div>
            <p className="head-text">{item.headText}</p>
            <p className="text-info">{item.textInfo}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductAndServicehome;
